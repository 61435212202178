!(function (e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : e();
})(function () {
  try {
    var e = { modules: {} },
      t = (e._ = {});
    (function () {
      var e = { function: !0, object: !0 },
        t = (e[typeof window] && window) || this;
      var r = t.JSON,
        i = t.JSON3,
        a = !1,
        n = (function s(r, i) {
          r || (r = t.Object()), i || (i = t.Object());
          var a = r.Number || t.Number,
            n = r.String || t.String,
            o = r.Object || t.Object,
            c = r.Date || t.Date,
            l = r.SyntaxError || t.SyntaxError,
            u = r.TypeError || t.TypeError,
            p = r.Math || t.Math,
            d = r.JSON || t.JSON;
          "object" == typeof d &&
            d &&
            ((i.stringify = d.stringify), (i.parse = d.parse));
          var f,
            _ = o.prototype,
            g = _.toString,
            h = _.hasOwnProperty;
          function m(e, t) {
            try {
              e();
            } catch (r) {
              t && t();
            }
          }
          var v = new c(-0xc782b5b800cec);
          function y(e) {
            if (null != y[e]) return y[e];
            var t;
            if ("bug-string-char-index" == e) t = "a" != "a"[0];
            else if ("json" == e)
              t =
                y("json-stringify") &&
                y("date-serialization") &&
                y("json-parse");
            else if ("date-serialization" == e) {
              if ((t = y("json-stringify") && v)) {
                var r = i.stringify;
                m(function () {
                  t =
                    '"-271821-04-20T00:00:00.000Z"' == r(new c(-864e13)) &&
                    '"+275760-09-13T00:00:00.000Z"' == r(new c(864e13)) &&
                    '"-000001-01-01T00:00:00.000Z"' == r(new c(-621987552e5)) &&
                    '"1969-12-31T23:59:59.999Z"' == r(new c(-1));
                });
              }
            } else {
              var s,
                o = '{"a":[1,true,false,null,"\\u0000\\b\\n\\f\\r\\t"]}';
              if ("json-stringify" == e) {
                var l = "function" == typeof (r = i.stringify);
                l &&
                  (((s = function () {
                    return 1;
                  }).toJSON = s),
                    m(
                      function () {
                        l =
                          "0" === r(0) &&
                          "0" === r(new a()) &&
                          '""' == r(new n()) &&
                          r(g) === f &&
                          r(f) === f &&
                          r() === f &&
                          "1" === r(s) &&
                          "[1]" == r([s]) &&
                          "[null]" == r([f]) &&
                          "null" == r(null) &&
                          "[null,null,null]" == r([f, g, null]) &&
                          r({ a: [s, !0, !1, null, "\0\b\n\f\r\t"] }) == o &&
                          "1" === r(null, s) &&
                          "[\n 1,\n 2\n]" == r([1, 2], null, 1);
                      },
                      function () {
                        l = !1;
                      }
                    )),
                  (t = l);
              }
              if ("json-parse" == e) {
                var u,
                  p = i.parse;
                "function" == typeof p &&
                  m(
                    function () {
                      0 !== p("0") ||
                        p(!1) ||
                        ((s = p(o)),
                          (u = 5 == s.a.length && 1 === s.a[0]) &&
                          (m(function () {
                            u = !p('"\t"');
                          }),
                            u &&
                            m(function () {
                              u = 1 !== p("01");
                            }),
                            u &&
                            m(function () {
                              u = 1 !== p("1.");
                            })));
                    },
                    function () {
                      u = !1;
                    }
                  ),
                  (t = u);
              }
            }
            return (y[e] = !!t);
          }
          if (
            (m(function () {
              v =
                -109252 == v.getUTCFullYear() &&
                0 === v.getUTCMonth() &&
                1 === v.getUTCDate() &&
                10 == v.getUTCHours() &&
                37 == v.getUTCMinutes() &&
                6 == v.getUTCSeconds() &&
                708 == v.getUTCMilliseconds();
            }),
              (y["bug-string-char-index"] =
                y["date-serialization"] =
                y.json =
                y["json-stringify"] =
                y["json-parse"] =
                null),
              !y("json"))
          ) {
            var S = y("bug-string-char-index"),
              b = function (t, r) {
                var i,
                  a,
                  n,
                  s = 0;
                for (n in (((i = function () {
                  this.valueOf = 0;
                }).prototype.valueOf = 0),
                  (a = new i())))
                  h.call(a, n) && s++;
                return (
                  (i = a = null),
                  s
                    ? (b = function (e, t) {
                      var r,
                        i,
                        a = "[object Function]" == g.call(e);
                      for (r in e)
                        (a && "prototype" == r) ||
                          !h.call(e, r) ||
                          (i = "constructor" === r) ||
                          t(r);
                      (i || h.call(e, (r = "constructor"))) && t(r);
                    })
                    : ((a = [
                      "valueOf",
                      "toString",
                      "toLocaleString",
                      "propertyIsEnumerable",
                      "isPrototypeOf",
                      "hasOwnProperty",
                      "constructor",
                    ]),
                      (b = function (t, r) {
                        var i,
                          n,
                          s = "[object Function]" == g.call(t),
                          o =
                            (!s &&
                              "function" != typeof t.constructor &&
                              e[typeof t.hasOwnProperty] &&
                              t.hasOwnProperty) ||
                            h;
                        for (i in t)
                          (s && "prototype" == i) || !o.call(t, i) || r(i);
                        for (n = a.length; (i = a[--n]);) o.call(t, i) && r(i);
                      })),
                  b(t, r)
                );
              };
            if (!y("json-stringify") && !y("date-serialization")) {
              var w = {
                92: "\\\\",
                34: '\\"',
                8: "\\b",
                12: "\\f",
                10: "\\n",
                13: "\\r",
                9: "\\t",
              },
                k = function (e, t) {
                  return ("000000" + (t || 0)).slice(-e);
                },
                j = function (e) {
                  var t, r, i, a, n, s, o, c, l;
                  if (v)
                    t = function (e) {
                      (r = e.getUTCFullYear()),
                        (i = e.getUTCMonth()),
                        (a = e.getUTCDate()),
                        (s = e.getUTCHours()),
                        (o = e.getUTCMinutes()),
                        (c = e.getUTCSeconds()),
                        (l = e.getUTCMilliseconds());
                    };
                  else {
                    var u = p.floor,
                      d = [
                        0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334,
                      ],
                      f = function (e, t) {
                        return (
                          d[t] +
                          365 * (e - 1970) +
                          u((e - 1969 + (t = +(t > 1))) / 4) -
                          u((e - 1901 + t) / 100) +
                          u((e - 1601 + t) / 400)
                        );
                      };
                    t = function (e) {
                      for (
                        a = u(e / 864e5), r = u(a / 365.2425) + 1970 - 1;
                        f(r + 1, 0) <= a;
                        r++
                      );
                      for (i = u((a - f(r, 0)) / 30.42); f(r, i + 1) <= a; i++);
                      (a = 1 + a - f(r, i)),
                        (s =
                          u((n = ((e % 864e5) + 864e5) % 864e5) / 36e5) % 24),
                        (o = u(n / 6e4) % 60),
                        (c = u(n / 1e3) % 60),
                        (l = n % 1e3);
                    };
                  }
                  return (j = function (e) {
                    return (
                      e > -1 / 0 && e < 1 / 0
                        ? (t(e),
                          (e =
                            (r <= 0 || r >= 1e4
                              ? (r < 0 ? "-" : "+") + k(6, r < 0 ? -r : r)
                              : k(4, r)) +
                            "-" +
                            k(2, i + 1) +
                            "-" +
                            k(2, a) +
                            "T" +
                            k(2, s) +
                            ":" +
                            k(2, o) +
                            ":" +
                            k(2, c) +
                            "." +
                            k(3, l) +
                            "Z"),
                          (r = i = a = s = o = c = l = null))
                        : (e = null),
                      e
                    );
                  })(e);
                };
              if (y("json-stringify") && !y("date-serialization")) {
                function P(e) {
                  return j(this);
                }
                var O = i.stringify;
                i.stringify = function (e, t, r) {
                  var i = c.prototype.toJSON;
                  c.prototype.toJSON = P;
                  var a = O(e, t, r);
                  return (c.prototype.toJSON = i), a;
                };
              } else {
                var D = function (e) {
                  var t = e.charCodeAt(0),
                    r = w[t];
                  return r || "\\u00" + k(2, t.toString(16));
                },
                  N = /[\x00-\x1f\x22\x5c]/g,
                  A = function (e) {
                    return (
                      (N.lastIndex = 0),
                      '"' + (N.test(e) ? e.replace(N, D) : e) + '"'
                    );
                  },
                  C = function (e, t, r, i, a, n, s) {
                    var o, l, p, d, _, h, v, y, S;
                    if (
                      (m(function () {
                        o = t[e];
                      }),
                        "object" == typeof o &&
                        o &&
                        (o.getUTCFullYear &&
                          "[object Date]" == g.call(o) &&
                          o.toJSON === c.prototype.toJSON
                          ? (o = j(o))
                          : "function" == typeof o.toJSON && (o = o.toJSON(e))),
                        r && (o = r.call(t, e, o)),
                        o == f)
                    )
                      return o === f ? o : "null";
                    switch (
                    ("object" == (l = typeof o) && (p = g.call(o)), p || l)
                    ) {
                      case "boolean":
                      case "[object Boolean]":
                        return "" + o;
                      case "number":
                      case "[object Number]":
                        return o > -1 / 0 && o < 1 / 0 ? "" + o : "null";
                      case "string":
                      case "[object String]":
                        return A("" + o);
                    }
                    if ("object" == typeof o) {
                      for (v = s.length; v--;) if (s[v] === o) throw u();
                      if (
                        (s.push(o),
                          (d = []),
                          (y = n),
                          (n += a),
                          "[object Array]" == p)
                      ) {
                        for (h = 0, v = o.length; h < v; h++)
                          (_ = C(h, o, r, i, a, n, s)),
                            d.push(_ === f ? "null" : _);
                        S = d.length
                          ? a
                            ? "[\n" + n + d.join(",\n" + n) + "\n" + y + "]"
                            : "[" + d.join(",") + "]"
                          : "[]";
                      } else
                        b(i || o, function (e) {
                          var t = C(e, o, r, i, a, n, s);
                          t !== f && d.push(A(e) + ":" + (a ? " " : "") + t);
                        }),
                          (S = d.length
                            ? a
                              ? "{\n" + n + d.join(",\n" + n) + "\n" + y + "}"
                              : "{" + d.join(",") + "}"
                            : "{}");
                      return s.pop(), S;
                    }
                  };
                i.stringify = function (t, r, i) {
                  var a, n, s, o;
                  if (e[typeof r] && r)
                    if ("[object Function]" == (o = g.call(r))) n = r;
                    else if ("[object Array]" == o) {
                      s = {};
                      for (var c, l = 0, u = r.length; l < u;)
                        (c = r[l++]),
                          ("[object String]" != (o = g.call(c)) &&
                            "[object Number]" != o) ||
                          (s[c] = 1);
                    }
                  if (i)
                    if ("[object Number]" == (o = g.call(i))) {
                      if ((i -= i % 1) > 0)
                        for (i > 10 && (i = 10), a = ""; a.length < i;)
                          a += " ";
                    } else
                      "[object String]" == o &&
                        (a = i.length <= 10 ? i : i.slice(0, 10));
                  return C("", (((c = {})[""] = t), c), n, s, a, "", []);
                };
              }
            }
            if (!y("json-parse")) {
              var x,
                $,
                I = n.fromCharCode,
                T = {
                  92: "\\",
                  34: '"',
                  47: "/",
                  98: "\b",
                  116: "\t",
                  110: "\n",
                  102: "\f",
                  114: "\r",
                },
                U = function () {
                  throw ((x = $ = null), l());
                },
                E = function () {
                  for (var e, t, r, i, a, n = $, s = n.length; x < s;)
                    switch ((a = n.charCodeAt(x))) {
                      case 9:
                      case 10:
                      case 13:
                      case 32:
                        x++;
                        break;
                      case 123:
                      case 125:
                      case 91:
                      case 93:
                      case 58:
                      case 44:
                        return (e = S ? n.charAt(x) : n[x]), x++, e;
                      case 34:
                        for (e = "@", x++; x < s;)
                          if ((a = n.charCodeAt(x)) < 32) U();
                          else if (92 == a)
                            switch ((a = n.charCodeAt(++x))) {
                              case 92:
                              case 34:
                              case 47:
                              case 98:
                              case 116:
                              case 110:
                              case 102:
                              case 114:
                                (e += T[a]), x++;
                                break;
                              case 117:
                                for (t = ++x, r = x + 4; x < r; x++)
                                  ((a = n.charCodeAt(x)) >= 48 && a <= 57) ||
                                    (a >= 97 && a <= 102) ||
                                    (a >= 65 && a <= 70) ||
                                    U();
                                e += I("0x" + n.slice(t, x));
                                break;
                              default:
                                U();
                            }
                          else {
                            if (34 == a) break;
                            for (
                              a = n.charCodeAt(x), t = x;
                              a >= 32 && 92 != a && 34 != a;

                            )
                              a = n.charCodeAt(++x);
                            e += n.slice(t, x);
                          }
                        if (34 == n.charCodeAt(x)) return x++, e;
                        U();
                      default:
                        if (
                          ((t = x),
                            45 == a && ((i = !0), (a = n.charCodeAt(++x))),
                            a >= 48 && a <= 57)
                        ) {
                          for (
                            48 == a &&
                            (a = n.charCodeAt(x + 1)) >= 48 &&
                            a <= 57 &&
                            U(),
                            i = !1;
                            x < s && (a = n.charCodeAt(x)) >= 48 && a <= 57;
                            x++
                          );
                          if (46 == n.charCodeAt(x)) {
                            for (
                              r = ++x;
                              r < s && !((a = n.charCodeAt(r)) < 48 || a > 57);
                              r++
                            );
                            r == x && U(), (x = r);
                          }
                          if (101 == (a = n.charCodeAt(x)) || 69 == a) {
                            for (
                              (43 != (a = n.charCodeAt(++x)) && 45 != a) || x++,
                              r = x;
                              r < s && !((a = n.charCodeAt(r)) < 48 || a > 57);
                              r++
                            );
                            r == x && U(), (x = r);
                          }
                          return +n.slice(t, x);
                        }
                        i && U();
                        var o = n.slice(x, x + 4);
                        if ("true" == o) return (x += 4), !0;
                        if ("fals" == o && 101 == n.charCodeAt(x + 4))
                          return (x += 5), !1;
                        if ("null" == o) return (x += 4), null;
                        U();
                    }
                  return "$";
                },
                R = function (e) {
                  var t, r;
                  if (("$" == e && U(), "string" == typeof e)) {
                    if ("@" == (S ? e.charAt(0) : e[0])) return e.slice(1);
                    if ("[" == e) {
                      for (t = []; "]" != (e = E());)
                        r
                          ? "," == e
                            ? "]" == (e = E()) && U()
                            : U()
                          : (r = !0),
                          "," == e && U(),
                          t.push(R(e));
                      return t;
                    }
                    if ("{" == e) {
                      for (t = {}; "}" != (e = E());)
                        r
                          ? "," == e
                            ? "}" == (e = E()) && U()
                            : U()
                          : (r = !0),
                          ("," != e &&
                            "string" == typeof e &&
                            "@" == (S ? e.charAt(0) : e[0]) &&
                            ":" == E()) ||
                          U(),
                          (t[e.slice(1)] = R(E()));
                      return t;
                    }
                    U();
                  }
                  return e;
                },
                L = function (e, t, r) {
                  var i = J(e, t, r);
                  i === f ? delete e[t] : (e[t] = i);
                },
                J = function (e, t, r) {
                  var i,
                    a = e[t];
                  if ("object" == typeof a && a)
                    if ("[object Array]" == g.call(a))
                      for (i = a.length; i--;) L(g, b, a);
                    else
                      b(a, function (e) {
                        L(a, e, r);
                      });
                  return r.call(e, t, a);
                };
              i.parse = function (e, t) {
                var r, i;
                return (
                  (x = 0),
                  ($ = "" + e),
                  (r = R(E())),
                  "$" != E() && U(),
                  (x = $ = null),
                  t && "[object Function]" == g.call(t)
                    ? J((((i = {})[""] = r), i), "", t)
                    : r
                );
              };
            }
          }
          return (i.runInContext = s), i;
        })(
          t,
          (t.JSON3 = {
            noConflict: function () {
              return (
                a || ((a = !0), (t.JSON = r), (t.JSON3 = i), (r = i = null)), n
              );
            },
          })
        );
      t.JSON = { parse: n.parse, stringify: n.stringify };
    }.call(this),
      (function (t) {
        if (t.atob)
          try {
            t.atob(" ");
          } catch (s) {
            t.atob =
              ((r = t.atob),
                ((i = function (e) {
                  return r(String(e).replace(/[\t\n\f\r ]+/g, ""));
                }).original = r),
                i);
          }
        else {
          var r,
            i,
            a =
              "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
            n =
              /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;
          (t.btoa = function (t) {
            t = String(t);
            for (
              var r, i, n, s, o = "", c = 0, l = t.length % 3;
              c < t.length;

            )
              ((i = t.charCodeAt(c++)) > 255 ||
                (n = t.charCodeAt(c++)) > 255 ||
                (s = t.charCodeAt(c++)) > 255) &&
                e.log(
                  "Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range."
                ),
                (o +=
                  a.charAt(((r = (i << 16) | (n << 8) | s) >> 18) & 63) +
                  a.charAt((r >> 12) & 63) +
                  a.charAt((r >> 6) & 63) +
                  a.charAt(63 & r));
            return l ? o.slice(0, l - 3) + "===".substring(l) : o;
          }),
            (t.atob = function (t) {
              (t = String(t).replace(/[\t\n\f\r ]+/g, "")),
                n.test(t) ||
                e.log(
                  "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
                ),
                (t += "==".slice(2 - (3 & t.length)));
              for (var r, i, s, o = "", c = 0; c < t.length;)
                (r =
                  (a.indexOf(t.charAt(c++)) << 18) |
                  (a.indexOf(t.charAt(c++)) << 12) |
                  ((i = a.indexOf(t.charAt(c++))) << 6) |
                  (s = a.indexOf(t.charAt(c++)))),
                  (o +=
                    64 === i
                      ? String.fromCharCode((r >> 16) & 255)
                      : 64 === s
                        ? String.fromCharCode((r >> 16) & 255, (r >> 8) & 255)
                        : String.fromCharCode(
                          (r >> 16) & 255,
                          (r >> 8) & 255,
                          255 & r
                        ));
              return o;
            });
        }
      })(window),
      String.prototype.replaceAll ||
      (String.prototype.replaceAll = function (e, t) {
        return "[object regexp]" ===
          Object.prototype.toString.call(e).toLowerCase()
          ? this.replace(e, t)
          : this.replace(new RegExp(e, "g"), t);
      }),
      (g = Array.prototype),
      (h = Function.prototype),
      (v = Object.prototype),
      (y = g.slice),
      (S = v.toString),
      (b = v.hasOwnProperty),
      h.bind,
      (w = g.forEach),
      g.indexOf,
      (k = Array.isArray),
      (j = {}),
      (P = t.each =
        function (e, r, i) {
          if (null == e) return !1;
          if (w && e.forEach === w) e.forEach(r, i);
          else if (t.isArray(e) && e.length === +e.length) {
            for (var a = 0, n = e.length; a < n; a++)
              if (a in e && r.call(i, e[a], a, e) === j) return !1;
          } else
            for (var s in e)
              if (b.call(e, s) && r.call(i, e[s], s, e) === j) return !1;
        }),
      (t.map = function (e, t) {
        var r = [];
        return null == e
          ? r
          : Array.prototype.map && e.map === Array.prototype.map
            ? e.map(t)
            : (P(e, function (e, i, a) {
              r.push(t(e, i, a));
            }),
              r);
      }),
      (t.extend = function (e) {
        return (
          P(y.call(arguments, 1), function (t) {
            for (var r in t) b.call(t, r) && void 0 !== t[r] && (e[r] = t[r]);
          }),
          e
        );
      }),
      (t.extend2Lev = function (e) {
        return (
          P(y.call(arguments, 1), function (r) {
            for (var i in r)
              void 0 !== r[i] &&
                (t.isObject(r[i]) && t.isObject(e[i])
                  ? t.extend(e[i], r[i])
                  : (e[i] = r[i]));
          }),
          e
        );
      }),
      (t.coverExtend = function (e) {
        return (
          P(y.call(arguments, 1), function (t) {
            for (var r in t)
              void 0 !== t[r] && void 0 === e[r] && (e[r] = t[r]);
          }),
          e
        );
      }),
      (t.isArray =
        k ||
        function (e) {
          return "[object Array]" === S.call(e);
        }),
      (t.isFunction = function (e) {
        if (!e) return !1;
        try {
          return /^\s*\bfunction\b/.test(e);
        } catch (t) {
          return !1;
        }
      }),
      (t.isArguments = function (e) {
        return !(!e || !b.call(e, "callee"));
      }),
      (t.toArray = function (e) {
        return e
          ? e.toArray
            ? e.toArray()
            : t.isArray(e)
              ? y.call(e)
              : t.isArguments(e)
                ? y.call(e)
                : t.values(e)
          : [];
      }),
      (t.values = function (e) {
        var t = [];
        return null == e
          ? t
          : (P(e, function (e) {
            t[t.length] = e;
          }),
            t);
      }),
      (t.indexOf = function (e, t) {
        var r = e.indexOf;
        if (r) return r.call(e, t);
        for (var i = 0; i < e.length; i++) if (t === e[i]) return i;
        return -1;
      }),
      (t.hasAttributes = function (e, r) {
        if ("string" == typeof r) return t.hasAttribute(e, r);
        if (t.isArray(r)) {
          for (var i = !1, a = 0; a < r.length; a++)
            if (t.hasAttribute(e, r[a])) {
              i = !0;
              break;
            }
          return i;
        }
      }),
      (t.hasAttribute = function (e, t) {
        return e.hasAttribute
          ? e.hasAttribute(t)
          : !(!e.attributes[t] || !e.attributes[t].specified);
      }),
      (t.filter = function (e, t, r) {
        var i = Object.prototype.hasOwnProperty;
        if (e.filter) return e.filter(t);
        for (var a = [], n = 0; n < e.length; n++)
          if (i.call(e, n)) {
            var s = e[n];
            t.call(r, s, n, e) && a.push(s);
          }
        return a;
      }),
      (t.inherit = function (e, t) {
        return (
          (e.prototype = new t()),
          (e.prototype.constructor = e),
          (e.superclass = t.prototype),
          e
        );
      }),
      (t.trim = function (e) {
        return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
      }),
      (t.isObject = function (e) {
        return null != e && "[object Object]" == S.call(e);
      }),
      (t.isEmptyObject = function (e) {
        if (t.isObject(e)) {
          for (var r in e) if (b.call(e, r)) return !1;
          return !0;
        }
        return !1;
      }),
      (t.isUndefined = function (e) {
        return void 0 === e;
      }),
      (t.isString = function (e) {
        return "[object String]" == S.call(e);
      }),
      (t.isDate = function (e) {
        return "[object Date]" == S.call(e);
      }),
      (t.isBoolean = function (e) {
        return "[object Boolean]" == S.call(e);
      }),
      (t.isNumber = function (e) {
        return "[object Number]" == S.call(e) && /[\d\.]+/.test(String(e));
      }),
      (t.isElement = function (e) {
        return !(!e || 1 !== e.nodeType);
      }),
      (t.isJSONString = function (e) {
        try {
          JSON.parse(e);
        } catch (t) {
          return !1;
        }
        return !0;
      }),
      (t.safeJSONParse = function (e) {
        var t = null;
        try {
          t = JSON.parse(e);
        } catch (r) {
          return !1;
        }
        return t;
      }),
      (t.decodeURIComponent = function (e) {
        var t = e;
        try {
          t = decodeURIComponent(e);
        } catch (r) {
          t = e;
        }
        return t;
      }),
      (t.decodeURI = function (e) {
        var t = e;
        try {
          t = decodeURI(e);
        } catch (r) {
          t = e;
        }
        return t;
      }),
      (t.isDecodeURI = function (e, r) {
        return e ? t.decodeURI(r) : r;
      }),
      (t.encodeDates = function (e) {
        return (
          t.each(e, function (r, i) {
            t.isDate(r)
              ? (e[i] = t.formatDate(r))
              : t.isObject(r) && (e[i] = t.encodeDates(r));
          }),
          e
        );
      }),
      (t.mediaQueriesSupported = function () {
        return (
          "undefined" != typeof window.matchMedia ||
          "undefined" != typeof window.msMatchMedia
        );
      }),
      (t.getScreenOrientation = function () {
        var e =
          screen.msOrientation ||
          screen.mozOrientation ||
          (screen.orientation || {}).type,
          r = "\u672a\u53d6\u5230\u503c";
        if (e) r = e.indexOf("landscape") > -1 ? "landscape" : "portrait";
        else if (t.mediaQueriesSupported()) {
          var i = window.matchMedia || window.msMatchMedia;
          i("(orientation: landscape)").matches
            ? (r = "landscape")
            : i("(orientation: portrait)").matches && (r = "portrait");
        }
        return r;
      }),
      (t.now =
        Date.now ||
        function () {
          return new Date().getTime();
        }),
      (t.throttle = function (e, r, i) {
        var a,
          n,
          s,
          o = null,
          c = 0;
        i || (i = {});
        var l = function () {
          (c = !1 === i.leading ? 0 : t.now()),
            (o = null),
            (s = e.apply(a, n)),
            o || (a = n = null);
        };
        return function () {
          var u = t.now();
          c || !1 !== i.leading || (c = u);
          var p = r - (u - c);
          return (
            (a = this),
            (n = arguments),
            p <= 0 || p > r
              ? (o && (clearTimeout(o), (o = null)),
                (c = u),
                (s = e.apply(a, n)),
                o || (a = n = null))
              : o || !1 === i.trailing || (o = setTimeout(l, p)),
            s
          );
        };
      }),
      (t.hashCode = function (e) {
        if ("string" != typeof e) return 0;
        var t = 0;
        if (0 == e.length) return t;
        for (var r = 0; r < e.length; r++)
          (t = (t << 5) - t + e.charCodeAt(r)), (t &= t);
        return t;
      }),
      (t.formatDate = function (e) {
        function t(e) {
          return e < 10 ? "0" + e : e;
        }
        return (
          e.getFullYear() +
          "-" +
          t(e.getMonth() + 1) +
          "-" +
          t(e.getDate()) +
          " " +
          t(e.getHours()) +
          ":" +
          t(e.getMinutes()) +
          ":" +
          t(e.getSeconds()) +
          "." +
          t(e.getMilliseconds())
        );
      }),
      (t.getRandomBasic =
        ((p = new Date().getTime()),
          function (e) {
            return Math.ceil(((p = (9301 * p + 49297) % 233280) / 233280) * e);
          })),
      (t.getRandom = function () {
        if ("function" == typeof Uint32Array) {
          var e = "";
          if (
            ("undefined" != typeof crypto
              ? (e = crypto)
              : "undefined" != typeof msCrypto && (e = msCrypto),
              t.isObject(e) && e.getRandomValues)
          ) {
            var r = new Uint32Array(1);
            return e.getRandomValues(r)[0] / Math.pow(2, 32);
          }
        }
        return t.getRandomBasic(1e19) / 1e19;
      }),
      (t.searchObjDate = function (e) {
        t.isObject(e) &&
          t.each(e, function (r, i) {
            t.isObject(r)
              ? t.searchObjDate(e[i])
              : t.isDate(r) && (e[i] = t.formatDate(r));
          });
      }),
      (t.searchZZAppStyle = function (e) {
        "undefined" != typeof e.properties.$project &&
          ((e.project = e.properties.$project), delete e.properties.$project),
          "undefined" != typeof e.properties.$token &&
          ((e.token = e.properties.$token), delete e.properties.$token);
      }),
      (t.formatJsonString = function (e) {
        try {
          return JSON.stringify(e, null, "  ");
        } catch (t) {
          return JSON.stringify(e);
        }
      }),
      (t.formatString = function (r, i) {
        return t.isNumber(i) && r.length > i
          ? (e.log(
            "\u5b57\u7b26\u4e32\u957f\u5ea6\u8d85\u8fc7\u9650\u5236\uff0c\u5df2\u7ecf\u505a\u622a\u53d6--" +
            r
          ),
            r.slice(0, i))
          : r;
      }),
      (t.searchObjString = function (r) {
        var i = ["$element_selector", "$element_path"];
        t.isObject(r) &&
          t.each(r, function (a, n) {
            t.isObject(a)
              ? t.searchObjString(r[n])
              : t.isString(a) &&
              (r[n] = t.formatString(
                a,
                t.indexOf(i, n) > -1 ? 1024 : e.para.max_string_length
              ));
          });
      }),
      (t.parseSuperProperties = function (r) {
        var i = r.properties,
          a = JSON.parse(JSON.stringify(r));
        t.isObject(i) &&
          (t.each(i, function (r, n) {
            if (t.isFunction(r))
              try {
                (i[n] = r(a)),
                  t.isFunction(i[n]) &&
                  (e.log(
                    "\u60a8\u7684\u5c5e\u6027- " +
                    n +
                    " \u683c\u5f0f\u4e0d\u6ee1\u8db3\u8981\u6c42\uff0c\u6211\u4eec\u5df2\u7ecf\u5c06\u5176\u5220\u9664"
                  ),
                    delete i[n]);
              } catch (s) {
                delete i[n],
                  e.log(
                    "\u60a8\u7684\u5c5e\u6027- " +
                    n +
                    " \u629b\u51fa\u4e86\u5f02\u5e38\uff0c\u6211\u4eec\u5df2\u7ecf\u5c06\u5176\u5220\u9664"
                  );
              }
          }),
            t.strip_sa_properties(i));
      }),
      (t.filterReservedProperties = function (r) {
        t.isObject(r) &&
          t.each(
            [
              "distinct_id",
              "user_id",
              "id",
              "date",
              "datetime",
              "event",
              "events",
              "first_id",
              "original_id",
              "device_id",
              "properties",
              "second_id",
              "time",
              "users",
            ],
            function (t, i) {
              t in r &&
                (i < 3
                  ? (delete r[t],
                    e.log(
                      "\u60a8\u7684\u5c5e\u6027- " +
                      t +
                      "\u662f\u4fdd\u7559\u5b57\u6bb5\uff0c\u6211\u4eec\u5df2\u7ecf\u5c06\u5176\u5220\u9664"
                    ))
                  : e.log(
                    "\u60a8\u7684\u5c5e\u6027- " +
                    t +
                    "\u662f\u4fdd\u7559\u5b57\u6bb5\uff0c\u8bf7\u907f\u514d\u5176\u4f5c\u4e3a\u5c5e\u6027\u540d"
                  ));
            }
          );
      }),
      (t.searchConfigData = function (e) {
        if ("object" == typeof e && e.$option) {
          var t = e.$option;
          return delete e.$option, t;
        }
        return {};
      }),
      (t.unique = function (e) {
        for (var t, r = [], i = {}, a = 0; a < e.length; a++)
          (t = e[a]) in i || ((i[t] = !0), r.push(t));
        return r;
      }),
      (t.strip_sa_properties = function (r) {
        return t.isObject(r)
          ? (t.each(r, function (i, a) {
            if (t.isArray(i)) {
              var n = [];
              t.each(i, function (r) {
                t.isString(r)
                  ? n.push(r)
                  : e.log(
                    "\u60a8\u7684\u6570\u636e-",
                    a,
                    i,
                    "\u7684\u6570\u7ec4\u91cc\u7684\u503c\u5fc5\u987b\u662f\u5b57\u7b26\u4e32,\u5df2\u7ecf\u5c06\u5176\u5220\u9664"
                  );
              }),
                (r[a] = n);
            }
            t.isString(i) ||
              t.isNumber(i) ||
              t.isDate(i) ||
              t.isBoolean(i) ||
              t.isArray(i) ||
              t.isFunction(i) ||
              "$option" === a ||
              (e.log(
                "\u60a8\u7684\u6570\u636e-",
                a,
                i,
                "-\u683c\u5f0f\u4e0d\u6ee1\u8db3\u8981\u6c42\uff0c\u6211\u4eec\u5df2\u7ecf\u5c06\u5176\u5220\u9664"
              ),
                delete r[a]);
          }),
            r)
          : r;
      }),
      (t.strip_empty_properties = function (e) {
        var r = {};
        return (
          t.each(e, function (e, t) {
            null != e && (r[t] = e);
          }),
          r
        );
      }),
      (t.base64Encode = function (e) {
        return btoa(
          encodeURIComponent(e).replace(/%([0-9A-F]{2})/g, function (e, t) {
            return String.fromCharCode("0x" + t);
          })
        );
      }),
      (t.base64Decode = function (e) {
        var r = t.map(atob(e).split(""), function (e) {
          return "%" + ("00" + e.charCodeAt(0).toString(16)).slice(-2);
        });
        return decodeURIComponent(r.join(""));
      }),
      (t.UUID =
        ((d = function () {
          for (var e = 1 * new Date(), t = 0; e == 1 * new Date();) t++;
          return e.toString(16) + t.toString(16);
        }),
          function () {
            var e = String(screen.height * screen.width);
            e =
              e && /\d{5,}/.test(e)
                ? e.toString(16)
                : String(31242 * t.getRandom())
                  .replace(".", "")
                  .slice(0, 8);
            var r =
              d() +
              "-" +
              t.getRandom().toString(16).replace(".", "") +
              "-" +
              (function (e) {
                var t,
                  r,
                  i = navigator.userAgent,
                  a = [],
                  n = 0;
                function s(e, t) {
                  var r,
                    i = 0;
                  for (r = 0; r < t.length; r++) i |= a[r] << (8 * r);
                  return e ^ i;
                }
                for (t = 0; t < i.length; t++)
                  (r = i.charCodeAt(t)),
                    a.unshift(255 & r),
                    a.length >= 4 && ((n = s(n, a)), (a = []));
                return a.length > 0 && (n = s(n, a)), n.toString(16);
              })() +
              "-" +
              e +
              "-" +
              d();
            return (
              r ||
              (
                String(t.getRandom()) +
                String(t.getRandom()) +
                String(t.getRandom())
              ).slice(2, 15)
            );
          })),
      (t.getQueryParam = function (e, r) {
        (r = r.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")),
          (e = t.decodeURIComponent(e));
        var i = new RegExp("[\\?&]" + r + "=([^&#]*)").exec(e);
        return null === i || (i && "string" != typeof i[1] && i[1].length)
          ? ""
          : t.decodeURIComponent(i[1]);
      }),
      (t.urlParse = function (t) {
        var r = function (e) {
          (this._fields = {
            Username: 4,
            Password: 5,
            Port: 7,
            Protocol: 2,
            Host: 6,
            Path: 8,
            URL: 0,
            QueryString: 9,
            Fragment: 10,
          }),
            (this._values = {}),
            (this._regex = null),
            (this._regex =
              /^((\w+):\/\/)?((\w+):?(\w+)?@)?([^\/\?:]+):?(\d+)?(\/?[^\?#]+)?\??([^#]+)?#?(\w*)/),
            void 0 !== e && this._parse(e);
        };
        return (
          (r.prototype.setUrl = function (e) {
            this._parse(e);
          }),
          (r.prototype._initValues = function () {
            for (var e in this._fields) this._values[e] = "";
          }),
          (r.prototype.addQueryString = function (e) {
            if ("object" != typeof e) return !1;
            var t = this._values.QueryString || "";
            for (var r in e)
              t = new RegExp(r + "[^&]+").test(t)
                ? t.replace(new RegExp(r + "[^&]+"), r + "=" + e[r])
                : "&" === t.slice(-1)
                  ? t + r + "=" + e[r]
                  : "" === t
                    ? r + "=" + e[r]
                    : t + "&" + r + "=" + e[r];
            this._values.QueryString = t;
          }),
          (r.prototype.getUrl = function () {
            var e = "";
            return (
              (e += this._values.Origin),
              (e += this._values.Port ? ":" + this._values.Port : ""),
              (e += this._values.Path),
              (e += this._values.QueryString
                ? "?" + this._values.QueryString
                : ""),
              (e += this._values.Fragment ? "#" + this._values.Fragment : "")
            );
          }),
          (r.prototype.getUrl = function () {
            var e = "";
            return (
              (e += this._values.Origin),
              (e += this._values.Port ? ":" + this._values.Port : ""),
              (e += this._values.Path),
              (e += this._values.QueryString
                ? "?" + this._values.QueryString
                : "")
            );
          }),
          (r.prototype._parse = function (t) {
            this._initValues();
            var r = this._regex.exec(t);
            for (var i in (r || e.log("DPURLParser::_parse -> Invalid URL"),
              this._fields))
              "undefined" != typeof r[this._fields[i]] &&
                (this._values[i] = r[this._fields[i]]);
            (this._values.Hostname = this._values.Host.replace(/:\d+$/, "")),
              (this._values.Origin =
                this._values.Protocol + "://" + this._values.Hostname);
          }),
          new r(t)
        );
      }),
      (t.addEvent = function () {
        function r(e) {
          return (
            e &&
            ((e.preventDefault = r.preventDefault),
              (e.stopPropagation = r.stopPropagation),
              (e._getPath = r._getPath)),
            e
          );
        }
        (r._getPath = function () {
          var e = this;
          return (
            this.path ||
            (this.composedPath && this.composedPath()) ||
            (function () {
              try {
                var t = e.target,
                  r = [t];
                if (null === t || null === t.parentElement) return [];
                for (; null !== t.parentElement;)
                  (t = t.parentElement), r.unshift(t);
                return r;
              } catch (i) {
                return [];
              }
            })()
          );
        }),
          (r.preventDefault = function () {
            this.returnValue = !1;
          }),
          (r.stopPropagation = function () {
            this.cancelBubble = !0;
          }),
          function (i, a, n) {
            var s = !(
              !t.isObject(e.para.heatmap) || !e.para.heatmap.useCapture
            );
            if (
              (t.isObject(e.para.heatmap) &&
                "undefined" == typeof e.para.heatmap.useCapture &&
                "click" === a &&
                (s = !0),
                i && i.addEventListener)
            )
              i.addEventListener(
                a,
                function (e) {
                  (e._getPath = r._getPath), n.call(this, e);
                },
                s
              );
            else {
              var o = "on" + a,
                c = i[o];
              i[o] = (function (e, t, i) {
                return function (a) {
                  if (!(a = a || r(window.event))) return undefined;
                  a.target = a.srcElement;
                  var n,
                    s,
                    o = !0;
                  return (
                    "function" == typeof i && (n = i(a)),
                    (s = t.call(e, a)),
                    (!1 !== n && !1 !== s) || (o = !1),
                    o
                  );
                };
              })(i, n, c);
            }
          }.apply(null, arguments);
      }),
      (t.addHashEvent = function (e) {
        var r = "pushState" in window.history ? "popstate" : "hashchange";
        t.addEvent(window, r, e);
      }),
      (t.addSinglePageEvent = function (e) {
        var r = location.href,
          i = window.history.pushState,
          a = window.history.replaceState;
        (window.history.pushState = function () {
          i.apply(window.history, arguments), e(r), (r = location.href);
        }),
          (window.history.replaceState = function () {
            a.apply(window.history, arguments), e(r), (r = location.href);
          });
        var n = i ? "popstate" : "hashchange";
        t.addEvent(window, n, function () {
          e(r), (r = location.href);
        });
      }),
      (t.cookie = {
        get: function (e) {
          for (
            var r = e + "=", i = document.cookie.split(";"), a = 0;
            a < i.length;
            a++
          ) {
            for (var n = i[a]; " " == n.charAt(0);)
              n = n.substring(1, n.length);
            if (0 == n.indexOf(r))
              return t.decodeURIComponent(n.substring(r.length, n.length));
          }
          return null;
        },
        set: function (r, i, a, n) {
          var s = "",
            o = "",
            c = "";
          if (
            ((a = null == a ? 73e3 : a),
              (n = void 0 === n ? e.para.cross_subdomain : n))
          ) {
            var l = t.getCurrentDomain(location.href);
            "url\u89e3\u6790\u5931\u8d25" === l && (l = ""),
              (s = l ? "; domain=" + l : "");
          }
          if (0 !== a) {
            var u = new Date();
            "s" === String(a).slice(-1)
              ? u.setTime(u.getTime() + 1e3 * Number(String(a).slice(0, -1)))
              : u.setTime(u.getTime() + 24 * a * 60 * 60 * 1e3),
              (o = "; expires=" + u.toGMTString());
          }
          function p(e) {
            return !!e && e.replaceAll(/\r\n/g, "");
          }
          e.para.is_secure_cookie && (c = "; secure");
          var d = "",
            f = "",
            _ = "";
          r && (d = p(r)),
            i && (f = p(i)),
            s && (_ = p(s)),
            d &&
            f &&
            (document.cookie =
              d + "=" + encodeURIComponent(f) + o + "; path=/" + _ + c);
        },
        encrypt: function (e) {
          return "data:enc;" + t.rot13obfs(e);
        },
        decrypt: function (e) {
          return (e = e.substring("data:enc;".length)), (e = t.rot13defs(e));
        },
        resolveValue: function (e) {
          return (
            t.isString(e) &&
            0 === e.indexOf("data:enc;") &&
            (e = t.cookie.decrypt(e)),
            e
          );
        },
        remove: function (r, i) {
          (i = void 0 === i ? e.para.cross_subdomain : i),
            t.cookie.set(r, "", -1, i);
        },
        getCookieName: function (r, i) {
          var a = "";
          if (((i = i || location.href), !1 === e.para.cross_subdomain)) {
            try {
              a = t.URL(i).hostname;
            } catch (n) {
              e.log(n);
            }
            a =
              "string" == typeof a && "" !== a
                ? "sajssdk_2015_" + r + "_" + a.replace(/\./g, "_")
                : "sajssdk_2015_root_" + r;
          } else a = "sajssdk_2015_cross_" + r;
          return a;
        },
        getNewUser: function () {
          return (
            null !== this.get("sensorsdata_is_new_user") ||
            null !== this.get(this.getCookieName("new_user"))
          );
        },
      }),
      (t.getElementContent = function (r, i) {
        var a = "",
          n = "";
        return (
          r.textContent
            ? (a = t.trim(r.textContent))
            : r.innerText && (a = t.trim(r.innerText)),
          a &&
          (a = a
            .replace(/[\r\n]/g, " ")
            .replace(/[ ]+/g, " ")
            .substring(0, 255)),
          (n = a || ""),
          ("input" !== i && "INPUT" !== i) ||
          ("button" === r.type || "submit" === r.type
            ? (n = r.value || "")
            : e.para.heatmap &&
            "function" == typeof e.para.heatmap.collect_input &&
            e.para.heatmap.collect_input(r) &&
            (n = r.value || "")),
          n
        );
      }),
      (t.getEleInfo = function (r) {
        if (!r.target) return !1;
        var i = r.target,
          a = i.tagName.toLowerCase(),
          n = {};
        return (
          (n.$element_type = a),
          (n.$element_name = i.getAttribute("name")),
          (n.$element_id = i.getAttribute("id")),
          (n.$element_class_name =
            "string" == typeof i.className ? i.className : null),
          (n.$element_target_url = i.getAttribute("href")),
          (n.$element_content = t.getElementContent(i, a)),
          ((n = t.strip_empty_properties(n)).$url = t.isDecodeURI(
            e.para.url_is_decode,
            location.href
          )),
          (n.$url_path = location.pathname),
          (n.$title = document.title),
          (n.$viewport_width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth ||
            0),
          n
        );
      }),
      (t.localStorage = {
        get: function (e) {
          return window.localStorage.getItem(e);
        },
        parse: function (r) {
          var i;
          try {
            i = JSON.parse(t.localStorage.get(r)) || null;
          } catch (a) {
            e.log(a);
          }
          return i;
        },
        set: function (e, t) {
          window.localStorage.setItem(e, t);
        },
        remove: function (e) {
          window.localStorage.removeItem(e);
        },
        isSupport: function () {
          var e = !0;
          try {
            var r = "__sensorsdatasupport__",
              i = "testIsSupportStorage";
            t.localStorage.set(r, i),
              t.localStorage.get(r) !== i && (e = !1),
              t.localStorage.remove(r);
          } catch (a) {
            e = !1;
          }
          return e;
        },
      }),
      (t.sessionStorage = {
        isSupport: function () {
          var e = !0,
            t = "testIsSupportStorage";
          try {
            sessionStorage && sessionStorage.setItem
              ? (sessionStorage.setItem("__sensorsdatasupport__", t),
                sessionStorage.removeItem("__sensorsdatasupport__", t),
                (e = !0))
              : (e = !1);
          } catch (r) {
            e = !1;
          }
          return e;
        },
      }),
      (t.isSupportCors = function () {
        return (
          "undefined" != typeof window.XMLHttpRequest &&
          ("withCredentials" in new XMLHttpRequest() ||
            "undefined" != typeof XDomainRequest)
        );
      }),
      (t.xhr = function (t) {
        if (t)
          return "undefined" != typeof window.XMLHttpRequest &&
            "withCredentials" in new XMLHttpRequest()
            ? new XMLHttpRequest()
            : "undefined" != typeof XDomainRequest
              ? new XDomainRequest()
              : null;
        if ("undefined" != typeof window.XMLHttpRequest)
          return new XMLHttpRequest();
        if (window.ActiveXObject)
          try {
            return new ActiveXObject("Msxml2.XMLHTTP");
          } catch (r) {
            try {
              return new ActiveXObject("Microsoft.XMLHTTP");
            } catch (r) {
              e.log(r);
            }
          }
      }),
      (t.ajax = function (r) {
        function i(e) {
          if (!e) return "";
          try {
            return JSON.parse(e);
          } catch (t) {
            return {};
          }
        }
        (r.timeout = r.timeout || 2e4),
          (r.credentials =
            "undefined" == typeof r.credentials || r.credentials);
        var a = t.xhr(r.cors);
        if (!a) return !1;
        r.type || (r.type = r.data ? "POST" : "GET"),
          (r = t.extend({ success: function () { }, error: function () { } }, r)),
          e.debug.protocol.ajax(r.url);
        var n,
          s = r.success,
          o = r.error;
        (r.success = function (e) {
          s(e), n && (clearTimeout(n), (n = null));
        }),
          (r.error = function (e) {
            o(e), n && (clearTimeout(n), (n = null));
          }),
          (n = setTimeout(function () {
            !(function () {
              try {
                t.isObject(a) && a.abort && a.abort();
              } catch (i) {
                e.log(i);
              }
              n &&
                (clearTimeout(n),
                  (n = null),
                  r.error && r.error(),
                  (a.onreadystatechange = null),
                  (a.onload = null),
                  (a.onerror = null));
            })();
          }, r.timeout)),
          "undefined" != typeof XDomainRequest &&
          a instanceof XDomainRequest &&
          ((a.onload = function () {
            r.success && r.success(i(a.responseText)),
              (a.onreadystatechange = null),
              (a.onload = null),
              (a.onerror = null);
          }),
            (a.onerror = function () {
              r.error && r.error(i(a.responseText), a.status),
                (a.onreadystatechange = null),
                (a.onerror = null),
                (a.onload = null);
            })),
          (a.onreadystatechange = function () {
            try {
              4 == a.readyState &&
                ((a.status >= 200 && a.status < 300) || 304 == a.status
                  ? r.success(i(a.responseText))
                  : r.error(i(a.responseText), a.status),
                  (a.onreadystatechange = null),
                  (a.onload = null));
            } catch (e) {
              (a.onreadystatechange = null), (a.onload = null);
            }
          }),
          a.open(r.type, r.url, !0);
        try {
          r.credentials && (a.withCredentials = !0),
            t.isObject(r.header) &&
            t.each(r.header, function (e, t) {
              a.setRequestHeader && a.setRequestHeader(t, e);
            }),
            r.data &&
            (r.cors ||
              (a.setRequestHeader &&
                a.setRequestHeader("X-Requested-With", "XMLHttpRequest")),
              "application/json" === r.contentType
                ? a.setRequestHeader &&
                a.setRequestHeader(
                  "Content-type",
                  "application/json; charset=UTF-8"
                )
                : a.setRequestHeader &&
                a.setRequestHeader(
                  "Content-type",
                  "application/x-www-form-urlencoded"
                ));
        } catch (c) {
          e.log(c);
        }
        a.send(r.data || null);
      }),
      (t.loadScript = function (e) {
        e = t.extend(
          {
            success: function () { },
            error: function () { },
            appendCall: function (e) {
              document.getElementsByTagName("head")[0].appendChild(e);
            },
          },
          e
        );
        var r = null;
        "css" === e.type &&
          (((r = document.createElement("link")).rel = "stylesheet"),
            (r.href = e.url)),
          "js" === e.type &&
          (((r = document.createElement("script")).async = "async"),
            r.setAttribute("charset", "UTF-8"),
            (r.src = e.url),
            (r.type = "text/javascript")),
          (r.onload = r.onreadystatechange =
            function () {
              (this.readyState &&
                "loaded" !== this.readyState &&
                "complete" !== this.readyState) ||
                (e.success(), (r.onload = r.onreadystatechange = null));
            }),
          (r.onerror = function () {
            e.error(), (r.onerror = null);
          }),
          e.appendCall(r);
      }),
      (t.getHostname = function (r, i) {
        (i && "string" == typeof i) || (i = "hostname\u89e3\u6790\u5f02\u5e38");
        var a = null;
        try {
          a = t.URL(r).hostname;
        } catch (n) {
          e.log(
            "getHostname\u4f20\u5165\u7684url\u53c2\u6570\u4e0d\u5408\u6cd5\uff01"
          );
        }
        return a || i;
      }),
      (t.getQueryParamsFromUrl = function (e) {
        var r = {},
          i = e.split("?")[1] || "";
        return i && (r = t.getURLSearchParams("?" + i)), r;
      }),
      (t.getURLSearchParams = function (e) {
        for (
          var t = function (e) {
            return decodeURIComponent(e);
          },
          r = {},
          i = (e = e || "").substring(1).split("&"),
          a = 0;
          a < i.length;
          a++
        ) {
          var n = i[a].indexOf("=");
          if (-1 !== n) {
            var s = i[a].substring(0, n),
              o = i[a].substring(n + 1);
            (s = t(s)), (o = t(o)), (r[s] = o);
          }
        }
        return r;
      }),
      (t.URL = function (r) {
        var i,
          a = {};
        if (
          "function" == typeof window.URL &&
          (function () {
            try {
              return (
                "http://modernizr.com/" ===
                new URL("http://modernizr.com/").href
              );
            } catch (e) {
              return !1;
            }
          })()
        )
          (a = new URL(r)).searchParams ||
            (a.searchParams =
              ((i = t.getURLSearchParams(a.search)),
              {
                get: function (e) {
                  return i[e];
                },
              }));
        else {
          !1 === /^https?:\/\/.+/.test(r) && e.log("Invalid URL");
          var n = t.urlParse(r);
          (a.hash = ""),
            (a.host = n._values.Host
              ? n._values.Host + (n._values.Port ? ":" + n._values.Port : "")
              : ""),
            (a.href = n._values.URL),
            (a.password = n._values.Password),
            (a.pathname = n._values.Path),
            (a.port = n._values.Port),
            (a.search = n._values.QueryString
              ? "?" + n._values.QueryString
              : ""),
            (a.username = n._values.Username),
            (a.hostname = n._values.Hostname),
            (a.protocol = n._values.Protocol ? n._values.Protocol + ":" : ""),
            (a.origin = n._values.Origin
              ? n._values.Origin + (n._values.Port ? ":" + n._values.Port : "")
              : ""),
            (a.searchParams = (function () {
              var e = t.getURLSearchParams("?" + n._values.QueryString);
              return {
                get: function (t) {
                  return e[t];
                },
              };
            })());
        }
        return a;
      }),
      (t.getCurrentDomain = function (r) {
        var i = e.para.current_domain;
        switch (typeof i) {
          case "function":
            var a = i();
            return "" === a || "" === t.trim(a)
              ? "url\u89e3\u6790\u5931\u8d25"
              : -1 !== a.indexOf(".")
                ? a
                : "url\u89e3\u6790\u5931\u8d25";
          case "string":
            return "" === i || "" === t.trim(i)
              ? "url\u89e3\u6790\u5931\u8d25"
              : -1 !== i.indexOf(".")
                ? i
                : "url\u89e3\u6790\u5931\u8d25";
          default:
            var n = t.getCookieTopLevelDomain();
            return "" === r
              ? "url\u89e3\u6790\u5931\u8d25"
              : "" === n
                ? "url\u89e3\u6790\u5931\u8d25"
                : n;
        }
      }),
      (t.getCookieTopLevelDomain = function (e) {
        var r = (e = e || location.hostname) || !1;
        if (!r) return "";
        var i = r.split(".");
        if (t.isArray(i) && i.length >= 2 && !/^(\d+\.)+\d+$/.test(r))
          for (var a = "." + i.splice(i.length - 1, 1); i.length > 0;)
            if (
              ((a = "." + i.splice(i.length - 1, 1) + a),
                (document.cookie =
                  "sensorsdata_domain_test=true; path=/; domain=" + a),
                -1 !== document.cookie.indexOf("sensorsdata_domain_test=true"))
            ) {
              var n = new Date();
              return (
                n.setTime(n.getTime() - 1e3),
                (document.cookie =
                  "sensorsdata_domain_test=true; expires=" +
                  n.toGMTString() +
                  "; path=/; domain=" +
                  a),
                a
              );
            }
        return "";
      }),
      (t.isReferralTraffic = function (e) {
        return (
          "" === (e = e || document.referrer) ||
          t.getCookieTopLevelDomain(t.getHostname(e)) !==
          t.getCookieTopLevelDomain()
        );
      }),
      (t.ry = function (e) {
        return new t.ry.init(e);
      }),
      (t.ry.init = function (e) {
        this.ele = e;
      }),
      (t.ry.init.prototype = {
        addClass: function (e) {
          return (
            -1 === (" " + this.ele.className + " ").indexOf(" " + e + " ") &&
            (this.ele.className =
              this.ele.className +
              ("" === this.ele.className ? "" : " ") +
              e),
            this
          );
        },
        removeClass: function (e) {
          var t = " " + this.ele.className + " ";
          return (
            -1 !== t.indexOf(" " + e + " ") &&
            (this.ele.className = t.replace(" " + e + " ", " ").slice(1, -1)),
            this
          );
        },
        hasClass: function (e) {
          return -1 !== (" " + this.ele.className + " ").indexOf(" " + e + " ");
        },
        attr: function (e, r) {
          return "string" == typeof e && t.isUndefined(r)
            ? this.ele.getAttribute(e)
            : ("string" == typeof e &&
              ((r = String(r)), this.ele.setAttribute(e, r)),
              this);
        },
        offset: function () {
          var e = this.ele.getBoundingClientRect();
          if (e.width || e.height) {
            var t = this.ele.ownerDocument.documentElement;
            return {
              top: e.top + window.pageYOffset - t.clientTop,
              left: e.left + window.pageXOffset - t.clientLeft,
            };
          }
          return { top: 0, left: 0 };
        },
        getSize: function () {
          if (!window.getComputedStyle)
            return {
              width: this.ele.offsetWidth,
              height: this.ele.offsetHeight,
            };
          try {
            var e = this.ele.getBoundingClientRect();
            return { width: e.width, height: e.height };
          } catch (t) {
            return { width: 0, height: 0 };
          }
        },
        getStyle: function (e) {
          return this.ele.currentStyle
            ? this.ele.currentStyle[e]
            : this.ele.ownerDocument.defaultView
              .getComputedStyle(this.ele, null)
              .getPropertyValue(e);
        },
        wrap: function (e) {
          var r = document.createElement(e);
          return (
            this.ele.parentNode.insertBefore(r, this.ele),
            r.appendChild(this.ele),
            t.ry(r)
          );
        },
        getCssStyle: function (e) {
          var r = this.ele.style.getPropertyValue(e);
          if (r) return r;
          var i = null;
          if (
            ("function" == typeof window.getMatchedCSSRules &&
              (i = getMatchedCSSRules(this.ele)),
              !i || !t.isArray(i))
          )
            return null;
          for (var a = i.length - 1; a >= 0; a--)
            if ((r = i[a].style.getPropertyValue(e))) return r;
        },
        sibling: function (e, t) {
          for (; (e = e[t]) && 1 !== e.nodeType;);
          return e;
        },
        next: function () {
          return this.sibling(this.ele, "nextSibling");
        },
        prev: function (e) {
          return this.sibling(this.ele, "previousSibling");
        },
        siblings: function (e) {
          return this.siblings(
            (this.ele.parentNode || {}).firstChild,
            this.ele
          );
        },
        children: function (e) {
          return this.siblings(this.ele.firstChild);
        },
        parent: function () {
          var e = this.ele.parentNode;
          return (e = e && 11 !== e.nodeType ? e : null), t.ry(e);
        },
        previousElementSibling: function () {
          var e = this.ele;
          if ("previousElementSibling" in document.documentElement)
            return t.ry(e.previousElementSibling);
          for (; (e = e.previousSibling);)
            if (1 === e.nodeType) return t.ry(e);
          return t.ry(null);
        },
        getSameTypeSiblings: function () {
          for (
            var e = this.ele,
            t = e.parentNode,
            r = e.tagName.toLowerCase(),
            i = [],
            a = 0;
            a < t.children.length;
            a++
          ) {
            var n = t.children[a];
            1 === n.nodeType &&
              n.tagName.toLowerCase() === r &&
              i.push(t.children[a]);
          }
          return i;
        },
      }),
      (t.strToUnicode = function (t) {
        if ("string" != typeof t)
          return e.log("\u8f6c\u6362unicode\u9519\u8bef", t), t;
        for (var r = "", i = 0; i < t.length; i++)
          r += "\\" + t.charCodeAt(i).toString(16);
        return r;
      }),
      (t.getReferrer = function (t) {
        return "string" != typeof (t = t || document.referrer)
          ? "\u53d6\u503c\u5f02\u5e38_referrer\u5f02\u5e38_" + String(t)
          : (0 === t.indexOf("https://www.baidu.com/") && (t = t.split("?")[0]),
            "string" ==
              typeof (t = t.slice(0, e.para.max_referrer_string_length))
              ? t
              : "");
      }),
      (t.getKeywordFromReferrer = function (r) {
        r = r || document.referrer;
        var i = e.para.source_type.keyword;
        if (document && "string" == typeof r) {
          if (0 === r.indexOf("http")) {
            var a = t.getReferSearchEngine(r),
              n = t.getQueryParamsFromUrl(r);
            if (t.isEmptyObject(n)) return "\u672a\u53d6\u5230\u503c";
            var s = null;
            for (var o in i)
              if (a === o && "object" == typeof n)
                if (((s = i[o]), t.isArray(s)))
                  for (o = 0; o < s.length; o++) {
                    var c = n[s[o]];
                    if (c) return c;
                  }
                else if (n[s]) return n[s];
            return "\u672a\u53d6\u5230\u503c";
          }
          return "" === r
            ? "\u672a\u53d6\u5230\u503c_\u76f4\u63a5\u6253\u5f00"
            : "\u672a\u53d6\u5230\u503c_\u975ehttp\u7684url";
        }
        return "\u53d6\u503c\u5f02\u5e38_referrer\u5f02\u5e38_" + String(r);
      }),
      (t.getWxAdIdFromUrl = function (e) {
        var r = t.getQueryParam(e, "gdt_vid"),
          i = t.getQueryParam(e, "hash_key"),
          a = t.getQueryParam(e, "callbacks"),
          n = { click_id: "", hash_key: "", callbacks: "" };
        return (
          t.isString(r) &&
          r.length &&
          ((n.click_id =
            16 == r.length || 18 == r.length
              ? r
              : "\u53c2\u6570\u89e3\u6790\u4e0d\u5408\u6cd5"),
            t.isString(i) && i.length && (n.hash_key = i),
            t.isString(a) && a.length && (n.callbacks = a)),
          n
        );
      }),
      (t.getReferSearchEngine = function (r) {
        var i = t.getHostname(r);
        if (!i || "hostname\u89e3\u6790\u5f02\u5e38" === i) return "";
        e.para.source_type.keyword;
        var a = {
          baidu: [/^.*\.baidu\.com$/],
          bing: [/^.*\.bing\.com$/],
          google: [
            /^www\.google\.com$/,
            /^www\.google\.com\.[a-z]{2}$/,
            /^www\.google\.[a-z]{2}$/,
          ],
          sm: [/^m\.sm\.cn$/],
          so: [/^.+\.so\.com$/],
          sogou: [/^.*\.sogou\.com$/],
          yahoo: [/^.*\.yahoo\.com$/],
        };
        for (var n in a)
          for (var s = a[n], o = 0, c = s.length; o < c; o++)
            if (s[o].test(i)) return n;
        return "\u672a\u77e5\u641c\u7d22\u5f15\u64ce";
      }),
      (t.getSourceFromReferrer = function () {
        function r(e, t) {
          for (var r = 0; r < e.length; r++)
            if (-1 !== t.split("?")[0].indexOf(e[r])) return !0;
        }
        var i = "(" + e.para.source_type.utm.join("|") + ")\\=[^&]+",
          a = e.para.source_type.search,
          n = e.para.source_type.social,
          s = document.referrer || "",
          o = t.info.pageProp.url;
        if (o) {
          var c = o.match(new RegExp(i));
          return c && c[0]
            ? "\u4ed8\u8d39\u5e7f\u544a\u6d41\u91cf"
            : r(a, s)
              ? "\u81ea\u7136\u641c\u7d22\u6d41\u91cf"
              : r(n, s)
                ? "\u793e\u4ea4\u7f51\u7ad9\u6d41\u91cf"
                : "" === s
                  ? "\u76f4\u63a5\u6d41\u91cf"
                  : "\u5f15\u8350\u6d41\u91cf";
        }
        return "\u83b7\u53d6url\u5f02\u5e38";
      }),
      (t.info = {
        initPage: function () {
          var r = t.getReferrer(),
            i = location.href,
            a = t.getCurrentDomain(i);
          a || e.debug.jssdkDebug("url_domain\u5f02\u5e38_" + i + "_" + a),
            (this.pageProp = {
              referrer: r,
              referrer_host: r ? t.getHostname(r) : "",
              url: i,
              url_host: t.getHostname(i, "url_host\u53d6\u503c\u5f02\u5e38"),
              url_domain: a,
            });
        },
        pageProp: {},
        campaignParams: function () {
          var r = e.source_channel_standard.split(" "),
            i = "",
            a = {};
          return (
            t.isArray(e.para.source_channel) &&
            e.para.source_channel.length > 0 &&
            ((r = r.concat(e.para.source_channel)), (r = t.unique(r))),
            t.each(r, function (e) {
              (i = t.getQueryParam(location.href, e)).length && (a[e] = i);
            }),
            a
          );
        },
        campaignParamsStandard: function (r, i) {
          (r = r || ""), (i = i || "");
          var a = t.info.campaignParams(),
            n = {},
            s = {};
          return (
            t.each(a, function (t, a, o) {
              -1 !==
                (" " + e.source_channel_standard + " ").indexOf(" " + a + " ")
                ? (n[r + a] = o[a])
                : (s[i + a] = o[a]);
            }),
            { $utms: n, otherUtms: s }
          );
        },
        properties: function () {
          return {
            $timezone_offset: new Date().getTimezoneOffset(),
            $screen_height: Number(screen.height) || 0,
            $screen_width: Number(screen.width) || 0,
            $lib: "js",
            $lib_version: String(e.lib_version),
          };
        },
        currentProps: {},
        register: function (e) {
          t.extend(t.info.currentProps, e);
        },
      }),
      (t.autoExeQueue = function () {
        return {
          items: [],
          enqueue: function (e) {
            this.items.push(e), this.start();
          },
          dequeue: function () {
            return this.items.shift();
          },
          getCurrentItem: function () {
            return this.items[0];
          },
          isRun: !1,
          start: function () {
            this.items.length > 0 &&
              !this.isRun &&
              ((this.isRun = !0), this.getCurrentItem().start());
          },
          close: function () {
            this.dequeue(), (this.isRun = !1), this.start();
          },
        };
      }),
      (t.trackLink = function (r, i, a) {
        var n = null;
        if (
          ((r = r || {}).ele && (n = r.ele),
            r.event && (n = r.target ? r.target : r.event.target),
            (a = a || {}),
            !n || "object" != typeof n)
        )
          return !1;
        if (
          !n.href ||
          /^javascript/.test(n.href) ||
          n.target ||
          n.download ||
          n.onclick
        )
          return e.track(i, a), !1;
        function s(t) {
          t.stopPropagation(), t.preventDefault();
          var r = !1;
          function s() {
            r || ((r = !0), (location.href = n.href));
          }
          setTimeout(s, 1e3), e.track(i, a, s);
        }
        r.event && s(r.event),
          r.ele &&
          t.addEvent(r.ele, "click", function (e) {
            s(e);
          });
      }),
      (t.eventEmitter = function () {
        (this._events = []), (this.pendingEvents = []);
      }),
      (t.eventEmitter.prototype = {
        emit: function (e) {
          var r = [].slice.call(arguments, 1);
          t.each(this._events, function (t) {
            t.type === e && t.callback.apply(t.context, r);
          });
        },
        on: function (e, t, r) {
          "function" == typeof t &&
            this._events.push({ type: e, callback: t, context: r || this });
        },
        tempAdd: function (e, t) {
          t &&
            e &&
            (this.pendingEvents.push({ type: e, data: t }),
              this.pendingEvents.length > 20 && this.pendingEvents.shift());
        },
        isReady: function () {
          var e = this;
          (this.tempAdd = this.emit),
            0 !== this.pendingEvents.length &&
            (t.each(this.pendingEvents, function (t) {
              e.emit(t.type, t.data);
            }),
              (this.pendingEvents = []));
        },
      }),
      (t.rot13obfs = function (e, t) {
        t = "number" == typeof t ? t : 13;
        for (var r = (e = String(e)).split(""), i = 0, a = r.length; i < a; i++)
          r[i].charCodeAt(0) < 126 &&
            (r[i] = String.fromCharCode((r[i].charCodeAt(0) + t) % 126));
        return r.join("");
      }),
      (t.rot13defs = function (e) {
        return (e = String(e)), t.rot13obfs(e, 113);
      }),
      (t.urlSafeBase64 =
        ((f = { "+": "-", "/": "_", "=": "." }),
          (_ = { "-": "+", _: "/", ".": "=" }),
        {
          encode: function (e) {
            return e.replace(/[+\/=]/g, function (e) {
              return f[e];
            });
          },
          decode: function (e) {
            return e.replace(/[-_.]/g, function (e) {
              return _[e];
            });
          },
          trim: function (e) {
            return e.replace(/[.=]{1,2}$/, "");
          },
          isBase64: function (e) {
            return /^[A-Za-z0-9+\/]*[=]{0,2}$/.test(e);
          },
          isUrlSafeBase64: function (e) {
            return /^[A-Za-z0-9_-]*[.]{0,2}$/.test(e);
          },
        })),
      (t.setCssStyle = function (e) {
        var t = document.createElement("style");
        t.type = "text/css";
        try {
          t.appendChild(document.createTextNode(e));
        } catch (a) {
          t.styleSheet.cssText = e;
        }
        var r = document.getElementsByTagName("head")[0],
          i = document.getElementsByTagName("script")[0];
        r
          ? r.children.length
            ? r.insertBefore(t, r.children[0])
            : r.appendChild(t)
          : i.parentNode.insertBefore(t, i);
      }),
      (t.isIOS = function () {
        return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
      }),
      (t.getIOSVersion = function () {
        try {
          var e = navigator.appVersion.match(/OS (\d+)[._](\d+)[._]?(\d+)?/);
          return e && e[1] ? Number.parseInt(e[1], 10) : "";
        } catch (t) {
          return "";
        }
      }),
      (t.getUA = function () {
        var e,
          t = {},
          r = navigator.userAgent.toLowerCase();
        return (
          (e = r.match(/opera.([\d.]+)/))
            ? (t.opera = Number(e[1].split(".")[0]))
            : (e = r.match(/msie ([\d.]+)/))
              ? (t.ie = Number(e[1].split(".")[0]))
              : (e = r.match(/edge.([\d.]+)/))
                ? (t.edge = Number(e[1].split(".")[0]))
                : (e = r.match(/firefox\/([\d.]+)/))
                  ? (t.firefox = Number(e[1].split(".")[0]))
                  : (e = r.match(/chrome\/([\d.]+)/))
                    ? (t.chrome = Number(e[1].split(".")[0]))
                    : (e = r.match(/version\/([\d.]+).*safari/)) &&
                    (t.safari = Number(e[1].match(/^\d*.\d*/))),
          t
        );
      }),
      (t.getDomBySelector = function (r) {
        if (!t.isString(r)) return null;
        var i,
          a = r.split(">");
        return (i = (function n(r) {
          var i,
            s = a.shift();
          if (!s) return r;
          try {
            i = (function (e, r) {
              var i;
              if ("body" === (e = t.trim(e)))
                return document.getElementsByTagName("body")[0];
              if (0 === e.indexOf("#"))
                (e = e.slice(1)), (i = document.getElementById(e));
              else if (e.indexOf(":nth-of-type") > -1) {
                var a = e.split(":nth-of-type");
                if (!a[0] || !a[1]) return null;
                var n = a[0],
                  s = a[1].match(/\(([0-9]+)\)/);
                if (!s || !s[1]) return null;
                var o = Number(s[1]);
                if (!(t.isElement(r) && r.children && r.children.length > 0))
                  return null;
                for (var c = r.children, l = 0; l < c.length; l++)
                  if (
                    t.isElement(c[l]) &&
                    c[l].tagName.toLowerCase() === n &&
                    0 == --o
                  ) {
                    i = c[l];
                    break;
                  }
                if (o > 0) return null;
              }
              return i || null;
            })(s, r);
          } catch (o) {
            e.log(o);
          }
          return i && t.isElement(i) ? n(i) : null;
        })()) && t.isElement(i)
          ? i
          : null;
      }),
      (t.jsonp = function (r) {
        if (!t.isObject(r) || !t.isString(r.callbackName))
          return e.log("JSONP \u8bf7\u6c42\u7f3a\u5c11 callbackName"), !1;
        (r.success = t.isFunction(r.success) ? r.success : function () { }),
          (r.error = t.isFunction(r.error) ? r.error : function () { }),
          (r.data = r.data || "");
        var i = document.createElement("script"),
          a = document.getElementsByTagName("head")[0],
          n = null,
          s = !1;
        if (
          (a.appendChild(i),
            t.isNumber(r.timeout) &&
            (n = setTimeout(function () {
              if (s) return !1;
              r.error("timeout"),
                (window[r.callbackName] = function () {
                  e.log("call jsonp error");
                }),
                (n = null),
                a.removeChild(i),
                (s = !0);
            }, r.timeout)),
            (window[r.callbackName] = function () {
              clearTimeout(n),
                (n = null),
                r.success.apply(null, arguments),
                (window[r.callbackName] = function () {
                  e.log("call jsonp error");
                }),
                a.removeChild(i);
            }),
            r.url.indexOf("?") > -1
              ? (r.url += "&callbackName=" + r.callbackName)
              : (r.url += "?callbackName=" + r.callbackName),
            t.isObject(r.data))
        ) {
          var o = [];
          t.each(r.data, function (e, t) {
            o.push(t + "=" + e);
          }),
            (r.data = o.join("&")),
            (r.url += "&" + r.data);
        }
        (i.onerror = function (t) {
          if (s) return !1;
          (window[r.callbackName] = function () {
            e.log("call jsonp error");
          }),
            clearTimeout(n),
            (n = null),
            a.removeChild(i),
            r.error(t),
            (s = !0);
        }),
          (i.src = r.url);
      }),
      (t.listenPageState = function (e) {
        ({
          visibleHandle: t.isFunction(e.visible) ? e.visible : function () { },
          hiddenHandler: t.isFunction(e.hidden) ? e.hidden : function () { },
          visibilityChange: null,
          hidden: null,
          isSupport: function () {
            return "undefined" != typeof document[this.hidden];
          },
          init: function () {
            "undefined" != typeof document.hidden
              ? ((this.hidden = "hidden"),
                (this.visibilityChange = "visibilitychange"))
              : "undefined" != typeof document.mozHidden
                ? ((this.hidden = "mozHidden"),
                  (this.visibilityChange = "mozvisibilitychange"))
                : "undefined" != typeof document.msHidden
                  ? ((this.hidden = "msHidden"),
                    (this.visibilityChange = "msvisibilitychange"))
                  : "undefined" != typeof document.webkitHidden &&
                  ((this.hidden = "webkitHidden"),
                    (this.visibilityChange = "webkitvisibilitychange")),
              this.listen();
          },
          listen: function () {
            if (this.isSupport()) {
              var e = this;
              document.addEventListener(
                e.visibilityChange,
                function () {
                  document[e.hidden] ? e.hiddenHandler() : e.visibleHandle();
                },
                1
              );
            } else
              document.addEventListener
                ? (window.addEventListener("focus", this.visibleHandle, 1),
                  window.addEventListener("blur", this.hiddenHandler, 1))
                : (document.attachEvent("onfocusin", this.visibleHandle),
                  document.attachEvent("onfocusout", this.hiddenHandler));
          },
        }.init());
      }),
      (t.isSupportBeaconSend = function () {
        var e = t.getUA(),
          r = !1,
          i = navigator.userAgent.toLowerCase();
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
        ) {
          var a = (i.match(/os [\d._]*/gi) + "")
            .replace(/[^0-9|_.]/gi, "")
            .replace(/_/gi, ".")
            .split(".");
          "undefined" == typeof e.safari && (e.safari = a[0]),
            a[0] && a[0] < 13
              ? (e.chrome > 41 ||
                e.firefox > 30 ||
                e.opera > 25 ||
                e.safari > 12) &&
              (r = !0)
              : (e.chrome > 41 ||
                e.firefox > 30 ||
                e.opera > 25 ||
                e.safari > 11.3) &&
              (r = !0);
        } else
          (e.chrome > 38 ||
            e.edge > 13 ||
            e.firefox > 30 ||
            e.opera > 25 ||
            e.safari > 11) &&
            (r = !0);
        return r;
      }),
      (t.secCheck = {
        isHttpUrl: function (t) {
          return (
            "string" == typeof t &&
            (!1 !== /^https?:\/\/.+/.test(t) || (e.log("Invalid URL"), !1))
          );
        },
        removeScriptProtocol: function (e) {
          if ("string" != typeof e) return "";
          for (var t = /^\s*javascript/i; t.test(e);) e = e.replace(t, "");
          return e;
        },
      }),
      (e.para_default = {
        preset_properties: {
          latest_utm: !0,
          latest_traffic_source_type: !0,
          latest_search_keyword: !0,
          latest_referrer: !0,
          latest_referrer_host: !1,
          latest_landing_page: !1,
          latest_wx_ad_click_id: undefined,
          url: !0,
          title: !0,
        },
        encrypt_cookie: !1,
        img_use_crossorigin: !1,
        name: "sa",
        max_referrer_string_length: 200,
        max_string_length: 500,
        cross_subdomain: !0,
        show_log: !0,
        is_debug: !1,
        debug_mode: !1,
        debug_mode_upload: !1,
        session_time: 0,
        use_client_time: !1,
        source_channel: [],
        send_type: "image",
        vtrack_ignore: {},
        auto_init: !0,
        is_track_single_page: !1,
        is_single_page: !1,
        batch_send: !1,
        source_type: {},
        callback_timeout: 200,
        datasend_timeout: 3e3,
        queue_timeout: 300,
        is_track_device_id: !1,
        ignore_oom: !0,
        app_js_bridge: !1,
        url_is_decode: !1,
      }),
      (e.addReferrerHost = function (r) {
        t.isObject(r.properties) &&
          (r.properties.$first_referrer &&
            (r.properties.$first_referrer_host = t.getHostname(
              r.properties.$first_referrer,
              "\u53d6\u503c\u5f02\u5e38"
            )),
            ("track" !== r.type && "track_signup" !== r.type) ||
            ("$referrer" in r.properties &&
              (r.properties.$referrer_host =
                "" === r.properties.$referrer
                  ? ""
                  : t.getHostname(
                    r.properties.$referrer,
                    "\u53d6\u503c\u5f02\u5e38"
                  )),
              e.para.preset_properties.latest_referrer &&
              e.para.preset_properties.latest_referrer_host &&
              (r.properties.$latest_referrer_host =
                "" === r.properties.$latest_referrer
                  ? ""
                  : t.getHostname(
                    r.properties.$latest_referrer,
                    "\u53d6\u503c\u5f02\u5e38"
                  ))));
      }),
      (e.addPropsHook = function (r) {
        e.para.preset_properties &&
          e.para.preset_properties.url &&
          ("track" === r.type || "track_signup" === r.type) &&
          "undefined" == typeof r.properties.$url &&
          (r.properties.$url = t.isDecodeURI(
            e.para.url_is_decode,
            window.location.href
          )),
          e.para.preset_properties &&
          e.para.preset_properties.title &&
          ("track" === r.type || "track_signup" === r.type) &&
          "undefined" == typeof r.properties.$title &&
          (r.properties.$title = document.title);
      }),
      (e.initPara = function (r) {
        e.para = r || e.para || {};
        var i,
          a = {};
        if (t.isObject(e.para.is_track_latest))
          for (var n in e.para.is_track_latest)
            a["latest_" + n] = e.para.is_track_latest[n];
        for (i in ((e.para.preset_properties = t.extend(
          {},
          e.para_default.preset_properties,
          a,
          e.para.preset_properties || {}
        )),
          e.para_default))
          void 0 === e.para[i] && (e.para[i] = e.para_default[i]);
        "string" == typeof e.para.server_url &&
          ((e.para.server_url = t.trim(e.para.server_url)),
            e.para.server_url &&
            ("://" === e.para.server_url.slice(0, 3)
              ? (e.para.server_url =
                location.protocol.slice(0, -1) + e.para.server_url)
              : "//" === e.para.server_url.slice(0, 2)
                ? (e.para.server_url = location.protocol + e.para.server_url)
                : "http" !== e.para.server_url.slice(0, 4) &&
                (e.para.server_url = ""))),
          "string" != typeof e.para.web_url ||
          ("://" !== e.para.web_url.slice(0, 3) &&
            "//" !== e.para.web_url.slice(0, 2)) ||
          ("://" === e.para.web_url.slice(0, 3)
            ? (e.para.web_url =
              location.protocol.slice(0, -1) + e.para.web_url)
            : (e.para.web_url = location.protocol + e.para.web_url)),
          "image" !== e.para.send_type &&
          "ajax" !== e.para.send_type &&
          "beacon" !== e.para.send_type &&
          (e.para.send_type = "image"),
          e.debug.protocol.serverUrl(),
          e.bridge.initPara(),
          e.bridge.initState();
        var s = { datasend_timeout: 6e3, send_interval: 6e3 };
        t.localStorage.isSupport() &&
          t.isSupportCors() &&
          "object" == typeof localStorage
          ? !0 === e.para.batch_send
            ? ((e.para.batch_send = t.extend({}, s)),
              (e.para.use_client_time = !0))
            : "object" == typeof e.para.batch_send &&
            ((e.para.use_client_time = !0),
              (e.para.batch_send = t.extend({}, s, e.para.batch_send)))
          : (e.para.batch_send = !1);
        var o = [
          "utm_source",
          "utm_medium",
          "utm_campaign",
          "utm_content",
          "utm_term",
        ],
          c = [
            "www.baidu.",
            "m.baidu.",
            "m.sm.cn",
            "so.com",
            "sogou.com",
            "youdao.com",
            "google.",
            "yahoo.com/",
            "bing.com/",
            "ask.com/",
          ],
          l = [
            "weibo.com",
            "renren.com",
            "kaixin001.com",
            "douban.com",
            "qzone.qq.com",
            "zhihu.com",
            "tieba.baidu.com",
            "weixin.qq.com",
          ],
          u = {
            baidu: ["wd", "word", "kw", "keyword"],
            google: "q",
            bing: "q",
            yahoo: "p",
            sogou: ["query", "keyword"],
            so: "q",
            sm: "q",
          };
        "object" == typeof e.para.source_type &&
          ((e.para.source_type.utm = t.isArray(e.para.source_type.utm)
            ? e.para.source_type.utm.concat(o)
            : o),
            (e.para.source_type.search = t.isArray(e.para.source_type.search)
              ? e.para.source_type.search.concat(c)
              : c),
            (e.para.source_type.social = t.isArray(e.para.source_type.social)
              ? e.para.source_type.social.concat(l)
              : l),
            (e.para.source_type.keyword = t.isObject(e.para.source_type.keyword)
              ? t.extend(u, e.para.source_type.keyword)
              : u));
        var p = [
          "mark",
          "/mark",
          "strong",
          "b",
          "em",
          "i",
          "u",
          "abbr",
          "ins",
          "del",
          "s",
          "sup",
        ];
        if (t.isObject(e.para.heatmap)) {
          (e.para.heatmap.clickmap = e.para.heatmap.clickmap || "default"),
            (e.para.heatmap.scroll_notice_map =
              e.para.heatmap.scroll_notice_map || "default"),
            (e.para.heatmap.scroll_delay_time =
              e.para.heatmap.scroll_delay_time || 4e3),
            (e.para.heatmap.scroll_event_duration =
              e.para.heatmap.scroll_event_duration || 18e3),
            (e.para.heatmap.renderRefreshTime =
              e.para.heatmap.renderRefreshTime || 1e3),
            (e.para.heatmap.loadTimeout = e.para.heatmap.loadTimeout || 1e3),
            !1 !== e.para.heatmap.get_vtrack_config &&
            (e.para.heatmap.get_vtrack_config = !0);
          var d = t.isArray(e.para.heatmap.track_attr)
            ? t.filter(e.para.heatmap.track_attr, function (e) {
              return e && "string" == typeof e;
            })
            : [];
          if (
            (d.push("data-sensors-click"),
              (e.para.heatmap.track_attr = d),
              t.isObject(e.para.heatmap.collect_tags))
          )
            if (!0 === e.para.heatmap.collect_tags.div)
              e.para.heatmap.collect_tags.div = {
                ignore_tags: p,
                max_level: 1,
              };
            else if (t.isObject(e.para.heatmap.collect_tags.div)) {
              if (
                (e.para.heatmap.collect_tags.div.ignore_tags
                  ? t.isArray(e.para.heatmap.collect_tags.div.ignore_tags) ||
                  (e.log(
                    "ignore_tags \u53c2\u6570\u5fc5\u987b\u662f\u6570\u7ec4\u683c\u5f0f"
                  ),
                    (e.para.heatmap.collect_tags.div.ignore_tags = p))
                  : (e.para.heatmap.collect_tags.div.ignore_tags = p),
                  e.para.heatmap.collect_tags.div.max_level)
              ) {
                -1 ===
                  t.indexOf(
                    [1, 2, 3],
                    e.para.heatmap.collect_tags.div.max_level
                  ) && (e.para.heatmap.collect_tags.div.max_level = 1);
              }
            } else e.para.heatmap.collect_tags.div = !1;
          else e.para.heatmap.collect_tags = { div: !1 };
        }
        if (t.isArray(e.para.server_url) && e.para.server_url.length)
          for (i = 0; i < e.para.server_url.length; i++)
            /sa\.gif[^\/]*$/.test(e.para.server_url[i]) ||
              (e.para.server_url[i] = e.para.server_url[i]
                .replace(/\/sa$/, "/sa.gif")
                .replace(/(\/sa)(\?[^\/]+)$/, "/sa.gif$2"));
        else
          /sa\.gif[^\/]*$/.test(e.para.server_url) ||
            "string" != typeof e.para.server_url ||
            (e.para.server_url = e.para.server_url
              .replace(/\/sa$/, "/sa.gif")
              .replace(/(\/sa)(\?[^\/]+)$/, "/sa.gif$2"));
        "string" == typeof e.para.server_url &&
          (e.para.debug_mode_url =
            e.para.debug_mode_url ||
            e.para.server_url.replace("sa.gif", "debug")),
          !0 === e.para.noCache
            ? (e.para.noCache = "?" + new Date().getTime())
            : (e.para.noCache = ""),
          e.para.callback_timeout > e.para.datasend_timeout &&
          (e.para.datasend_timeout = e.para.callback_timeout),
          e.para.callback_timeout > e.para.queue_timeout &&
          (e.para.queue_timeout = e.para.callback_timeout),
          e.para.queue_timeout > e.para.datasend_timeout &&
          (e.para.datasend_timeout = e.para.queue_timeout);
      }),
      (e.readyState = {
        state: 0,
        historyState: [],
        stateType: {
          1: "1-init\u672a\u5f00\u59cb",
          2: "2-init\u5f00\u59cb",
          3: "3-store\u5b8c\u6210",
        },
        getState: function () {
          return this.historyState.join("\n");
        },
        setState: function (e) {
          String(e) in this.stateType && (this.state = e),
            this.historyState.push(this.stateType[e]);
        },
      }),
      (e.setPreConfig = function (t) {
        (e.para = t.para), (e._q = t._q);
      }),
      (e.setInitVar = function () {
        (e._t = e._t || 1 * new Date()),
          (e.lib_version = "1.18.1"),
          (e.is_first_visitor = !1),
          (e.source_channel_standard =
            "utm_source utm_medium utm_campaign utm_content utm_term");
      }),
      (e.log = function () {
        if (
          ((t.sessionStorage.isSupport() &&
            "true" === sessionStorage.getItem("sensorsdata_jssdk_debug")) ||
            e.para.show_log) &&
          (!t.isObject(arguments[0]) ||
            (!0 !== e.para.show_log &&
              "string" !== e.para.show_log &&
              !1 !== e.para.show_log) ||
            (arguments[0] = t.formatJsonString(arguments[0])),
            "object" == typeof console && console.log)
        )
          try {
            return console.log.apply(console, arguments);
          } catch (r) {
            console.log(arguments[0]);
          }
      }),
      (e.enableLocalLog = function () {
        if (t.sessionStorage.isSupport())
          try {
            sessionStorage.setItem("sensorsdata_jssdk_debug", "true");
          } catch (r) {
            e.log("enableLocalLog error: " + r.message);
          }
      }),
      (e.disableLocalLog = function () {
        t.sessionStorage.isSupport() &&
          sessionStorage.removeItem("sensorsdata_jssdk_debug");
      }),
      (e.debug = {
        distinct_id: function () { },
        jssdkDebug: function () { },
        _sendDebug: function (t) {
          e.track("_sensorsdata2019_debug", { _jssdk_debug_info: t });
        },
        apph5: function (r) {
          var i = "app_h5\u6253\u901a\u5931\u8d25-",
            a = {
              1: i + "use_app_track\u4e3afalse",
              2:
                i +
                "Android\u6216\u8005iOS\uff0c\u6ca1\u6709\u66b4\u9732\u76f8\u5e94\u65b9\u6cd5",
              3.1: i + "Android\u6821\u9a8cserver_url\u5931\u8d25",
              3.2: i + "iOS\u6821\u9a8cserver_url\u5931\u8d25",
              4.1: i + "H5 \u6821\u9a8c iOS server_url \u5931\u8d25",
              4.2: i + "H5 \u6821\u9a8c Android server_url \u5931\u8d25",
            },
            n = r.output,
            s = r.step,
            o = r.data || "";
          ("all" !== n && "console" !== n) || e.log(a[s]),
            ("all" === n || "code" === n) &&
            t.isObject(e.para.is_debug) &&
            e.para.is_debug.apph5 &&
            ((o.type && "profile" === o.type.slice(0, 7)) ||
              (o.properties._jssdk_debug_info = "apph5-" + String(s)));
        },
        defineMode: function (e) {
          var t = {
            1: {
              title:
                "\u5f53\u524d\u9875\u9762\u65e0\u6cd5\u8fdb\u884c\u53ef\u89c6\u5316\u5168\u57cb\u70b9",
              message:
                "App SDK \u4e0e Web JS SDK \u6ca1\u6709\u8fdb\u884c\u6253\u901a\uff0c\u8bf7\u8054\u7cfb\u8d35\u65b9\u6280\u672f\u4eba\u5458\u4fee\u6b63 App SDK \u7684\u914d\u7f6e\uff0c\u8be6\u7ec6\u4fe1\u606f\u8bf7\u67e5\u770b\u6587\u6863\u3002",
              link_text: "\u914d\u7f6e\u6587\u6863",
              link_url:
                "https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_link-1573913.html",
            },
            2: {
              title:
                "\u5f53\u524d\u9875\u9762\u65e0\u6cd5\u8fdb\u884c\u53ef\u89c6\u5316\u5168\u57cb\u70b9",
              message:
                "App SDK \u4e0e Web JS SDK \u6ca1\u6709\u8fdb\u884c\u6253\u901a\uff0c\u8bf7\u8054\u7cfb\u8d35\u65b9\u6280\u672f\u4eba\u5458\u4fee\u6b63 Web JS SDK \u7684\u914d\u7f6e\uff0c\u8be6\u7ec6\u4fe1\u606f\u8bf7\u67e5\u770b\u6587\u6863\u3002",
              link_text: "\u914d\u7f6e\u6587\u6863",
              link_url:
                "https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_link-1573913.html",
            },
            3: {
              title:
                "\u5f53\u524d\u9875\u9762\u65e0\u6cd5\u8fdb\u884c\u53ef\u89c6\u5316\u5168\u57cb\u70b9",
              message:
                "Web JS SDK \u6ca1\u6709\u5f00\u542f\u5168\u57cb\u70b9\u914d\u7f6e\uff0c\u8bf7\u8054\u7cfb\u8d35\u65b9\u5de5\u4f5c\u4eba\u5458\u4fee\u6b63 SDK \u7684\u914d\u7f6e\uff0c\u8be6\u7ec6\u4fe1\u606f\u8bf7\u67e5\u770b\u6587\u6863\u3002",
              link_text: "\u914d\u7f6e\u6587\u6863",
              link_url:
                "https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_web_all-1573964.html",
            },
            4: {
              title:
                "\u5f53\u524d\u9875\u9762\u65e0\u6cd5\u8fdb\u884c\u53ef\u89c6\u5316\u5168\u57cb\u70b9",
              message:
                "Web JS SDK \u914d\u7f6e\u7684\u6570\u636e\u6821\u9a8c\u5730\u5740\u4e0e App SDK \u914d\u7f6e\u7684\u6570\u636e\u6821\u9a8c\u5730\u5740\u4e0d\u4e00\u81f4\uff0c\u8bf7\u8054\u7cfb\u8d35\u65b9\u5de5\u4f5c\u4eba\u5458\u4fee\u6b63 SDK \u7684\u914d\u7f6e\uff0c\u8be6\u7ec6\u4fe1\u606f\u8bf7\u67e5\u770b\u6587\u6863\u3002",
              link_text: "\u914d\u7f6e\u6587\u6863",
              link_url:
                "https://manual.sensorsdata.cn/sa/latest/tech_sdk_client_link-1573913.html",
            },
          };
          return !(!e || !t[e]) && t[e];
        },
        protocol: {
          protocolIsSame: function (r, i) {
            try {
              if (t.URL(r).protocol !== t.URL(i).protocol) return !1;
            } catch (a) {
              return e.log("\u4e0d\u652f\u6301 _.URL \u65b9\u6cd5"), !1;
            }
            return !0;
          },
          serverUrl: function () {
            t.isString(e.para.server_url) &&
              "" !== e.para.server_url &&
              !this.protocolIsSame(e.para.server_url, location.href) &&
              e.log(
                "SDK \u68c0\u6d4b\u5230\u60a8\u7684\u6570\u636e\u53d1\u9001\u5730\u5740\u548c\u5f53\u524d\u9875\u9762\u5730\u5740\u7684\u534f\u8bae\u4e0d\u4e00\u81f4\uff0c\u5efa\u8bae\u60a8\u4fee\u6539\u6210\u4e00\u81f4\u7684\u534f\u8bae\u3002\n\u56e0\u4e3a\uff1a1\u3001https \u4e0b\u9762\u53d1\u9001 http \u7684\u56fe\u7247\u8bf7\u6c42\u4f1a\u5931\u8d25\u30022\u3001http \u9875\u9762\u4f7f\u7528 https + ajax \u65b9\u5f0f\u53d1\u6570\u636e\uff0c\u5728 ie9 \u53ca\u4ee5\u4e0b\u4f1a\u4e22\u5931\u6570\u636e\u3002"
              );
          },
          ajax: function (r) {
            if (r === e.para.server_url) return !1;
            t.isString(r) &&
              "" !== r &&
              !this.protocolIsSame(r, location.href) &&
              e.log(
                "SDK \u68c0\u6d4b\u5230\u60a8\u7684\u6570\u636e\u53d1\u9001\u5730\u5740\u548c\u5f53\u524d\u9875\u9762\u5730\u5740\u7684\u534f\u8bae\u4e0d\u4e00\u81f4\uff0c\u5efa\u8bae\u60a8\u4fee\u6539\u6210\u4e00\u81f4\u7684\u534f\u8bae\u3002\u56e0\u4e3a http \u9875\u9762\u4f7f\u7528 https + ajax \u65b9\u5f0f\u53d1\u6570\u636e\uff0c\u5728 ie9 \u53ca\u4ee5\u4e0b\u4f1a\u4e22\u5931\u6570\u636e\u3002"
              );
          },
        },
      }));
    var r = {
      setOnlineState: function (r) {
        if (
          !0 === r &&
          t.isObject(e.para.jsapp) &&
          "function" == typeof e.para.jsapp.getData
        ) {
          e.para.jsapp.isOnline = !0;
          var i = e.para.jsapp.getData();
          t.isArray(i) &&
            i.length > 0 &&
            t.each(i, function (r) {
              t.isJSONString(r) && e.sendState.pushSend(JSON.parse(r));
            });
        } else e.para.jsapp.isOnline = !1;
      },
      autoTrackIsUsed: !1,
      isReady: function (e) {
        e();
      },
      getUtm: function () {
        return t.info.campaignParams();
      },
      getStayTime: function () {
        return (new Date() - e._t) / 1e3;
      },
      setProfileLocal: function (r) {
        if (!t.localStorage.isSupport()) return e.setProfile(r), !1;
        if (!t.isObject(r) || t.isEmptyObject(r)) return !1;
        var i = t.localStorage.parse("sensorsdata_2015_jssdk_profile"),
          a = !1;
        if (t.isObject(i) && !t.isEmptyObject(i)) {
          for (var n in r)
            (!(n in i && i[n] !== r[n]) && n in i) || ((i[n] = r[n]), (a = !0));
          a &&
            (t.localStorage.set(
              "sensorsdata_2015_jssdk_profile",
              JSON.stringify(i)
            ),
              e.setProfile(r));
        } else
          t.localStorage.set(
            "sensorsdata_2015_jssdk_profile",
            JSON.stringify(r)
          ),
            e.setProfile(r);
      },
      setInitReferrer: function () {
        var r = t.getReferrer();
        e.setOnceProfile({
          _init_referrer: r,
          _init_referrer_host: t.info.pageProp.referrer_host,
        });
      },
      setSessionReferrer: function () {
        var e = t.getReferrer();
        c.setSessionPropsOnce({
          _session_referrer: e,
          _session_referrer_host: t.info.pageProp.referrer_host,
        });
      },
      setDefaultAttr: function () {
        t.info.register({
          _current_url: location.href,
          _referrer: t.getReferrer(),
          _referring_host: t.info.pageProp.referrer_host,
        });
      },
      trackHeatMap: function (r, i, a) {
        if ("object" == typeof r && r.tagName) {
          var n = r.tagName.toLowerCase(),
            s = r.parentNode.tagName.toLowerCase(),
            o =
              e.para.heatmap && e.para.heatmap.track_attr
                ? e.para.heatmap.track_attr
                : ["data-sensors-click"];
          "button" === n ||
            "a" === n ||
            "a" === s ||
            "button" === s ||
            "input" === n ||
            "textarea" === n ||
            t.hasAttributes(r, o) ||
            u.start(null, r, n, i, a);
        }
      },
      trackAllHeatMap: function (e, t, r) {
        if ("object" == typeof e && e.tagName) {
          var i = e.tagName.toLowerCase();
          u.start(null, e, i, t, r);
        }
      },
      autoTrackSinglePage: function (r, i) {
        if (this.autoTrackIsUsed) var a = t.info.pageProp.url;
        else a = t.info.pageProp.referrer;
        function n() {
          var r = t.info.campaignParams(),
            i = {};
          return (
            t.each(r, function (t, r, a) {
              -1 !==
                (" " + e.source_channel_standard + " ").indexOf(" " + r + " ")
                ? (i["$" + r] = a[r])
                : (i[r] = a[r]);
            }),
            i
          );
        }
        function s(r, i) {
          e.track(
            "$pageview",
            t.extend(
              {
                $referrer: t.isDecodeURI(e.para.url_is_decode, a),
                $url: t.isDecodeURI(e.para.url_is_decode, location.href),
                $url_path: location.pathname,
                $title: document.title,
              },
              r,
              n()
            ),
            i
          ),
            (a = location.href);
        }
        (r = t.isObject(r) ? r : {}),
          (r = t.isObject(r) ? r : {}),
          e.is_first_visitor &&
          !r.not_set_profile &&
          (e.setOnceProfile(
            t.extend(
              {
                $first_visit_time: new Date(),
                $first_referrer: t.isDecodeURI(
                  e.para.url_is_decode,
                  t.getReferrer()
                ),
                $first_browser_language:
                  navigator.language || "\u53d6\u503c\u5f02\u5e38",
                $first_browser_charset:
                  "string" == typeof document.charset
                    ? document.charset.toUpperCase()
                    : "\u53d6\u503c\u5f02\u5e38",
                $first_traffic_source_type: t.getSourceFromReferrer(),
                $first_search_keyword: t.getKeywordFromReferrer(),
              },
              n()
            )
          ),
            (e.is_first_visitor = !1)),
          r.not_set_profile && delete r.not_set_profile,
          s(r, i),
          (this.autoTrackSinglePage = s);
      },
      autoTrackWithoutProfile: function (e, r) {
        (e = t.isObject(e) ? e : {}),
          this.autoTrack(t.extend(e, { not_set_profile: !0 }), r);
      },
      autoTrack: function (r, i) {
        r = t.isObject(r) ? r : {};
        var a = t.info.campaignParams(),
          n = {};
        t.each(a, function (t, r, i) {
          -1 !== (" " + e.source_channel_standard + " ").indexOf(" " + r + " ")
            ? (n["$" + r] = i[r])
            : (n[r] = i[r]);
        }),
          e.is_first_visitor &&
          !r.not_set_profile &&
          (e.setOnceProfile(
            t.extend(
              {
                $first_visit_time: new Date(),
                $first_referrer: t.isDecodeURI(
                  e.para.url_is_decode,
                  t.getReferrer()
                ),
                $first_browser_language:
                  navigator.language || "\u53d6\u503c\u5f02\u5e38",
                $first_browser_charset:
                  "string" == typeof document.charset
                    ? document.charset.toUpperCase()
                    : "\u53d6\u503c\u5f02\u5e38",
                $first_traffic_source_type: t.getSourceFromReferrer(),
                $first_search_keyword: t.getKeywordFromReferrer(),
              },
              n
            )
          ),
            (e.is_first_visitor = !1)),
          r.not_set_profile && delete r.not_set_profile;
        var s = location.href;
        e.para.is_single_page &&
          t.addHashEvent(function () {
            var a = t.getReferrer(s);
            e.track(
              "$pageview",
              t.extend(
                {
                  $referrer: t.isDecodeURI(e.para.url_is_decode, a),
                  $url: t.isDecodeURI(e.para.url_is_decode, location.href),
                  $url_path: location.pathname,
                  $title: document.title,
                },
                n,
                r
              ),
              i
            ),
              (s = location.href);
          }),
          e.track(
            "$pageview",
            t.extend(
              {
                $referrer: t.isDecodeURI(e.para.url_is_decode, t.getReferrer()),
                $url: t.isDecodeURI(e.para.url_is_decode, location.href),
                $url_path: location.pathname,
                $title: document.title,
              },
              n,
              r
            ),
            i
          ),
          (this.autoTrackIsUsed = !0);
      },
      getAnonymousID: function () {
        return t.isEmptyObject(e.store._state)
          ? "\u8bf7\u5148\u521d\u59cb\u5316SDK"
          : e.store._state._first_id ||
          e.store._state.first_id ||
          e.store._state._distinct_id ||
          e.store._state.distinct_id;
      },
      setPlugin: function (r) {
        if (!t.isObject(r)) return !1;
        t.each(r, function (r, i) {
          t.isFunction(r) &&
            (t.isObject(window.TutorDataWebJSSDKPlugin) &&
              window.TutorDataWebJSSDKPlugin[i]
              ? r(window.TutorDataWebJSSDKPlugin[i])
              : e.log(
                i +
                "\u6ca1\u6709\u83b7\u53d6\u5230,\u8bf7\u67e5\u9605\u6587\u6863\uff0c\u8c03\u6574" +
                i +
                "\u7684\u5f15\u5165\u987a\u5e8f\uff01"
              ));
        });
      },
      useModulePlugin: function () {
        e.use.apply(e, arguments);
      },
      useAppPlugin: function () {
        this.setPlugin.apply(this, arguments);
      },
    };
    function i() {
      (this.sendingData = 0), (this.sendingItemKeys = []);
    }
    (e.quick = function () {
      var t = Array.prototype.slice.call(arguments),
        i = t[0],
        a = t.slice(1);
      if ("string" == typeof i && r[i]) return r[i].apply(r, a);
      "function" == typeof i
        ? i.apply(e, a)
        : e.log(
          "quick\u65b9\u6cd5\u4e2d\u6ca1\u6709\u8fd9\u4e2a\u529f\u80fd" + t[0]
        );
    }),
      (e.use = function (r, i) {
        return t.isString(r)
          ? t.isObject(window.TutorDataWebJSSDKPlugin) &&
            t.isObject(window.TutorDataWebJSSDKPlugin[r]) &&
            t.isFunction(window.TutorDataWebJSSDKPlugin[r].init)
            ? (window.TutorDataWebJSSDKPlugin[r].init(e, i),
              window.TutorDataWebJSSDKPlugin[r])
            : t.isObject(e.modules) &&
              t.isObject(e.modules[r]) &&
              t.isFunction(e.modules[r].init)
              ? (e.modules[r].init(e, i), e.modules[r])
              : void e.log(
                r +
                "\u6ca1\u6709\u83b7\u53d6\u5230,\u8bf7\u67e5\u9605\u6587\u6863\uff0c\u8c03\u6574" +
                r +
                "\u7684\u5f15\u5165\u987a\u5e8f\uff01"
              )
          : (e.log(
            "use\u63d2\u4ef6\u540d\u79f0\u5fc5\u987b\u662f\u5b57\u7b26\u4e32\uff01"
          ),
            !1);
      }),
      (e.track = function (e, t, r) {
        s.check({ event: e, properties: t }) &&
          s.send({ type: "track", event: e, properties: t }, r);
      }),
      (e.trackLink = function (e, r, i) {
        "object" == typeof e && e.tagName
          ? t.trackLink({ ele: e }, r, i)
          : "object" == typeof e && e.target && e.event && t.trackLink(e, r, i);
      }),
      (e.trackLinks = function (r, i, a) {
        return (
          (a = a || {}),
          !(!r || "object" != typeof r) &&
          !(!r.href || /^javascript/.test(r.href) || r.target) &&
          void t.addEvent(r, "click", function (t) {
            t.preventDefault();
            var n = !1;
            function s() {
              n || ((n = !0), (location.href = r.href));
            }
            setTimeout(s, 1e3), e.track(i, a, s);
          })
        );
      }),
      (e.setProfile = function (e, t) {
        s.check({ propertiesMust: e }) &&
          s.send({ type: "profile_set", properties: e }, t);
      }),
      (e.setOnceProfile = function (e, t) {
        s.check({ propertiesMust: e }) &&
          s.send({ type: "profile_set_once", properties: e }, t);
      }),
      (e.appendProfile = function (r, i) {
        s.check({ propertiesMust: r }) &&
          (t.each(r, function (i, a) {
            t.isString(i)
              ? (r[a] = [i])
              : t.isArray(i)
                ? (r[a] = i)
                : (delete r[a],
                  e.log(
                    "appendProfile\u5c5e\u6027\u7684\u503c\u5fc5\u987b\u662f\u5b57\u7b26\u4e32\u6216\u8005\u6570\u7ec4"
                  ));
          }),
            t.isEmptyObject(r) ||
            s.send({ type: "profile_append", properties: r }, i));
      }),
      (e.incrementProfile = function (r, i) {
        var a = r;
        t.isString(r) && ((r = {})[a] = 1),
          s.check({ propertiesMust: r }) &&
          (!(function (e) {
            for (var t in e)
              if (
                Object.prototype.hasOwnProperty.call(e, t) &&
                !/-*\d+/.test(String(e[t]))
              )
                return !1;
            return !0;
          })(r)
            ? e.log(
              "profile_increment\u7684\u503c\u53ea\u80fd\u662f\u6570\u5b57"
            )
            : s.send({ type: "profile_increment", properties: r }, i));
      }),
      (e.deleteProfile = function (e) {
        s.send({ type: "profile_delete" }, e),
          c.set("distinct_id", t.UUID()),
          c.set("first_id", "");
      }),
      (e.unsetProfile = function (r, i) {
        var a = r,
          n = {};
        t.isString(r) && (r = []).push(a),
          t.isArray(r)
            ? (t.each(r, function (r) {
              t.isString(r)
                ? (n[r] = !0)
                : e.log(
                  "profile_unset\u7ed9\u7684\u6570\u7ec4\u91cc\u9762\u7684\u503c\u5fc5\u987b\u65f6string,\u5df2\u7ecf\u8fc7\u6ee4\u6389",
                  r
                );
            }),
              s.send({ type: "profile_unset", properties: n }, i))
            : e.log("profile_unset\u7684\u53c2\u6570\u662f\u6570\u7ec4");
      }),
      (e.identify = function (r, i) {
        "number" == typeof r && (r = String(r));
        var a = c.getFirstId();
        if (void 0 === r) {
          var n = t.UUID();
          a ? c.set("first_id", n) : c.set("distinct_id", n);
        } else
          s.check({ distinct_id: r })
            ? !0 === i
              ? a
                ? c.set("first_id", r)
                : c.set("distinct_id", r)
              : a
                ? c.change("first_id", r)
                : c.change("distinct_id", r)
            : e.log(
              "identify\u7684\u53c2\u6570\u5fc5\u987b\u662f\u5b57\u7b26\u4e32"
            );
      }),
      (e.trackSignup = function (e, t, r, i) {
        if (s.check({ distinct_id: e, event: t, properties: r })) {
          var a = c.getFirstId() || c.getDistinctId();
          c.set("distinct_id", e),
            s.send(
              {
                original_id: a,
                distinct_id: e,
                type: "track_signup",
                event: t,
                properties: r,
              },
              i
            );
        }
      }),
      (e.registerPage = function (r) {
        s.check({ properties: r })
          ? t.extend(t.info.currentProps, r)
          : e.log("register\u8f93\u5165\u7684\u53c2\u6570\u6709\u8bef");
      }),
      (e.clearAllRegister = function (e) {
        c.clearAllProps(e);
      }),
      (e.clearPageRegister = function (e) {
        if (t.isArray(e) && e.length > 0)
          for (var r = 0; r < e.length; r++)
            t.isString(e[r]) &&
              e[r] in t.info.currentProps &&
              delete t.info.currentProps[e[r]];
        else if (!0 === e)
          for (var r in t.info.currentProps) delete t.info.currentProps[r];
      }),
      (e.register = function (t) {
        s.check({ properties: t })
          ? c.setProps(t)
          : e.log("register\u8f93\u5165\u7684\u53c2\u6570\u6709\u8bef");
      }),
      (e.registerOnce = function (t) {
        s.check({ properties: t })
          ? c.setPropsOnce(t)
          : e.log("registerOnce\u8f93\u5165\u7684\u53c2\u6570\u6709\u8bef");
      }),
      (e.registerSession = function (t) {
        s.check({ properties: t })
          ? c.setSessionProps(t)
          : e.log("registerSession\u8f93\u5165\u7684\u53c2\u6570\u6709\u8bef");
      }),
      (e.registerSessionOnce = function (t) {
        s.check({ properties: t })
          ? c.setSessionPropsOnce(t)
          : e.log(
            "registerSessionOnce\u8f93\u5165\u7684\u53c2\u6570\u6709\u8bef"
          );
      }),
      (e.login = function (t, r) {
        if (
          ("number" == typeof t && (t = String(t)), s.check({ distinct_id: t }))
        ) {
          var i = c.getFirstId(),
            a = c.getDistinctId();
          t !== a
            ? (i || c.set("first_id", a), e.trackSignup(t, "$SignUp", {}, r))
            : r && r();
        } else
          e.log("login\u7684\u53c2\u6570\u5fc5\u987b\u662f\u5b57\u7b26\u4e32"),
            r && r();
      }),
      (e.logout = function (r) {
        var i = c.getFirstId();
        if (i)
          if ((c.set("first_id", ""), !0 === r)) {
            var a = t.UUID();
            c.set("distinct_id", a);
          } else c.set("distinct_id", i);
        else e.log("\u6ca1\u6709first_id\uff0clogout\u5931\u8d25");
      }),
      (e.getPresetProperties = function () {
        var r,
          i,
          a = {
            $is_first_day: t.cookie.getNewUser(),
            $referrer:
              t.isDecodeURI(e.para.url_is_decode, t.info.pageProp.referrer) ||
              "",
            $referrer_host: t.info.pageProp.referrer
              ? t.getHostname(t.info.pageProp.referrer)
              : "",
            $url: t.isDecodeURI(e.para.url_is_decode, location.href),
            $url_path: location.pathname,
            $title: document.title || "",
            _distinct_id: c.getDistinctId(),
          },
          n = t.extend(
            {},
            t.info.properties(),
            e.store.getProps(),
            ((r = t.info.campaignParams()),
              (i = {}),
              t.each(r, function (t, r, a) {
                -1 !==
                  (" " + e.source_channel_standard + " ").indexOf(" " + r + " ")
                  ? (i["$" + r] = a[r])
                  : (i[r] = a[r]);
              }),
              i),
            a
          );
        return (
          e.para.preset_properties.latest_referrer &&
          e.para.preset_properties.latest_referrer_host &&
          (n.$latest_referrer_host =
            "" === n.$latest_referrer
              ? ""
              : t.getHostname(n.$latest_referrer)),
          n
        );
      }),
      (e.detectMode = function () {
        var r = {
          searchKeywordMatch: location.search.match(/sa-request-id=([^&#]+)/),
          isSeachHasKeyword: function () {
            var e = this.searchKeywordMatch;
            return (
              !!(e && e[0] && e[1]) &&
              ("string" ==
                typeof sessionStorage.getItem("sensors-visual-mode") &&
                sessionStorage.removeItem("sensors-visual-mode"),
                !0)
            );
          },
          hasKeywordHandle: function () {
            var e = this.searchKeywordMatch,
              r = location.search.match(/sa-request-type=([^&#]+)/),
              i = location.search.match(/sa-request-url=([^&#]+)/);
            u.setNotice(i),
              t.sessionStorage.isSupport() &&
              (i &&
                i[0] &&
                i[1] &&
                sessionStorage.setItem(
                  "sensors_heatmap_url",
                  decodeURIComponent(i[1])
                ),
                sessionStorage.setItem("sensors_heatmap_id", e[1]),
                r && r[0] && r[1]
                  ? "1" === r[1] || "2" === r[1] || "3" === r[1]
                    ? ((r = r[1]),
                      sessionStorage.setItem("sensors_heatmap_type", r))
                    : (r = null)
                  : (r =
                    null !== sessionStorage.getItem("sensors_heatmap_type")
                      ? sessionStorage.getItem("sensors_heatmap_type")
                      : null)),
              this.isReady(e[1], r);
          },
          isReady: function (r, i, a) {
            e.para.heatmap_url
              ? t.loadScript({
                success: function () {
                  setTimeout(function () {
                    "undefined" != typeof sa_jssdk_heatmap_render &&
                      (sa_jssdk_heatmap_render(e, r, i, a),
                        "object" == typeof console &&
                        "function" == typeof console.log &&
                        ((e.heatmap_version &&
                          e.heatmap_version === e.lib_version) ||
                          console.log(
                            "heatmap.js\u4e0esensorsdata.js\u7248\u672c\u53f7\u4e0d\u4e00\u81f4\uff0c\u53ef\u80fd\u5b58\u5728\u98ce\u9669!"
                          )));
                  }, 0);
                },
                error: function () { },
                type: "js",
                url: e.para.heatmap_url,
              })
              : e.log(
                "\u6ca1\u6709\u6307\u5b9aheatmap_url\u7684\u8def\u5f84"
              );
          },
          isStorageHasKeyword: function () {
            return (
              t.sessionStorage.isSupport() &&
              "string" == typeof sessionStorage.getItem("sensors_heatmap_id")
            );
          },
          storageHasKeywordHandle: function () {
            u.setNotice(),
              r.isReady(
                sessionStorage.getItem("sensors_heatmap_id"),
                sessionStorage.getItem("sensors_heatmap_type"),
                location.href
              );
          },
        },
          i = {
            isStorageHasKeyword: function () {
              return (
                t.sessionStorage.isSupport() &&
                "string" == typeof sessionStorage.getItem("sensors-visual-mode")
              );
            },
            isSearchHasKeyword: function () {
              return (
                !!location.search.match(/sa-visual-mode=true/) &&
                ("string" ==
                  typeof sessionStorage.getItem("sensors_heatmap_id") &&
                  sessionStorage.removeItem("sensors_heatmap_id"),
                  !0)
              );
            },
            loadVtrack: function () {
              t.loadScript({
                success: function () { },
                error: function () { },
                type: "js",
                url: e.para.vtrack_url
                  ? e.para.vtrack_url
                  : location.protocol +
                  "//static.sensorsdata.cn/sdk/" +
                  e.lib_version +
                  "/vtrack.min.js",
              });
            },
            messageListener: function (r) {
              if ("sa-fe" !== r.data.source) return !1;
              if ("v-track-mode" === r.data.type) {
                if (r.data.data && r.data.data.isVtrack)
                  if (
                    (t.sessionStorage.isSupport() &&
                      sessionStorage.setItem("sensors-visual-mode", "true"),
                      r.data.data.userURL &&
                      location.search.match(/sa-visual-mode=true/))
                  ) {
                    var a =
                      ((n = r.data.data.userURL),
                        t.secCheck.isHttpUrl(n)
                          ? t.secCheck.removeScriptProtocol(n)
                          : (e.log(
                            "\u53ef\u89c6\u5316\u6a21\u5f0f\u68c0\u6d4b URL \u5931\u8d25"
                          ),
                            !1));
                    a && (window.location.href = a);
                  } else i.loadVtrack();
                window.removeEventListener("message", i.messageListener, !1);
              }
              var n;
            },
            removeMessageHandle: function () {
              window.removeEventListener &&
                window.removeEventListener("message", i.messageListener, !1);
            },
            verifyVtrackMode: function () {
              window.addEventListener &&
                window.addEventListener("message", i.messageListener, !1),
                i.postMessage();
            },
            postMessage: function () {
              window.parent &&
                window.parent.postMessage &&
                window.parent.postMessage(
                  {
                    source: "sa-web-sdk",
                    type: "v-is-vtrack",
                    data: { sdkversion: "1.18.1" },
                  },
                  "*"
                );
            },
            notifyUser: function () {
              var e = function (t) {
                if ("sa-fe" !== t.data.source) return !1;
                "v-track-mode" === t.data.type &&
                  (t.data.data &&
                    t.data.data.isVtrack &&
                    alert(
                      "\u5f53\u524d\u7248\u672c\u4e0d\u652f\u6301\uff0c\u8bf7\u5347\u7ea7\u90e8\u7f72\u795e\u7b56\u6570\u636e\u6cbb\u7406"
                    ),
                    window.removeEventListener("message", e, !1));
              };
              window.addEventListener &&
                window.addEventListener("message", e, !1),
                i.postMessage();
            },
          },
          a = function (r) {
            var i = e.bridge.initDefineBridgeInfo();
            function a() {
              var r = [];
              i.touch_app_bridge || r.push(e.debug.defineMode("1")),
                t.isObject(e.para.app_js_bridge) ||
                (r.push(e.debug.defineMode("2")), (i.verify_success = !1)),
                (t.isObject(e.para.heatmap) &&
                  "default" == e.para.heatmap.clickmap) ||
                r.push(e.debug.defineMode("3")),
                "fail" === i.verify_success && r.push(e.debug.defineMode("4"));
              var a = { callType: "app_alert", data: r };
              SensorsData_App_Visual_Bridge &&
                SensorsData_App_Visual_Bridge.sensorsdata_visualized_alert_info
                ? SensorsData_App_Visual_Bridge.sensorsdata_visualized_alert_info(
                  JSON.stringify(a)
                )
                : window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.sensorsdataNativeTracker &&
                window.webkit.messageHandlers.sensorsdataNativeTracker
                  .postMessage &&
                window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage(
                  JSON.stringify(a)
                );
            }
            if (
              t.isObject(window.SensorsData_App_Visual_Bridge) &&
              window.SensorsData_App_Visual_Bridge
                .sensorsdata_visualized_mode &&
              (!0 ===
                window.SensorsData_App_Visual_Bridge
                  .sensorsdata_visualized_mode ||
                window.SensorsData_App_Visual_Bridge.sensorsdata_visualized_mode())
            )
              if (
                t.isObject(e.para.heatmap) &&
                "default" == e.para.heatmap.clickmap
              )
                if (
                  t.isObject(e.para.app_js_bridge) &&
                  "success" === i.verify_success
                )
                  if (r) sa_jssdk_app_define_mode(e, r);
                  else {
                    var n = location.protocol;
                    (n = t.indexOf(["http:", "https:"], n) > -1 ? n : "https:"),
                      t.loadScript({
                        success: function () {
                          setTimeout(function () {
                            "undefined" != typeof sa_jssdk_app_define_mode &&
                              sa_jssdk_app_define_mode(e, r);
                          }, 0);
                        },
                        error: function () { },
                        type: "js",
                        url:
                          n +
                          "//static.sensorsdata.cn/sdk/" +
                          e.lib_version +
                          "/vapph5define.min.js",
                      });
                  }
                else a();
              else a();
          };
        e.para.heatmap &&
          e.para.heatmap.collect_tags &&
          t.isObject(e.para.heatmap.collect_tags) &&
          t.each(e.para.heatmap.collect_tags, function (t, r) {
            "div" !== r && t && e.heatmap.otherTags.push(r);
          }),
          r.isSeachHasKeyword()
            ? r.hasKeywordHandle()
            : window.parent !== self && i.isSearchHasKeyword()
              ? i.verifyVtrackMode()
              : r.isStorageHasKeyword()
                ? r.storageHasKeywordHandle()
                : window.parent !== self && i.isStorageHasKeyword()
                  ? i.verifyVtrackMode()
                  : (e.readyState.setState(3),
                    new e.JSBridge({
                      type: "visualized",
                      app_call_js: function () {
                        "undefined" != typeof sa_jssdk_app_define_mode
                          ? a(!0)
                          : a(!1);
                      },
                    }),
                    a(!1),
                    e.bridge.app_js_bridge_v1(),
                    t.info.initPage(),
                    e.para.is_track_single_page &&
                    t.addSinglePageEvent(function (r) {
                      var i = function (i) {
                        (i = i || {}),
                          r !== location.href &&
                          ((t.info.pageProp.referrer = r),
                            (r = t.isDecodeURI(e.para.url_is_decode, r)),
                            e.quick(
                              "autoTrack",
                              t.extend(
                                {
                                  $url: t.isDecodeURI(
                                    e.para.url_is_decode,
                                    location.href
                                  ),
                                  $referrer: r,
                                },
                                i
                              )
                            ));
                      };
                      if ("boolean" == typeof e.para.is_track_single_page) i();
                      else if ("function" == typeof e.para.is_track_single_page) {
                        var a = e.para.is_track_single_page();
                        t.isObject(a) ? i(a) : !0 === a && i();
                      }
                    }),
                    e.para.batch_send &&
                    (t.addEvent(
                      window,
                      "onpagehide" in window ? "pagehide" : "unload",
                      function (t) {
                        e.batchSend.clearPendingStatus();
                      }
                    ),
                      e.batchSend.batchInterval()),
                    e.store.init(),
                    e.vtrackcollect.init(),
                    e.readyState.setState(4),
                    e._q &&
                    t.isArray(e._q) &&
                    e._q.length > 0 &&
                    t.each(e._q, function (t) {
                      e[t[0]].apply(e, Array.prototype.slice.call(t[1]));
                    }),
                    t.isObject(e.para.heatmap) &&
                    (u.initHeatmap(), u.initScrollmap()),
                    i.notifyUser());
      }),
      (i.prototype = {
        add: function (e) {
          t.isObject(e) &&
            (this.writeStore(e),
              ("track_signup" !== e.type && "$pageview" !== e.event) ||
              this.sendStrategy());
        },
        clearPendingStatus: function () {
          this.sendingItemKeys.length &&
            this.removePendingItems(this.sendingItemKeys);
        },
        remove: function (e) {
          this.sendingData > 0 && --this.sendingData,
            t.isArray(e) &&
            e.length > 0 &&
            t.each(e, function (e) {
              t.localStorage.remove(e);
            });
        },
        send: function (r) {
          var i,
            a = this;
          (t.isString(e.para.server_url) && "" !== e.para.server_url) ||
            (t.isArray(e.para.server_url) && e.para.server_url.length)
            ? ((i = t.isArray(e.para.server_url)
              ? e.para.server_url[0]
              : e.para.server_url),
              t.ajax({
                url: i,
                type: "POST",
                data:
                  "data_list=" +
                  encodeURIComponent(t.base64Encode(JSON.stringify(r.vals))),
                credentials: !1,
                timeout: e.para.batch_send.datasend_timeout,
                cors: !0,
                success: function () {
                  a.remove(r.keys), a.removePendingItems(r.keys);
                },
                error: function () {
                  a.sendingData > 0 && --a.sendingData,
                    a.removePendingItems(r.keys);
                },
              }))
            : e.log(
              "\u5f53\u524d server_url \u4e3a\u7a7a\u6216\u4e0d\u6b63\u786e\uff0c\u53ea\u5728\u63a7\u5236\u53f0\u6253\u5370\u65e5\u5fd7\uff0cnetwork \u4e2d\u4e0d\u4f1a\u53d1\u6570\u636e\uff0c\u8bf7\u914d\u7f6e\u6b63\u786e\u7684 server_url\uff01"
            );
        },
        appendPendingItems: function (e) {
          if (!1 !== t.isArray(e)) {
            this.sendingItemKeys = t.unique(this.sendingItemKeys.concat(e));
            try {
              var r = this.getPendingItems(),
                i = t.unique(r.concat(e));
              localStorage.setItem(
                "sawebjssdk-sendingitems",
                JSON.stringify(i)
              );
            } catch (a) { }
          }
        },
        removePendingItems: function (e) {
          if (!1 !== t.isArray(e)) {
            this.sendingItemKeys.length &&
              (this.sendingItemKeys = t.filter(
                this.sendingItemKeys,
                function (r) {
                  return -1 === t.indexOf(e, r);
                }
              ));
            try {
              var r = this.getPendingItems(),
                i = t.filter(r, function (r) {
                  return -1 === t.indexOf(e, r);
                });
              localStorage.setItem(
                "sawebjssdk-sendingitems",
                JSON.stringify(i)
              );
            } catch (a) { }
          }
        },
        getPendingItems: function () {
          var e = [];
          try {
            var t = localStorage.getItem("sawebjssdk-sendingitems");
            t && (e = JSON.parse(t));
          } catch (r) { }
          return e;
        },
        sendPrepare: function (e) {
          this.appendPendingItems(e.keys);
          var t = e.vals;
          t.length > 0 && this.send({ keys: e.keys, vals: t });
        },
        sendStrategy: function () {
          if (!1 === document.hasFocus()) return !1;
          var e = this.readStore();
          e.keys.length > 0 &&
            0 === this.sendingData &&
            ((this.sendingData = 1), this.sendPrepare(e));
        },
        batchInterval: function () {
          var t = this;
          setInterval(function () {
            t.sendStrategy();
          }, e.para.batch_send.send_interval);
        },
        readStore: function () {
          for (
            var r = [],
            i = [],
            a = null,
            n = new Date().getTime(),
            s = localStorage.length,
            o = this.getPendingItems(),
            c = 0;
            c < s;
            c++
          ) {
            var l = localStorage.key(c);
            if (0 === l.indexOf("sawebjssdk-") && /^sawebjssdk\-\d+$/.test(l)) {
              if (o.length && t.indexOf(o, l) > -1) continue;
              (a = localStorage.getItem(l))
                ? (a = t.safeJSONParse(a)) && t.isObject(a)
                  ? ((a._flush_time = n), r.push(l), i.push(a))
                  : (localStorage.removeItem(l),
                    e.log("localStorage-\u6570\u636eparse\u5f02\u5e38" + a))
                : (localStorage.removeItem(l),
                  e.log(
                    "localStorage-\u6570\u636e\u53d6\u503c\u5f02\u5e38" + a
                  ));
            }
          }
          return { keys: r, vals: i };
        },
        writeStore: function (e) {
          var r =
            String(t.getRandom()).slice(2, 5) +
            String(t.getRandom()).slice(2, 5) +
            String(new Date().getTime()).slice(3);
          localStorage.setItem("sawebjssdk-" + r, JSON.stringify(e));
        },
      }),
      (e.batchSend = new i());
    var a = {
      getSendUrl: function (e, r) {
        var i = t.base64Encode(r),
          a = "crc=" + t.hashCode(i);
        return -1 !== e.indexOf("?")
          ? e +
          "&data=" +
          encodeURIComponent(i) +
          "&ext=" +
          encodeURIComponent(a)
          : e +
          "?data=" +
          encodeURIComponent(i) +
          "&ext=" +
          encodeURIComponent(a);
      },
      getSendData: function (e) {
        var r = t.base64Encode(e),
          i = "crc=" + t.hashCode(r);
        return (
          "data=" + encodeURIComponent(r) + "&ext=" + encodeURIComponent(i)
        );
      },
      getInstance: function (r) {
        var i = new this[this.getSendType(r)](r),
          a = i.start;
        return (
          (i.start = function () {
            t.isObject(e.para.is_debug) &&
              e.para.is_debug.storage &&
              e.store.requests &&
              e.store.requests.push({
                name: this.server_url,
                initiatorType: this.img ? "img" : "xmlhttprequest",
                entryType: "resource",
                requestData: this.data,
              });
            var r = this;
            a.apply(this, arguments),
              setTimeout(function () {
                r.isEnd(!0);
              }, e.para.callback_timeout);
          }),
          (i.end = function () {
            this.callback && this.callback();
            var t = this;
            setTimeout(function () {
              t.lastClear && t.lastClear();
            }, e.para.datasend_timeout - e.para.callback_timeout);
          }),
          (i.isEnd = function (t) {
            if (!this.received) {
              (this.received = !0), this.end();
              var r = this;
              t
                ? e.para.queue_timeout - e.para.callback_timeout <= 0
                  ? r.close()
                  : setTimeout(function () {
                    r.close();
                  }, e.para.queue_timeout - e.para.callback_timeout)
                : r.close();
            }
          }),
          i
        );
      },
      getRealtimeInstance: function (t) {
        var r = new this[this.getSendType(t)](t),
          i = r.start;
        return (
          (r.start = function () {
            var t = this;
            i.apply(this, arguments),
              setTimeout(function () {
                t.isEnd(!0);
              }, e.para.callback_timeout);
          }),
          (r.end = function () {
            this.callback && this.callback();
            var t = this;
            setTimeout(function () {
              t.lastClear && t.lastClear();
            }, e.para.datasend_timeout - e.para.callback_timeout);
          }),
          (r.isEnd = function (e) {
            this.received || ((this.received = !0), this.end());
          }),
          r
        );
      },
      getSendType: function (r) {
        var i = ["image", "ajax", "beacon"],
          a = i[0];
        return (
          "beacon" ===
          (a =
            r.config && t.indexOf(i, r.config.send_type) > -1
              ? r.config.send_type
              : e.para.send_type) &&
          !1 === t.isSupportBeaconSend() &&
          (a = "image"),
          "ajax" === a && !1 === t.isSupportCors() && (a = "image"),
          a
        );
      },
      image: function (t) {
        (this.callback = t.callback),
          (this.img = document.createElement("img")),
          (this.img.width = 1),
          (this.img.height = 1),
          e.para.img_use_crossorigin && (this.img.crossOrigin = "anonymous"),
          (this.data = t.data),
          (this.server_url = a.getSendUrl(t.server_url, t.data));
      },
    };
    (a.image.prototype.start = function () {
      var t = this;
      e.para.ignore_oom &&
        ((this.img.onload = function () {
          (this.onload = null),
            (this.onerror = null),
            (this.onabort = null),
            t.isEnd();
        }),
          (this.img.onerror = function () {
            (this.onload = null),
              (this.onerror = null),
              (this.onabort = null),
              t.isEnd();
          }),
          (this.img.onabort = function () {
            (this.onload = null),
              (this.onerror = null),
              (this.onabort = null),
              t.isEnd();
          })),
        (this.img.src = this.server_url);
    }),
      (a.image.prototype.lastClear = function () {
        this.img.src = "";
      }),
      (a.ajax = function (e) {
        (this.callback = e.callback),
          (this.server_url = e.server_url),
          (this.data = a.getSendData(e.data));
      }),
      (a.ajax.prototype.start = function () {
        var r = this;
        t.ajax({
          url: this.server_url,
          type: "POST",
          data: this.data,
          credentials: !1,
          timeout: e.para.datasend_timeout,
          cors: !0,
          success: function () {
            r.isEnd();
          },
          error: function () {
            r.isEnd();
          },
        });
      }),
      (a.beacon = function (e) {
        (this.callback = e.callback),
          (this.server_url = e.server_url),
          (this.data = a.getSendData(e.data));
      }),
      (a.beacon.prototype.start = function () {
        var e = this;
        "object" == typeof navigator &&
          "function" == typeof navigator.sendBeacon &&
          navigator.sendBeacon(this.server_url, this.data),
          setTimeout(function () {
            e.isEnd();
          }, 40);
      });
    var n = {};
    (e.sendState = n),
      (e.events = new t.eventEmitter()),
      (n.queue = t.autoExeQueue()),
      (n.getSendCall = function (r, i, a) {
        if (e.is_heatmap_render_mode) return !1;
        if (e.readyState.state < 3)
          return e.log("\u521d\u59cb\u5316\u6ca1\u6709\u5b8c\u6210"), !1;
        (r._track_id = Number(
          String(t.getRandom()).slice(2, 5) +
          String(t.getRandom()).slice(2, 4) +
          String(new Date().getTime()).slice(-4)
        )),
          e.para.use_client_time && (r._flush_time = new Date().getTime());
        var n = r;
        r = JSON.stringify(r);
        var s = { data: n, config: i, callback: a };
        if (
          (e.events.tempAdd("send", n),
            !e.para.app_js_bridge &&
            e.para.batch_send &&
            localStorage.length < 200)
        )
          return e.log(n), e.batchSend.add(s.data), !1;
        e.bridge.dataSend(s, this, a), e.log(n);
      }),
      (n.prepareServerUrl = function (r) {
        if ("object" == typeof r.config && r.config.server_url)
          this.sendCall(r, r.config.server_url, r.callback);
        else if (t.isArray(e.para.server_url) && e.para.server_url.length)
          for (var i = 0; i < e.para.server_url.length; i++)
            this.sendCall(r, e.para.server_url[i]);
        else
          "string" == typeof e.para.server_url && "" !== e.para.server_url
            ? this.sendCall(r, e.para.server_url, r.callback)
            : e.log(
              "\u5f53\u524d server_url \u4e3a\u7a7a\u6216\u4e0d\u6b63\u786e\uff0c\u53ea\u5728\u63a7\u5236\u53f0\u6253\u5370\u65e5\u5fd7\uff0cnetwork \u4e2d\u4e0d\u4f1a\u53d1\u6570\u636e\uff0c\u8bf7\u914d\u7f6e\u6b63\u786e\u7684 server_url\uff01"
            );
      }),
      (n.sendCall = function (r, i, a) {
        var n = {
          server_url: i,
          data: JSON.stringify(r.data),
          callback: a,
          config: r.config,
        };
        t.isObject(e.para.jsapp) &&
          !e.para.jsapp.isOnline &&
          "function" == typeof e.para.jsapp.setData
          ? (delete n.callback,
            (n = JSON.stringify(n)),
            e.para.jsapp.setData(n))
          : e.para.use_client_time
            ? this.realtimeSend(n)
            : this.pushSend(n);
      }),
      (n.pushSend = function (e) {
        var t = a.getInstance(e),
          r = this;
        (t.close = function () {
          r.queue.close();
        }),
          this.queue.enqueue(t);
      }),
      (n.realtimeSend = function (e) {
        a.getRealtimeInstance(e).start();
      });
    var s = {};
    (e.saEvent = s),
      (s.checkOption = {
        regChecks: {
          regName:
            /^((?!^distinct_id$|^original_id$|^time$|^properties$|^id$|^first_id$|^second_id$|^users$|^events$|^event$|^user_id$|^date$|^datetime$)[a-zA-Z_$][a-zA-Z\d_$]{0,99})$/i,
        },
        checkPropertiesKey: function (e) {
          var r = this,
            i = !0;
          return (
            t.each(e, function (e, t) {
              r.regChecks.regName.test(t) || (i = !1);
            }),
            i
          );
        },
        check: function (e, r) {
          return "string" == typeof this[e]
            ? this[this[e]](r)
            : t.isFunction(this[e])
              ? this[e](r)
              : void 0;
        },
        str: function (r) {
          return (
            !!t.isString(r) ||
            (e.log(
              "\u8bf7\u68c0\u67e5\u53c2\u6570\u683c\u5f0f,\u5fc5\u987b\u662f\u5b57\u7b26\u4e32"
            ),
              !0)
          );
        },
        properties: function (r) {
          return (
            t.strip_sa_properties(r),
            !r ||
            (t.isObject(r)
              ? !!this.checkPropertiesKey(r) ||
              (e.log(
                "properties \u91cc\u7684\u81ea\u5b9a\u4e49\u5c5e\u6027\u540d\u9700\u8981\u662f\u5408\u6cd5\u7684\u53d8\u91cf\u540d\uff0c\u4e0d\u80fd\u4ee5\u6570\u5b57\u5f00\u5934\uff0c\u4e14\u53ea\u5305\u542b\uff1a\u5927\u5c0f\u5199\u5b57\u6bcd\u3001\u6570\u5b57\u3001\u4e0b\u5212\u7ebf\uff0c\u81ea\u5b9a\u4e49\u5c5e\u6027\u4e0d\u80fd\u4ee5 $ \u5f00\u5934"
              ),
                !0)
              : (e.log(
                "properties\u53ef\u4ee5\u6ca1\u6709\uff0c\u4f46\u6709\u7684\u8bdd\u5fc5\u987b\u662f\u5bf9\u8c61"
              ),
                !0))
          );
        },
        propertiesMust: function (r) {
          return (
            t.strip_sa_properties(r),
            r === undefined || !t.isObject(r) || t.isEmptyObject(r)
              ? (e.log(
                "properties\u5fc5\u987b\u662f\u5bf9\u8c61\u4e14\u6709\u503c"
              ),
                !0)
              : !!this.checkPropertiesKey(r) ||
              (e.log(
                "properties \u91cc\u7684\u81ea\u5b9a\u4e49\u5c5e\u6027\u540d\u9700\u8981\u662f\u5408\u6cd5\u7684\u53d8\u91cf\u540d\uff0c\u4e0d\u80fd\u4ee5\u6570\u5b57\u5f00\u5934\uff0c\u4e14\u53ea\u5305\u542b\uff1a\u5927\u5c0f\u5199\u5b57\u6bcd\u3001\u6570\u5b57\u3001\u4e0b\u5212\u7ebf\uff0c\u81ea\u5b9a\u4e49\u5c5e\u6027\u4e0d\u80fd\u4ee5 $ \u5f00\u5934"
              ),
                !0)
          );
        },
        event: function (r) {
          return (
            !(!t.isString(r) || !this.regChecks.regName.test(r)) ||
            (e.log(
              "\u8bf7\u68c0\u67e5\u53c2\u6570\u683c\u5f0f\uff0ceventName \u5fc5\u987b\u662f\u5b57\u7b26\u4e32\uff0c\u4e14\u9700\u662f\u5408\u6cd5\u7684\u53d8\u91cf\u540d\uff0c\u5373\u4e0d\u80fd\u4ee5\u6570\u5b57\u5f00\u5934\uff0c\u4e14\u53ea\u5305\u542b\uff1a\u5927\u5c0f\u5199\u5b57\u6bcd\u3001\u6570\u5b57\u3001\u4e0b\u5212\u7ebf\u548c $,\u5176\u4e2d\u4ee5 $ \u5f00\u5934\u7684\u8868\u660e\u662f\u7cfb\u7edf\u7684\u4fdd\u7559\u5b57\u6bb5\uff0c\u81ea\u5b9a\u4e49\u4e8b\u4ef6\u540d\u8bf7\u4e0d\u8981\u4ee5 $ \u5f00\u5934"
            ),
              !0)
          );
        },
        test_id: "str",
        group_id: "str",
        distinct_id: function (r) {
          return (
            !(!t.isString(r) || !/^.{1,255}$/.test(r)) ||
            (e.log(
              "distinct_id\u5fc5\u987b\u662f\u4e0d\u80fd\u4e3a\u7a7a\uff0c\u4e14\u5c0f\u4e8e255\u4f4d\u7684\u5b57\u7b26\u4e32"
            ),
              !1)
          );
        },
      }),
      (s.check = function (e) {
        for (var t in e)
          if (
            Object.prototype.hasOwnProperty.call(e, t) &&
            !this.checkOption.check(t, e[t])
          )
            return !1;
        return !0;
      }),
      (s.send = function (r, i) {
        var a = {
          distinct_id: c.getDistinctId(),
          lib: {
            $lib: "js",
            $lib_method: "code",
            $lib_version: String(e.lib_version),
          },
          properties: {},
        };
        t.isObject(r) &&
          t.isObject(r.properties) &&
          !t.isEmptyObject(r.properties) &&
          r.properties.$lib_detail &&
          ((a.lib.$lib_detail = r.properties.$lib_detail),
            delete r.properties.$lib_detail),
          t.extend(a, e.store.getUnionId(), r),
          t.isObject(r.properties) &&
          !t.isEmptyObject(r.properties) &&
          t.extend(a.properties, r.properties),
          (r.type && "profile" === r.type.slice(0, 7)) ||
          ((a.properties = t.extend(
            {},
            t.info.properties(),
            c.getProps(),
            c.getSessionProps(),
            t.info.currentProps,
            a.properties
          )),
            e.para.preset_properties.latest_referrer &&
            !t.isString(a.properties.$latest_referrer) &&
            (a.properties.$latest_referrer = "\u53d6\u503c\u5f02\u5e38"),
            e.para.preset_properties.latest_search_keyword &&
            !t.isString(a.properties.$latest_search_keyword) &&
            (a.properties.$latest_search_keyword =
              "\u53d6\u503c\u5f02\u5e38"),
            e.para.preset_properties.latest_traffic_source_type &&
            !t.isString(a.properties.$latest_traffic_source_type) &&
            (a.properties.$latest_traffic_source_type =
              "\u53d6\u503c\u5f02\u5e38"),
            e.para.preset_properties.latest_landing_page &&
            !t.isString(a.properties.$latest_landing_page) &&
            (a.properties.$latest_landing_page = "\u53d6\u503c\u5f02\u5e38"),
            "not_collect" === e.para.preset_properties.latest_wx_ad_click_id
              ? (delete a.properties._latest_wx_ad_click_id,
                delete a.properties._latest_wx_ad_hash_key,
                delete a.properties._latest_wx_ad_callbacks)
              : e.para.preset_properties.latest_wx_ad_click_id &&
              !t.isString(a.properties._latest_wx_ad_click_id) &&
              ((a.properties._latest_wx_ad_click_id =
                "\u53d6\u503c\u5f02\u5e38"),
                (a.properties._latest_wx_ad_hash_key =
                  "\u53d6\u503c\u5f02\u5e38"),
                (a.properties._latest_wx_ad_callbacks =
                  "\u53d6\u503c\u5f02\u5e38")),
            t.isString(a.properties._latest_wx_ad_click_id) &&
            (a.properties.$url = t.isDecodeURI(
              e.para.url_is_decode,
              window.location.href
            ))),
          a.properties.$time && t.isDate(a.properties.$time)
            ? ((a.time = 1 * a.properties.$time), delete a.properties.$time)
            : e.para.use_client_time && (a.time = 1 * new Date());
        var n = e.vtrackcollect.customProp.getVtrackProps(
          JSON.parse(JSON.stringify(a))
        );
        t.isObject(n) &&
          !t.isEmptyObject(n) &&
          (a.properties = t.extend(a.properties, n)),
          t.parseSuperProperties(a),
          t.filterReservedProperties(a.properties),
          t.searchObjDate(a),
          t.searchObjString(a),
          t.searchZZAppStyle(a);
        var s = t.searchConfigData(a.properties);
        l.checkIsAddSign(a),
          l.checkIsFirstTime(a),
          e.addReferrerHost(a),
          e.addPropsHook(a),
          !0 === e.para.debug_mode
            ? (e.log(a), this.debugPath(JSON.stringify(a), i))
            : e.sendState.getSendCall(a, s, i);
      }),
      (s.debugPath = function (r, i) {
        var a = r,
          n = "";
        (n =
          -1 !== e.para.debug_mode_url.indexOf("?")
            ? e.para.debug_mode_url +
            "&data=" +
            encodeURIComponent(t.base64Encode(r))
            : e.para.debug_mode_url +
            "?data=" +
            encodeURIComponent(t.base64Encode(r))),
          t.ajax({
            url: n,
            type: "GET",
            cors: !0,
            header: { "Dry-Run": String(e.para.debug_mode_upload) },
            success: function (e) {
              !0 === t.isEmptyObject(e)
                ? alert("debug\u6570\u636e\u53d1\u9001\u6210\u529f" + a)
                : alert(
                  "debug\u5931\u8d25 \u9519\u8bef\u539f\u56e0" +
                  JSON.stringify(e)
                );
            },
          });
      });
    var c = (e.store = {
      requests: [],
      _sessionState: {},
      _state: { distinct_id: "", first_id: "", props: {} },
      getProps: function () {
        return this._state.props || {};
      },
      getSessionProps: function () {
        return this._sessionState;
      },
      getDistinctId: function () {
        return this._state._distinct_id || this._state.distinct_id;
      },
      getUnionId: function () {
        var e = {},
          t = this._state._first_id || this._state.first_id,
          r = this._state._distinct_id || this._state.distinct_id;
        return (
          t && r
            ? ((e.login_id = r), (e.anonymous_id = t))
            : (e.anonymous_id = r),
          e
        );
      },
      getFirstId: function () {
        return this._state._first_id || this._state.first_id;
      },
      toState: function (r) {
        var i = null;
        if (null != r && t.isJSONString(r))
          if (
            ((i = JSON.parse(r)), (this._state = t.extend(i)), i.distinct_id)
          ) {
            if ("object" == typeof i.props) {
              for (var a in i.props)
                "string" == typeof i.props[a] &&
                  (i.props[a] = i.props[a].slice(
                    0,
                    e.para.max_referrer_string_length
                  ));
              this.save();
            }
          } else
            this.set("distinct_id", t.UUID()), e.debug.distinct_id("1", r);
        else this.set("distinct_id", t.UUID()), e.debug.distinct_id("2", r);
      },
      initSessionState: function () {
        var e = t.cookie.get("sensorsdata2015session"),
          r = null;
        null !== e &&
          "object" == typeof (r = JSON.parse(e)) &&
          (this._sessionState = r || {});
      },
      setOnce: function (e, t) {
        e in this._state || this.set(e, t);
      },
      set: function (t, r) {
        this._state = this._state || {};
        var i = this._state.distinct_id;
        (this._state[t] = r),
          "first_id" === t
            ? delete this._state._first_id
            : "distinct_id" === t && delete this._state._distinct_id,
          this.save(),
          "distinct_id" === t && i && e.events.tempAdd("changeDistinctId", r);
      },
      change: function (e, t) {
        this._state["_" + e] = t;
      },
      setSessionProps: function (e) {
        var r = this._sessionState;
        t.extend(r, e), this.sessionSave(r);
      },
      setSessionPropsOnce: function (e) {
        var r = this._sessionState;
        t.coverExtend(r, e), this.sessionSave(r);
      },
      setProps: function (r, i) {
        var a = {};
        for (var n in (a = i ? r : t.extend(this._state.props || {}, r)))
          "string" == typeof a[n] &&
            (a[n] = a[n].slice(0, e.para.max_referrer_string_length));
        this.set("props", a);
      },
      setPropsOnce: function (e) {
        var r = this._state.props || {};
        t.coverExtend(r, e), this.set("props", r);
      },
      clearAllProps: function (e) {
        if (((this._sessionState = {}), t.isArray(e) && e.length > 0))
          for (var r = 0; r < e.length; r++)
            t.isString(e[r]) &&
              -1 === e[r].indexOf("latest_") &&
              e[r] in this._state.props &&
              delete this._state.props[e[r]];
        else
          for (var r in this._state.props)
            1 !== r.indexOf("latest_") && delete this._state.props[r];
        this.sessionSave({}), this.save();
      },
      sessionSave: function (e) {
        (this._sessionState = e),
          t.cookie.set(
            "sensorsdata2015session",
            JSON.stringify(this._sessionState),
            0
          );
      },
      save: function () {
        var r = JSON.parse(JSON.stringify(this._state));
        delete r._first_id, delete r._distinct_id;
        var i = JSON.stringify(r);
        e.para.encrypt_cookie && (i = t.cookie.encrypt(i)),
          t.cookie.set(this.getCookieName(), i, 73e3, e.para.cross_subdomain);
      },
      getCookieName: function () {
        var r = "";
        if (!1 === e.para.cross_subdomain) {
          try {
            r = t.URL(location.href).hostname;
          } catch (i) {
            e.log(i);
          }
          r =
            "string" == typeof r && "" !== r
              ? "sa_jssdk_2015_" + r.replace(/\./g, "_")
              : "sa_jssdk_2015_root";
        } else r = "sensorsdata2015jssdkcross";
        return r;
      },
      init: function () {
        this.initSessionState();
        var r = t.UUID(),
          i = t.cookie.get(this.getCookieName());
        null === (i = t.cookie.resolveValue(i))
          ? ((e.is_first_visitor = !0), this.set("distinct_id", r))
          : ((t.isJSONString(i) && JSON.parse(i).distinct_id) ||
            (e.is_first_visitor = !0),
            this.toState(i)),
          l.setDeviceId(r),
          l.storeInitCheck(),
          l.checkIsFirstLatest();
      },
    }),
      l = {
        checkIsAddSign: function (e) {
          "track" === e.type &&
            (t.cookie.getNewUser()
              ? (e.properties.$is_first_day = !0)
              : (e.properties.$is_first_day = !1));
        },
        is_first_visit_time: !1,
        checkIsFirstTime: function (e) {
          "track" === e.type &&
            "$pageview" === e.event &&
            (this.is_first_visit_time
              ? ((e.properties.$is_first_time = !0),
                (this.is_first_visit_time = !1))
              : (e.properties.$is_first_time = !1));
        },
        setDeviceId: function (r) {
          var i = null,
            a = t.cookie.get("sensorsdata2015jssdkcross"),
            n = {};
          null != (a = t.cookie.resolveValue(a)) &&
            t.isJSONString(a) &&
            (n = JSON.parse(a)).$device_id &&
            (i = n.$device_id),
            (i = i || r),
            !0 === e.para.cross_subdomain
              ? c.set("$device_id", i)
              : ((n.$device_id = i),
                (n = JSON.stringify(n)),
                e.para.encrypt_cookie && (n = t.cookie.encrypt(n)),
                t.cookie.set("sensorsdata2015jssdkcross", n, null, !0)),
            e.para.is_track_device_id && (t.info.currentProps.$device_id = i);
        },
        storeInitCheck: function () {
          if (e.is_first_visitor) {
            var r = new Date(),
              i = {
                h: 23 - r.getHours(),
                m: 59 - r.getMinutes(),
                s: 59 - r.getSeconds(),
              };
            t.cookie.set(
              t.cookie.getCookieName("new_user"),
              "1",
              3600 * i.h + 60 * i.m + i.s + "s"
            ),
              (this.is_first_visit_time = !0);
          } else
            t.cookie.getNewUser() ||
              (this.checkIsAddSign = function (e) {
                "track" === e.type && (e.properties.$is_first_day = !1);
              }),
              (this.checkIsFirstTime = function (e) {
                "track" === e.type &&
                  "$pageview" === e.event &&
                  (e.properties.$is_first_time = !1);
              });
        },
        checkIsFirstLatest: function () {
          for (
            var r = t.info.pageProp.url_domain,
            i = [
              "$utm_source",
              "$utm_medium",
              "$utm_campaign",
              "$utm_content",
              "$utm_term",
            ],
            a = c.getProps(),
            n = 0;
            n < i.length;
            n++
          )
            i[n] in a && delete a[i[n]];
          c.setProps(a, !0);
          var s = {};
          if (
            ("" === r && (r = "url\u89e3\u6790\u5931\u8d25"),
              t.each(e.para.preset_properties, function (i, a) {
                if (-1 === a.indexOf("latest_")) return !1;
                if (((a = a.slice(7)), i)) {
                  if ("wx_ad_click_id" === a && "not_collect" === i) return !1;
                  if ("utm" !== a && "url\u89e3\u6790\u5931\u8d25" === r)
                    "wx_ad_click_id" === a
                      ? ((s._latest_wx_ad_click_id =
                        "url\u7684domain\u89e3\u6790\u5931\u8d25"),
                        (s._latest_wx_ad_hash_key =
                          "url\u7684domain\u89e3\u6790\u5931\u8d25"),
                        (s._latest_wx_ad_callbacks =
                          "url\u7684domain\u89e3\u6790\u5931\u8d25"))
                      : (s["$latest_" + a] =
                        "url\u7684domain\u89e3\u6790\u5931\u8d25");
                  else if (t.isReferralTraffic(document.referrer))
                    switch (a) {
                      case "traffic_source_type":
                        s.$latest_traffic_source_type = t.getSourceFromReferrer();
                        break;
                      case "referrer":
                        s.$latest_referrer = t.isDecodeURI(
                          e.para.url_is_decode,
                          t.info.pageProp.referrer
                        );
                        break;
                      case "search_keyword":
                        s.$latest_search_keyword = t.getKeywordFromReferrer();
                        break;
                      case "landing_page":
                        s.$latest_landing_page = t.isDecodeURI(
                          e.para.url_is_decode,
                          location.href
                        );
                        break;
                      case "wx_ad_click_id":
                        var n = t.getWxAdIdFromUrl(location.href);
                        (s._latest_wx_ad_click_id = n.click_id),
                          (s._latest_wx_ad_hash_key = n.hash_key),
                          (s._latest_wx_ad_callbacks = n.callbacks);
                    }
                } else if ("utm" === a && e.store._state.props)
                  for (var o in e.store._state.props)
                    (0 === o.indexOf("$latest_utm") ||
                      (0 === o.indexOf("_latest_") &&
                        o.indexOf("_latest_wx_ad_") < 0)) &&
                      delete e.store._state.props[o];
                else if (
                  e.store._state.props &&
                  "$latest_" + a in e.store._state.props
                )
                  delete e.store._state.props["$latest_" + a];
                else if (
                  "wx_ad_click_id" == a &&
                  e.store._state.props &&
                  !1 === i
                ) {
                  t.each(
                    [
                      "_latest_wx_ad_click_id",
                      "_latest_wx_ad_hash_key",
                      "_latest_wx_ad_callbacks",
                    ],
                    function (t) {
                      t in e.store._state.props && delete e.store._state.props[t];
                    }
                  );
                }
              }),
              e.register(s),
              e.para.preset_properties.latest_utm)
          ) {
            var o = t.info.campaignParamsStandard("$latest_", "_latest_"),
              l = o.$utms,
              u = o.otherUtms;
            t.isEmptyObject(l) || e.register(l),
              t.isEmptyObject(u) || e.register(u);
          }
        },
      };
    (e.bridge = {
      is_verify_success: !1,
      initPara: function () {
        var r = { is_send: !0, white_list: [], is_mui: !1 };
        "object" == typeof e.para.app_js_bridge
          ? (e.para.app_js_bridge = t.extend({}, r, e.para.app_js_bridge))
          : !0 === e.para.use_app_track ||
            !0 === e.para.app_js_bridge ||
            "only" === e.para.use_app_track
            ? ((!1 !== e.para.use_app_track_is_send &&
              "only" !== e.para.use_app_track) ||
              (r.is_send = !1),
              (e.para.app_js_bridge = t.extend({}, r)))
            : "mui" === e.para.use_app_track &&
            ((r.is_mui = !0), (e.para.app_js_bridge = t.extend({}, r))),
          !1 === e.para.app_js_bridge.is_send &&
          e.log(
            "\u8bbe\u7f6e\u4e86 is_send:false,\u5982\u679c\u6253\u901a\u5931\u8d25\uff0c\u6570\u636e\u5c06\u88ab\u4e22\u5f03\uff01"
          );
      },
      initState: function () {
        function r(r) {
          function i(r) {
            var i = { hostname: "", project: "" };
            try {
              (i.hostname = t.URL(r).hostname),
                (i.project = t.URL(r).searchParams.get("project") || "default");
            } catch (a) {
              e.log(a);
            }
            return i;
          }
          var a = i(r),
            n = i(e.para.server_url);
          if (a.hostname === n.hostname && a.project === n.project) return !0;
          if (e.para.app_js_bridge.white_list.length > 0)
            for (var s = 0; s < e.para.app_js_bridge.white_list.length; s++) {
              var o = i(e.para.app_js_bridge.white_list[s]);
              if (o.hostname === a.hostname && o.project === a.project)
                return !0;
            }
          return !1;
        }
        if (t.isObject(e.para.app_js_bridge) && !e.para.app_js_bridge.is_mui)
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.sensorsdataNativeTracker &&
            t.isObject(window.SensorsData_iOS_JS_Bridge) &&
            window.SensorsData_iOS_JS_Bridge.sensorsdata_app_server_url
          )
            r(window.SensorsData_iOS_JS_Bridge.sensorsdata_app_server_url) &&
              (e.bridge.is_verify_success = !0);
          else if (
            t.isObject(window.SensorsData_APP_New_H5_Bridge) &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_get_server_url &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_track
          ) {
            var i =
              window.SensorsData_APP_New_H5_Bridge.sensorsdata_get_server_url();
            i && r(i) && (e.bridge.is_verify_success = !0);
          }
      },
      initDefineBridgeInfo: function () {
        var r = { touch_app_bridge: !0, verify_success: !1 };
        return (
          window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.sensorsdataNativeTracker &&
            window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage &&
            t.isObject(window.SensorsData_iOS_JS_Bridge) &&
            window.SensorsData_iOS_JS_Bridge.sensorsdata_app_server_url
            ? e.bridge.is_verify_success
              ? (r.verify_success = "success")
              : (r.verify_success = "fail")
            : t.isObject(window.SensorsData_APP_New_H5_Bridge) &&
              window.SensorsData_APP_New_H5_Bridge.sensorsdata_get_server_url &&
              window.SensorsData_APP_New_H5_Bridge.sensorsdata_track
              ? e.bridge.is_verify_success
                ? (r.verify_success = "success")
                : (r.verify_success = "fail")
              : "object" == typeof SensorsData_APP_JS_Bridge &&
                ((SensorsData_APP_JS_Bridge.sensorsdata_verify &&
                  SensorsData_APP_JS_Bridge.sensorsdata_visual_verify) ||
                  SensorsData_APP_JS_Bridge.sensorsdata_track)
                ? SensorsData_APP_JS_Bridge.sensorsdata_verify &&
                  SensorsData_APP_JS_Bridge.sensorsdata_visual_verify
                  ? SensorsData_APP_JS_Bridge.sensorsdata_visual_verify(
                    JSON.stringify({ server_url: e.para.server_url })
                  )
                    ? (r.verify_success = "success")
                    : (r.verify_success = "fail")
                  : (r.verify_success = "success")
                : (!/sensors-verify/.test(navigator.userAgent) &&
                  !/sa-sdk-ios/.test(navigator.userAgent)) ||
                  window.MSStream
                  ? (r.touch_app_bridge = !1)
                  : e.bridge.iOS_UA_bridge()
                    ? (r.verify_success = "success")
                    : (r.verify_success = "fail"),
          r
        );
      },
      iOS_UA_bridge: function () {
        if (/sensors-verify/.test(navigator.userAgent)) {
          var r = navigator.userAgent.match(/sensors-verify\/([^\s]+)/);
          if (
            r &&
            r[0] &&
            "string" == typeof r[1] &&
            2 === r[1].split("?").length
          ) {
            r = r[1].split("?");
            var i = null,
              a = null;
            try {
              (i = t.URL(e.para.server_url).hostname),
                (a =
                  t.URL(e.para.server_url).searchParams.get("project") ||
                  "default");
            } catch (n) {
              e.log(n);
            }
            return !(!i || i !== r[0] || !a || a !== r[1]);
          }
          return !1;
        }
        return !!/sa-sdk-ios/.test(navigator.userAgent);
      },
      dataSend: function (r, i, a) {
        var n = r.data;
        if (t.isObject(e.para.app_js_bridge) && !e.para.app_js_bridge.is_mui)
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.sensorsdataNativeTracker &&
            window.webkit.messageHandlers.sensorsdataNativeTracker
              .postMessage &&
            t.isObject(window.SensorsData_iOS_JS_Bridge) &&
            window.SensorsData_iOS_JS_Bridge.sensorsdata_app_server_url
          )
            e.bridge.is_verify_success
              ? (window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage(
                JSON.stringify({
                  callType: "app_h5_track",
                  data: t.extend({ server_url: e.para.server_url }, n),
                })
              ),
                "function" == typeof a && a())
              : e.para.app_js_bridge.is_send
                ? (e.debug.apph5({ data: n, step: "4.1", output: "all" }),
                  i.prepareServerUrl(r))
                : "function" == typeof a && a();
          else if (
            t.isObject(window.SensorsData_APP_New_H5_Bridge) &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_get_server_url &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_track
          )
            e.bridge.is_verify_success
              ? (SensorsData_APP_New_H5_Bridge.sensorsdata_track(
                JSON.stringify(t.extend({ server_url: e.para.server_url }, n))
              ),
                "function" == typeof a && a())
              : e.para.app_js_bridge.is_send
                ? (e.debug.apph5({ data: n, step: "4.2", output: "all" }),
                  i.prepareServerUrl(r))
                : "function" == typeof a && a();
          else if (
            "object" == typeof SensorsData_APP_JS_Bridge &&
            (SensorsData_APP_JS_Bridge.sensorsdata_verify ||
              SensorsData_APP_JS_Bridge.sensorsdata_track)
          )
            SensorsData_APP_JS_Bridge.sensorsdata_verify
              ? SensorsData_APP_JS_Bridge.sensorsdata_verify(
                JSON.stringify(t.extend({ server_url: e.para.server_url }, n))
              )
                ? "function" == typeof a && a()
                : e.para.app_js_bridge.is_send
                  ? (e.debug.apph5({ data: n, step: "3.1", output: "all" }),
                    i.prepareServerUrl(r))
                  : "function" == typeof a && a()
              : (SensorsData_APP_JS_Bridge.sensorsdata_track(
                JSON.stringify(t.extend({ server_url: e.para.server_url }, n))
              ),
                "function" == typeof a && a());
          else if (
            (!/sensors-verify/.test(navigator.userAgent) &&
              !/sa-sdk-ios/.test(navigator.userAgent)) ||
            window.MSStream
          )
            t.isObject(e.para.app_js_bridge) &&
              !0 === e.para.app_js_bridge.is_send
              ? (e.debug.apph5({ data: n, step: "2", output: "all" }),
                i.prepareServerUrl(r))
              : "function" == typeof a && a();
          else {
            var s = null;
            if (e.bridge.iOS_UA_bridge()) {
              s = document.createElement("iframe");
              var o = (function (r) {
                var i = JSON.stringify(
                  t.extend({ server_url: e.para.server_url }, r)
                );
                return (
                  (i = i.replaceAll(/\r\n/g, "")),
                  "sensorsanalytics://trackEvent?event=" +
                  (i = encodeURIComponent(i))
                );
              })(n);
              s.setAttribute("src", o),
                document.documentElement.appendChild(s),
                s.parentNode.removeChild(s),
                (s = null),
                "function" == typeof a && a();
            } else
              e.para.app_js_bridge.is_send
                ? (e.debug.apph5({ data: n, step: "3.2", output: "all" }),
                  i.prepareServerUrl(r))
                : "function" == typeof a && a();
          }
        else
          t.isObject(e.para.app_js_bridge) && e.para.app_js_bridge.is_mui
            ? t.isObject(window.plus) &&
              window.plus.SDAnalytics &&
              window.plus.SDAnalytics.trackH5Event
              ? (window.plus.SDAnalytics.trackH5Event(data),
                "function" == typeof a && a())
              : t.isObject(e.para.app_js_bridge) &&
                !0 === e.para.app_js_bridge.is_send
                ? i.prepareServerUrl(r)
                : "function" == typeof a && a()
            : (e.debug.apph5({ data: n, step: "1", output: "code" }),
              i.prepareServerUrl(r));
      },
      app_js_bridge_v1: function () {
        var r = null,
          i = null;
        (window.sensorsdata_app_js_bridge_call_js = function (e) {
          !(function (e) {
            (r = e),
              t.isJSONString(r) && (r = JSON.parse(r)),
              i && (i(r), (i = null), (r = null));
          })(e);
        }),
          (e.getAppStatus = function (e) {
            if (
              ((function () {
                if (
                  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                  !window.MSStream
                ) {
                  var e = document.createElement("iframe");
                  e.setAttribute("src", "sensorsanalytics://getAppInfo"),
                    document.documentElement.appendChild(e),
                    e.parentNode.removeChild(e),
                    (e = null);
                }
              })(),
                "object" == typeof window.SensorsData_APP_JS_Bridge &&
                window.SensorsData_APP_JS_Bridge.sensorsdata_call_app &&
                ((r = SensorsData_APP_JS_Bridge.sensorsdata_call_app()),
                  t.isJSONString(r) && (r = JSON.parse(r))),
                !e)
            )
              return r;
            null === r ? (i = e) : (e(r), (r = null));
          });
      },
      supportAppCallJs: function () {
        (window.sensorsdata_app_call_js = function (e, t) {
          e in window.sensorsdata_app_call_js.modules &&
            window.sensorsdata_app_call_js.modules[e](t);
        }),
          (window.sensorsdata_app_call_js.modules = {});
      },
    }),
      (e.JSBridge = function (e) {
        (this.list = {}),
          (this.type = e.type),
          (this.app_call_js = t.isFunction(e.app_call_js)
            ? e.app_call_js
            : function () { }),
          this.init();
      }),
      (e.JSBridge.prototype.init = function () {
        var e = this;
        window.sensorsdata_app_call_js.modules[this.type] ||
          (window.sensorsdata_app_call_js.modules[this.type] = function (t) {
            e.app_call_js(t);
          });
      }),
      (e.JSBridge.prototype.jsCallApp = function (r) {
        var i = { callType: this.type, data: r };
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.sensorsdataNativeTracker &&
          window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage
        )
          window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage(
            JSON.stringify(i)
          );
        else {
          if (
            !t.isObject(window.SensorsData_APP_New_H5_Bridge) ||
            !window.SensorsData_APP_New_H5_Bridge.sensorsdata_js_call_app
          )
            return (
              e.log(
                "\u6570\u636e\u53d1\u5f80App\u5931\u8d25\uff0cApp\u6ca1\u6709\u66b4\u9732bridge"
              ),
              !1
            );
          window.SensorsData_APP_New_H5_Bridge.sensorsdata_js_call_app(
            JSON.stringify(i)
          );
        }
      }),
      (e.JSBridge.prototype.hasAppBridge = function () {
        return window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.sensorsdataNativeTracker &&
          window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage
          ? "ios"
          : t.isObject(window.SensorsData_APP_New_H5_Bridge) &&
            window.SensorsData_APP_New_H5_Bridge.sensorsdata_js_call_app
            ? "android"
            : (e.log("App\u7aefbridge\u672a\u66b4\u9732"), !1);
      }),
      (e.JSBridge.prototype.requestToApp = function (r) {
        var i = this,
          a = t.isObject(r.data) ? r.data : {};
        t.isFunction(r.callback) || (r.callback = function () { }),
          t.isObject(r.timeout) &&
          t.isNumber(r.timeout.time) &&
          (t.isFunction(r.timeout.callback) ||
            (r.timeout.callback = function () { }),
            (r.timer = setTimeout(function () {
              r.timeout.callback(), delete i.list[n];
            }, r.timeout.time)));
        var n =
          new Date().getTime().toString(16) +
          "-" +
          String(t.getRandom()).replace(".", "").slice(1, 8);
        this.list[n] = r;
        var s = { callType: this.type, data: a };
        if (
          ((s.data.message_id = n),
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.sensorsdataNativeTracker &&
            window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage)
        )
          window.webkit.messageHandlers.sensorsdataNativeTracker.postMessage(
            JSON.stringify(s)
          );
        else {
          if (
            !t.isObject(window.SensorsData_APP_New_H5_Bridge) ||
            !window.SensorsData_APP_New_H5_Bridge.sensorsdata_js_call_app
          )
            return (
              e.log(
                "\u6570\u636e\u53d1\u5f80App\u5931\u8d25\uff0cApp\u6ca1\u6709\u66b4\u9732bridge"
              ),
              !1
            );
          window.SensorsData_APP_New_H5_Bridge.sensorsdata_js_call_app(
            JSON.stringify(s)
          );
        }
      }),
      (e.JSBridge.prototype.double = function (e) {
        if (e.message_id) {
          var t = this.list[e.message_id];
          t &&
            (t.timer && clearTimeout(t.timer),
              t.callback(e),
              delete this.list[e.message_id]);
        }
      });
    var u = (e.heatmap = {
      otherTags: [],
      getTargetElement: function (r, i) {
        var a = r;
        if ("object" != typeof a) return null;
        if ("string" != typeof a.tagName) return null;
        var n = a.tagName.toLowerCase();
        if ("body" === n.toLowerCase() || "html" === n.toLowerCase())
          return null;
        if (!a || !a.parentNode || !a.parentNode.children) return null;
        var s = a.parentNode,
          o = this.hasElement(i.originalEvent || i),
          c = e.para.heatmap.track_attr,
          l = this.otherTags;
        if (
          "a" === n ||
          "button" === n ||
          "input" === n ||
          "textarea" === n ||
          t.hasAttributes(a, c)
        )
          return a;
        if (t.indexOf(l, n) > -1) return a;
        if (
          "button" === s.tagName.toLowerCase() ||
          "a" === s.tagName.toLowerCase() ||
          t.hasAttributes(s, c)
        )
          return s;
        if (
          "area" === n &&
          "map" === s.tagName.toLowerCase() &&
          t.ry(s).prev().tagName &&
          "img" === t.ry(s).prev().tagName.toLowerCase()
        )
          return t.ry(s).prev();
        if (o) return o;
        if (
          "div" === n &&
          e.para.heatmap.collect_tags.div &&
          this.isDivLevelValid(a)
        )
          return ((e.para.heatmap &&
            e.para.heatmap.collect_tags &&
            e.para.heatmap.collect_tags.div &&
            e.para.heatmap.collect_tags.div.max_level) ||
            1) > 1 || this.isCollectableDiv(a)
            ? a
            : null;
        if (this.isStyleTag(n) && e.para.heatmap.collect_tags.div) {
          var u = this.getCollectableParent(a);
          if (u && this.isDivLevelValid(u)) return u;
        }
        return null;
      },
      getDivLevels: function (e, r) {
        var i = u.getElementPath(e, !0, r).split(" > "),
          a = 0;
        return (
          t.each(i, function (e) {
            "div" === e && a++;
          }),
          a
        );
      },
      isDivLevelValid: function (t) {
        for (
          var r =
            (e.para.heatmap &&
              e.para.heatmap.collect_tags &&
              e.para.heatmap.collect_tags.div &&
              e.para.heatmap.collect_tags.div.max_level) ||
            1,
          i = t.getElementsByTagName("div"),
          a = i.length - 1;
          a >= 0;
          a--
        )
          if (u.getDivLevels(i[a], t) > r) return !1;
        return !0;
      },
      getElementPath: function (e, t, r) {
        for (var i = []; e.parentNode;) {
          if (e.id && !t && /^[A-Za-z][-A-Za-z0-9_:.]*$/.test(e.id)) {
            i.unshift(e.tagName.toLowerCase() + "#" + e.id);
            break;
          }
          if (r && e === r) {
            i.unshift(e.tagName.toLowerCase());
            break;
          }
          if (e === document.body) {
            i.unshift("body");
            break;
          }
          i.unshift(e.tagName.toLowerCase()), (e = e.parentNode);
        }
        return i.join(" > ");
      },
      getClosestLi: function (e) {
        return (function (e, t) {
          for (; e && e !== document && 1 === e.nodeType; e = e.parentNode)
            if (e.tagName.toLowerCase() === t) return e;
          return null;
        })(e, "li");
      },
      getElementPosition: function (r, i, a) {
        var n = e.heatmap.getClosestLi(r);
        if (!n) return null;
        var s = r.tagName.toLowerCase(),
          o = n.getElementsByTagName(s),
          c = o.length,
          l = [];
        if (c > 1) {
          for (var u = 0; u < c; u++) {
            e.heatmap.getElementPath(o[u], a) === i && l.push(o[u]);
          }
          if (l.length > 1) return t.indexOf(l, r);
        }
        return (function (e) {
          if ((e.tagName.toLowerCase(), !e.parentNode)) return "";
          if (1 === t.ry(e).getSameTypeSiblings().length) return 0;
          for (
            var r = 0, i = e;
            t.ry(i).previousElementSibling().ele;
            i = t.ry(i).previousElementSibling().ele, r++
          );
          return r;
        })(n);
      },
      setNotice: function (t) {
        (e.is_heatmap_render_mode = !0),
          e.para.heatmap ||
          (e.errorMsg =
            "\u60a8SDK\u6ca1\u6709\u914d\u7f6e\u5f00\u542f\u70b9\u51fb\u56fe\uff0c\u53ef\u80fd\u6ca1\u6709\u6570\u636e\uff01"),
          t &&
          t[0] &&
          t[1] &&
          "http:" === t[1].slice(0, 5) &&
          "https:" === location.protocol &&
          (e.errorMsg =
            "\u60a8\u7684\u5f53\u524d\u9875\u9762\u662fhttps\u7684\u5730\u5740\uff0c\u795e\u7b56\u5206\u6790\u73af\u5883\u4e5f\u5fc5\u987b\u662fhttps\uff01"),
          e.para.heatmap_url ||
          (e.para.heatmap_url =
            location.protocol +
            "//static.sensorsdata.cn/sdk/" +
            e.lib_version +
            "/heatmap.min.js");
      },
      getDomIndex: function (e) {
        if (!e.parentNode) return -1;
        for (
          var t = 0, r = e.tagName, i = e.parentNode.children, a = 0;
          a < i.length;
          a++
        )
          if (i[a].tagName === r) {
            if (e === i[a]) return t;
            t++;
          }
        return -1;
      },
      selector: function (t, r) {
        var i =
          t.parentNode && 9 == t.parentNode.nodeType ? -1 : this.getDomIndex(t);
        return t.getAttribute &&
          t.getAttribute("id") &&
          /^[A-Za-z][-A-Za-z0-9_:.]*$/.test(t.getAttribute("id")) &&
          (!e.para.heatmap ||
            (e.para.heatmap &&
              "not_use_id" !== e.para.heatmap.element_selector)) &&
          !r
          ? "#" + t.getAttribute("id")
          : t.tagName.toLowerCase() +
          (~i ? ":nth-of-type(" + (i + 1) + ")" : "");
      },
      getDomSelector: function (t, r, i) {
        if (!t || !t.parentNode || !t.parentNode.children) return !1;
        r = r && r.join ? r : [];
        var a = t.nodeName.toLowerCase();
        return t && "body" !== a && 1 == t.nodeType
          ? (r.unshift(this.selector(t, i)),
            t.getAttribute &&
              t.getAttribute("id") &&
              /^[A-Za-z][-A-Za-z0-9_:.]*$/.test(t.getAttribute("id")) &&
              e.para.heatmap &&
              "not_use_id" !== e.para.heatmap.element_selector &&
              !i
              ? r.join(" > ")
              : this.getDomSelector(t.parentNode, r, i))
          : (r.unshift("body"), r.join(" > "));
      },
      na: function () {
        var e = document.documentElement.scrollLeft || window.pageXOffset;
        return parseInt(isNaN(e) ? 0 : e, 10);
      },
      i: function () {
        var e = 0;
        try {
          (e =
            (o.documentElement && o.documentElement.scrollTop) ||
            m.pageYOffset),
            (e = isNaN(e) ? 0 : e);
        } catch (t) {
          e = 0;
        }
        return parseInt(e, 10);
      },
      getBrowserWidth: function () {
        var e = window.innerWidth || document.body.clientWidth;
        return isNaN(e) ? 0 : parseInt(e, 10);
      },
      getBrowserHeight: function () {
        var e = window.innerHeight || document.body.clientHeight;
        return isNaN(e) ? 0 : parseInt(e, 10);
      },
      getScrollWidth: function () {
        var e = parseInt(document.body.scrollWidth, 10);
        return isNaN(e) ? 0 : e;
      },
      W: function (e) {
        var t = parseInt(+e.clientX + +this.na(), 10);
        e = parseInt(+e.clientY + +this.i(), 10);
        return { x: isNaN(t) ? 0 : t, y: isNaN(e) ? 0 : e };
      },
      getEleDetail: function (r) {
        var i = this.getDomSelector(r),
          a = t.getEleInfo({ target: r });
        (a.$element_selector = i || ""),
          (a.$element_path = e.heatmap.getElementPath(
            r,
            e.para.heatmap && "not_use_id" === e.para.heatmap.element_selector
          ));
        var n = e.heatmap.getElementPosition(
          r,
          a.$element_path,
          e.para.heatmap && "not_use_id" === e.para.heatmap.element_selector
        );
        return t.isNumber(n) && (a.$element_position = n), a;
      },
      start: function (r, i, a, n, s) {
        var o = t.isObject(n) ? n : {},
          c = t.isFunction(s) ? s : t.isFunction(n) ? n : undefined;
        if (
          e.para.heatmap &&
          e.para.heatmap.collect_element &&
          !e.para.heatmap.collect_element(i)
        )
          return !1;
        var l = this.getEleDetail(i);
        if (e.para.heatmap && e.para.heatmap.custom_property) {
          var u = e.para.heatmap.custom_property(i);
          t.isObject(u) && (l = t.extend(l, u));
        }
        (l = t.extend(l, o)),
          "a" === a && e.para.heatmap && !0 === e.para.heatmap.isTrackLink
            ? t.trackLink({ event: r, target: i }, "$WebClick", l)
            : e.track("$WebClick", l, c);
      },
      hasElement: function (e) {
        var r = e._getPath
          ? e._getPath()
          : u.getElementPath(e.target, !0).split(" > ");
        if (t.isArray(r) && r.length > 0)
          for (var i = 0; i < r.length; i++)
            if (r[i] && r[i].tagName && "a" === r[i].tagName.toLowerCase())
              return r[i];
        return !1;
      },
      isStyleTag: function (r, i) {
        return (
          !(t.indexOf(["a", "div", "input", "button", "textarea"], r) > -1) &&
          (!i ||
            (e.para.heatmap &&
              e.para.heatmap.collect_tags &&
              e.para.heatmap.collect_tags.div)
            ? !!(
              t.isObject(e.para.heatmap) &&
              t.isObject(e.para.heatmap.collect_tags) &&
              t.isObject(e.para.heatmap.collect_tags.div) &&
              t.indexOf(e.para.heatmap.collect_tags.div.ignore_tags, r) > -1
            )
            : t.indexOf(
              [
                "mark",
                "/mark",
                "strong",
                "b",
                "em",
                "i",
                "u",
                "abbr",
                "ins",
                "del",
                "s",
                "sup",
              ],
              r
            ) > -1)
        );
      },
      isCollectableDiv: function (t, r) {
        try {
          if (0 === t.children.length) return !0;
          for (var i = 0; i < t.children.length; i++)
            if (1 === t.children[i].nodeType) {
              var a = t.children[i].tagName.toLowerCase(),
                n =
                  e.para &&
                  e.para.heatmap &&
                  e.para.heatmap.collect_tags &&
                  e.para.heatmap.collect_tags.div &&
                  e.para.heatmap.collect_tags.div.max_level;
              if (!(("div" === a && n > 1) || this.isStyleTag(a, r))) return !1;
              if (!this.isCollectableDiv(t.children[i], r)) return !1;
            }
          return !0;
        } catch (s) {
          e.log(s);
        }
        return !1;
      },
      getCollectableParent: function (t, r) {
        try {
          var i = t.parentNode,
            a = i ? i.tagName.toLowerCase() : "";
          if ("body" === a) return !1;
          var n =
            e.para &&
            e.para.heatmap &&
            e.para.heatmap.collect_tags &&
            e.para.heatmap.collect_tags.div &&
            e.para.heatmap.collect_tags.div.max_level;
          if (a && "div" === a && (n > 1 || this.isCollectableDiv(i, r)))
            return i;
          if (i && this.isStyleTag(a, r))
            return this.getCollectableParent(i, r);
        } catch (s) {
          e.log(s);
        }
        return !1;
      },
      initScrollmap: function () {
        if (
          !t.isObject(e.para.heatmap) ||
          "default" !== e.para.heatmap.scroll_notice_map
        )
          return !1;
        var r = function () {
          return !(
            e.para.scrollmap &&
            t.isFunction(e.para.scrollmap.collect_url) &&
            !e.para.scrollmap.collect_url()
          );
        },
          i = (function (e) {
            var t = {};
            return (
              (t.timeout = e.timeout || 1e3),
              (t.func = e.func),
              (t.hasInit = !1),
              (t.inter = null),
              (t.main = function (e, t) {
                this.func(e, t), (this.inter = null);
              }),
              (t.go = function (e) {
                var r = {};
                this.inter ||
                  ((r.$viewport_position =
                    (document.documentElement &&
                      document.documentElement.scrollTop) ||
                    window.pageYOffset ||
                    document.body.scrollTop ||
                    0),
                    (r.$viewport_position =
                      Math.round(r.$viewport_position) || 0),
                    (r.$viewport_height =
                      window.innerHeight ||
                      document.documentElement.clientHeight ||
                      document.body.clientHeight ||
                      0),
                    (r.$viewport_width =
                      window.innerWidth ||
                      document.documentElement.clientWidth ||
                      document.body.clientWidth ||
                      0),
                    e
                      ? t.main(r, !0)
                      : (this.inter = setTimeout(function () {
                        t.main(r);
                      }, this.timeout)));
              }),
              t
            );
          })({
            timeout: 1e3,
            func: function (r, i) {
              var a =
                (document.documentElement &&
                  document.documentElement.scrollTop) ||
                window.pageYOffset ||
                document.body.scrollTop ||
                0,
                n = new Date(),
                s = n - this.current_time;
              ((s > e.para.heatmap.scroll_delay_time &&
                a - r.$viewport_position != 0) ||
                i) &&
                ((r.$url = t.isDecodeURI(e.para.url_is_decode, location.href)),
                  (r.$title = document.title),
                  (r.$url_path = location.pathname),
                  (r.event_duration = Math.min(
                    e.para.heatmap.scroll_event_duration,
                    parseInt(s) / 1e3
                  )),
                  e.track("$WebStay", r)),
                (this.current_time = n);
            },
          });
        (i.current_time = new Date()),
          t.addEvent(window, "scroll", function () {
            if (!r()) return !1;
            i.go();
          }),
          t.addEvent(window, "unload", function () {
            if (!r()) return !1;
            i.go("notime");
          });
      },
      initHeatmap: function () {
        var r = this;
        return (
          !(
            !t.isObject(e.para.heatmap) || "default" !== e.para.heatmap.clickmap
          ) &&
          !(
            t.isFunction(e.para.heatmap.collect_url) &&
            !e.para.heatmap.collect_url()
          ) &&
          ("all" === e.para.heatmap.collect_elements
            ? (e.para.heatmap.collect_elements = "all")
            : (e.para.heatmap.collect_elements = "interact"),
            void ("all" === e.para.heatmap.collect_elements
              ? t.addEvent(document, "click", function (e) {
                var t = e || window.event;
                if (!t) return !1;
                var i = t.target || t.srcElement;
                if ("object" != typeof i) return !1;
                if ("string" != typeof i.tagName) return !1;
                var a = i.tagName.toLowerCase();
                if ("body" === a || "html" === a) return !1;
                if (!i || !i.parentNode || !i.parentNode.children) return !1;
                var n = i.parentNode.tagName.toLowerCase();
                "a" === n || "button" === n
                  ? r.start(t, i.parentNode, n)
                  : r.start(t, i, a);
              })
              : t.addEvent(document, "click", function (t) {
                var i = t || window.event;
                if (!i) return !1;
                var a = i.target || i.srcElement,
                  n = e.heatmap.getTargetElement(a, t);
                n && r.start(i, n, n.tagName.toLowerCase());
              })))
        );
      },
    });
    (e.customProp = {
      events: [],
      configSwitch: !1,
      collectAble: function () {
        return (
          this.configSwitch &&
          t.isObject(e.para.heatmap) &&
          e.para.heatmap.get_vtrack_config
        );
      },
      updateEvents: function () {
        (this.events = e.vtrackcollect.getAssignConfigs(function (e) {
          return !!(
            t.isObject(e) &&
            t.isArray(e.properties) &&
            e.properties.length > 0
          );
        })),
          this.events.length
            ? (this.configSwitch = !0)
            : (this.configSwitch = !1);
      },
      getVtrackProps: function (e) {
        var t = {};
        return this.collectAble()
          ? ("$WebClick" === e.event &&
            (t = this.clickCustomPropMaker(e, this.events)),
            t)
          : {};
      },
      clickCustomPropMaker: function (r, i, a) {
        var n = this,
          s =
            ((a =
              a || this.filterConfig(r, i, e.vtrackcollect.url_info.page_url)),
              {});
        return a.length
          ? (t.each(a, function (e) {
            t.isArray(e.properties) &&
              e.properties.length > 0 &&
              t.each(e.properties, function (e) {
                var i = n.getProp(e, r);
                t.isObject(i) && t.extend(s, i);
              });
          }),
            s)
          : {};
      },
      getProp: function (r, i) {
        if (!t.isObject(r)) return !1;
        if (!(t.isString(r.name) && r.name.length > 0))
          return (
            e.log(
              "----vtrackcustom----\u5c5e\u6027\u540d\u4e0d\u5408\u6cd5,\u5c5e\u6027\u629b\u5f03",
              r.name
            ),
            !1
          );
        var a,
          n,
          s = {};
        if ("content" === r.method) {
          var o;
          if (t.isString(r.element_selector) && r.element_selector.length > 0)
            o = t.getDomBySelector(r.element_selector);
          else {
            if (!t.isString(r.list_selector))
              return (
                e.log(
                  "----vtrackcustom----\u5c5e\u6027\u914d\u7f6e\u5f02\u5e38\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name
                ),
                !1
              );
            var c = t.getDomBySelector(i.properties.$element_selector);
            if (!c)
              return (
                e.log(
                  "----vtrackcustom----\u70b9\u51fb\u5143\u7d20\u83b7\u53d6\u5f02\u5e38\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name
                ),
                !1
              );
            var l = e.heatmap.getClosestLi(c);
            o = this.getPropElInLi(l, r.list_selector);
          }
          if (!o || !t.isElement(o))
            return (
              e.log(
                "----vtrackcustom----\u5c5e\u6027\u5143\u7d20\u83b7\u53d6\u5931\u8d25\uff0c\u5c5e\u6027\u629b\u5f03",
                r.name
              ),
              !1
            );
          if ("input" === o.tagName.toLowerCase()) a = o.value || "";
          else if ("select" === o.tagName.toLowerCase()) {
            var u = o.selectedIndex;
            t.isNumber(u) &&
              t.isElement(o[u]) &&
              (a = e._.getElementContent(o[u], "select"));
          } else a = t.getElementContent(o, o.tagName.toLowerCase());
          if (r.regular) {
            try {
              n = new RegExp(r.regular).exec(a);
            } catch (p) {
              return (
                e.log(
                  "----vtrackcustom----\u6b63\u5219\u5904\u7406\u5931\u8d25\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name
                ),
                !1
              );
            }
            if (null === n)
              return (
                e.log(
                  "----vtrackcustom----\u5c5e\u6027\u89c4\u5219\u5904\u7406\uff0c\u672a\u5339\u914d\u5230\u7ed3\u679c,\u5c5e\u6027\u629b\u5f03",
                  r.name
                ),
                !1
              );
            if (!t.isArray(n) || !t.isString(n[0]))
              return (
                e.log(
                  "----vtrackcustom----\u6b63\u5219\u5904\u7406\u5f02\u5e38\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name,
                  n
                ),
                !1
              );
            a = n[0];
          }
          if ("STRING" === r.type) s[r.name] = a;
          else if ("NUMBER" === r.type) {
            if (a.length < 1)
              return (
                e.log(
                  "----vtrackcustom----\u672a\u83b7\u53d6\u5230\u6570\u5b57\u5185\u5bb9\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name,
                  a
                ),
                !1
              );
            if (isNaN(Number(a)))
              return (
                e.log(
                  "----vtrackcustom----\u6570\u5b57\u7c7b\u578b\u5c5e\u6027\u8f6c\u6362\u5931\u8d25\uff0c\u5c5e\u6027\u629b\u5f03",
                  r.name,
                  a
                ),
                !1
              );
            s[r.name] = Number(a);
          }
          return s;
        }
        return (
          e.log(
            "----vtrackcustom----\u5c5e\u6027\u4e0d\u652f\u6301\u6b64\u83b7\u53d6\u65b9\u5f0f",
            r.name,
            r.method
          ),
          !1
        );
      },
      getPropElInLi: function (r, i) {
        if (!(r && t.isElement(r) && t.isString(i))) return null;
        if ("li" !== r.tagName.toLowerCase()) return null;
        var a,
          n = e.heatmap.getDomSelector(r);
        if (n) {
          a = n + i;
          var s = t.getDomBySelector(a);
          return s || null;
        }
        return (
          e.log(
            "----vtrackcustom---\u83b7\u53d6\u540c\u7ea7\u5c5e\u6027\u5143\u7d20\u5931\u8d25\uff0cselector\u4fe1\u606f\u5f02\u5e38",
            n,
            i
          ),
          null
        );
      },
      filterConfig: function (r, i, a) {
        var n = [];
        if (!a) {
          var s = e.vtrackcollect.initUrl();
          if (!s) return [];
          a = s.page_url;
        }
        return (
          "$WebClick" === r.event &&
          t.each(i, function (i, s) {
            t.isObject(i) &&
              "webclick" === i.event_type &&
              t.isObject(i.event) &&
              i.event.url_host === a.host &&
              i.event.url_path === a.pathname &&
              e.vtrackcollect.configIsMatch(r.properties, i.event) &&
              n.push(i);
          }),
          n
        );
      },
    }),
      (e.vtrackcollect = {
        config: {},
        storageEnable: !0,
        storage_name: "webjssdkvtrackcollect",
        para: { session_time: 18e5, timeout: 5e3, update_interval: 18e5 },
        url_info: {},
        timer: null,
        update_time: null,
        customProp: e.customProp,
        initUrl: function () {
          var r,
            i,
            a,
            n = {
              server_url: { project: "", host: "" },
              page_url: { host: "", pathname: "" },
              api_url: "",
            };
          if (!t.isString(e.para.server_url))
            return (
              e.log(
                "----vtrackcollect---server_url\u5fc5\u987b\u4e3a\u5b57\u7b26\u4e32"
              ),
              !1
            );
          try {
            (r = t.URL(e.para.server_url)),
              (n.server_url.project =
                r.searchParams.get("project") || "default"),
              (n.server_url.host = r.host);
          } catch (s) {
            return (
              e.log(
                "----vtrackcollect---server_url\u89e3\u6790\u5f02\u5e38",
                s
              ),
              !1
            );
          }
          try {
            (i = t.URL(location.href)),
              (n.page_url.host = i.hostname),
              (n.page_url.pathname = i.pathname);
          } catch (s) {
            return (
              e.log(
                "----vtrackcollect---\u9875\u9762\u5730\u5740\u89e3\u6790\u5f02\u5e38",
                s
              ),
              !1
            );
          }
          try {
            ((a = new t.urlParse(e.para.server_url))._values.Path =
              "/config/visualized/Web.conf"),
              (n.api_url = a.getUrl());
          } catch (s) {
            return (
              e.log(
                "----vtrackcollect---API\u5730\u5740\u89e3\u6790\u5f02\u5e38",
                s
              ),
              !1
            );
          }
          return (this.url_info = n), n;
        },
        init: function () {
          if (!t.isObject(e.para.heatmap) || !e.para.heatmap.get_vtrack_config)
            return (
              e.log(
                "----vtrackcustom----\u521d\u59cb\u5316\u5931\u8d25\uff0cget_vtrack_config\u5f00\u5173\u672a\u5f00\u542f"
              ),
              !1
            );
          if (
            (t.localStorage.isSupport() || (this.storageEnable = !1),
              !this.initUrl())
          )
            return (
              e.log(
                "----vtrackcustom----\u521d\u59cb\u5316\u5931\u8d25\uff0curl\u4fe1\u606f\u89e3\u6790\u5931\u8d25"
              ),
              !1
            );
          if (this.storageEnable) {
            var r = t.localStorage.parse(this.storage_name);
            if (t.isObject(r) && t.isObject(r.data))
              if (this.serverUrlIsSame(r.serverUrl)) {
                (this.config = r.data),
                  (this.update_time = r.updateTime),
                  this.updateConfig(r.data);
                var i = new Date().getTime() - this.update_time;
                if (t.isNumber(i) && i > 0 && i < this.para.session_time) {
                  var a = this.para.update_interval - i;
                  this.setNextFetch(a);
                } else this.getConfigFromServer();
              } else this.getConfigFromServer();
            else this.getConfigFromServer();
          } else this.getConfigFromServer();
          this.pageStateListenner();
        },
        serverUrlIsSame: function (e) {
          return (
            !!t.isObject(e) &&
            e.host === this.url_info.server_url.host &&
            e.project === this.url_info.server_url.project
          );
        },
        getConfigFromServer: function () {
          var r = this;
          this.sendRequest(
            function (i, a) {
              r.update_time = new Date().getTime();
              var n = {};
              200 === i
                ? a &&
                t.isObject(a) &&
                "Web" === a.os &&
                ((n = a), r.updateConfig(n))
                : 205 === i
                  ? r.updateConfig(n)
                  : 304 === i
                    ? (n = r.config)
                    : (e.log("----vtrackcustom----\u6570\u636e\u5f02\u5e38", i),
                      r.updateConfig(n)),
                r.updateStorage(n),
                r.setNextFetch();
            },
            function (t) {
              (r.update_time = new Date().getTime()),
                e.log(
                  "----vtrackcustom----\u914d\u7f6e\u62c9\u53d6\u5931\u8d25",
                  t
                ),
                r.setNextFetch();
            }
          );
        },
        setNextFetch: function (e) {
          var t = this;
          this.timer && (clearTimeout(this.timer), (this.timer = null)),
            (e = e || this.para.update_interval),
            (this.timer = setTimeout(function () {
              t.getConfigFromServer();
            }, e));
        },
        pageStateListenner: function () {
          var e = this;
          t.listenPageState({
            visible: function () {
              var r = new Date().getTime() - e.update_time;
              if (t.isNumber(r) && r > 0 && r < e.para.update_interval) {
                var i = e.para.update_interval - r;
                e.setNextFetch(i);
              } else e.getConfigFromServer();
            },
            hidden: function () {
              e.timer && (clearTimeout(e.timer), (e.timer = null));
            },
          });
        },
        updateConfig: function (e) {
          if (!t.isObject(e)) return !1;
          (this.config = e), this.customProp.updateEvents();
        },
        updateStorage: function (r) {
          if (!this.storageEnable) return !1;
          if (!t.isObject(r)) return !1;
          var i;
          if (this.url_info.server_url) i = this.url_info.server_url;
          else {
            var a = e.vtrackcollect.initUrl();
            if (!a) return !1;
            i = a.server_url;
          }
          var n = { updateTime: new Date().getTime(), data: r, serverUrl: i };
          t.localStorage.set(this.storage_name, JSON.stringify(n));
        },
        sendRequest: function (e, r) {
          var i = { app_id: this.url_info.page_url.host };
          this.config.version && (i.v = this.config.version),
            t.jsonp({
              url: this.url_info.api_url,
              callbackName: "saJSSDKVtrackCollectConfig",
              data: i,
              timeout: this.para.timeout,
              success: function (t, r) {
                e(t, r);
              },
              error: function (e) {
                r(e);
              },
            });
        },
        getAssignConfigs: function (e) {
          var r = this,
            i = [];
          return t.isObject(this.config) &&
            t.isArray(this.config.events) &&
            this.config.events.length > 0
            ? (t.each(this.config.events, function (a) {
              t.isObject(a) &&
                t.isObject(a.event) &&
                a.event.url_host === r.url_info.page_url.host &&
                a.event.url_path === r.url_info.page_url.pathname &&
                e(a) &&
                i.push(a);
            }),
              i)
            : [];
        },
        isDiv: function (e) {
          if (e.element_path) {
            var r = e.element_path.split(">");
            if ("div" !== t.trim(r.pop()).slice(0, 3)) return !1;
          }
          return !0;
        },
        configIsMatch: function (t, r) {
          if (!r.element_path) return !1;
          if (
            r.limit_element_content &&
            r.element_content !== t.$element_content
          )
            return !1;
          if (
            r.limit_element_position &&
            r.element_position !== String(t.$element_position)
          )
            return !1;
          if (t.$element_position !== undefined) {
            if (r.element_path !== t.$element_path) return !1;
          } else if (e.vtrackcollect.isDiv({ element_path: r.element_path })) {
            if (t.$element_path.indexOf(r.element_path) < 0) return !1;
          } else if (r.element_path !== t.$element_path) return !1;
          return !0;
        },
      }),
      (e.init = function (t) {
        if (e.readyState && e.readyState.state && e.readyState.state >= 2)
          return !1;
        e.setInitVar(),
          e.readyState.setState(2),
          e.initPara(t),
          e.bridge.supportAppCallJs(),
          e.detectMode(),
          e._.isIOS() &&
          e._.getIOSVersion() &&
          e._.getIOSVersion() < 13 &&
          (e.para.heatmap &&
            e.para.heatmap.collect_tags &&
            e.para.heatmap.collect_tags.div &&
            e._.setCssStyle(
              "div, [data-sensors-click] { cursor: pointer; -webkit-tap-highlight-color: rgba(0,0,0,0); }"
            ),
            e.para.heatmap &&
            e.para.heatmap.track_attr &&
            e._.setCssStyle(
              "[" +
              e.para.heatmap.track_attr.join("], [") +
              "] { cursor: pointer; -webkit-tap-highlight-color: rgba(0,0,0,0); }"
            ));
      });
    if (
      (t.each(
        [
          "getAppStatus",
          "track",
          "quick",
          "register",
          "registerPage",
          "registerOnce",
          "trackSignup",
          "setProfile",
          "setOnceProfile",
          "appendProfile",
          "incrementProfile",
          "deleteProfile",
          "unsetProfile",
          "identify",
          "login",
          "logout",
          "trackLink",
          "clearAllRegister",
          "clearPageRegister",
        ],
        function (r) {
          var i = e[r];
          e[r] = function () {
            if (e.readyState.state < 3)
              return (
                t.isArray(e._q) || (e._q = []), e._q.push([r, arguments]), !1
              );
            if (e.readyState.getState()) return i.apply(e, arguments);
            try {
              console.error("\u8bf7\u5148\u521d\u59cb\u5316\u795e\u7b56JS SDK");
            } catch (a) {
              e.log(a);
            }
          };
        }
      ),
        (e.modules.Amp = (function () {
          "use strict";
          var e = {
            sd: null,
            init: function (e) {
              if (this.sd) return !1;
              if (((this.sd = e), !this.sd || !this.sd._)) return !1;
              var t = this.sd._.cookie.get("sensors_amp_id"),
                r = this.sd.store._state.distinct_id;
              if (t && t.length > 0) {
                var i = "amp-" === t.slice(0, 4);
                if (t !== r) {
                  if (!i) return !1;
                  this.sd.store._state.first_id
                    ? (this.sd.identify(t, !0),
                      this.sd.saEvent.send(
                        {
                          original_id: t,
                          distinct_id: r,
                          type: "track_signup",
                          event: "$SignUp",
                          properties: {},
                        },
                        null
                      ),
                      this.setAmpId(r))
                    : this.sd.identify(t, !0);
                }
              } else this.setAmpId(r);
              this.addListener();
            },
            addListener: function () {
              var e = this;
              this.sd.events.on("changeDistinctId", function (t) {
                e.setAmpId(t);
              }),
                this.sd.events.isReady();
            },
            setAmpId: function (e) {
              this.sd._.cookie.set("sensors_amp_id", e);
            },
          };
          return (
            window.TutorDataWebJSSDKPlugin &&
              "[object Object]" ===
              Object.prototype.toString.call(window.TutorDataWebJSSDKPlugin)
              ? (window.TutorDataWebJSSDKPlugin.Amp =
                window.TutorDataWebJSSDKPlugin.Amp || e)
              : (window.TutorDataWebJSSDKPlugin = { Amp: e }),
            e
          );
        })()),
        (e.modules.Channel = (function () {
          "use strict";
          var e, t, r = {
            event_list: [],
            latest_event_initial_time: null,
            max_save_time: 2592e6,
            init: function (r) {
              return (
                !t &&
                !!(t = r) &&
                !!(e = t._).localStorage.isSupport() &&
                ((t.para.max_string_length = 1024),
                  this.eventList.init(),
                  this.addLatestChannelUrl(),
                  void this.addIsChannelCallbackEvent())
              );
            },
            addIsChannelCallbackEvent: function () {
              t.registerPage({
                $is_channel_callback_event: function (e) {
                  if (
                    e.event &&
                    "$WebClick" !== e.event &&
                    "$pageview" !== e.event &&
                    "$WebStay" !== e.event &&
                    "$SignUp" !== e.event
                  )
                    return (
                      !r.eventList.hasEvent(e.event) &&
                      (r.eventList.add(e.event), !0)
                    );
                },
              });
            },
            addLatestChannelUrl: function () {
              var i = this.getUrlDomain(),
                a = this.cookie.getChannel();
              if ("url\u89e3\u6790\u5931\u8d25" === i)
                this.registerAndSave({
                  _sa_channel_landing_url: "",
                  _sa_channel_landing_url_error:
                    "url\u7684domain\u89e3\u6790\u5931\u8d25",
                });
              else if (e.isReferralTraffic(document.referrer)) {
                var n = e.getQueryParam(location.href, "sat_cf");
                e.isString(n) && n.length > 0
                  ? (this.registerAndSave({
                    _sa_channel_landing_url: location.href,
                  }),
                    r.channelLinkHandler())
                  : this.registerAndSave({ _sa_channel_landing_url: "" });
              } else
                a
                  ? t.registerPage(a)
                  : t.registerPage({
                    _sa_channel_landing_url: "",
                    _sa_channel_landing_url_error: "\u53d6\u503c\u5f02\u5e38",
                  });
            },
            registerAndSave: function (e) {
              t.registerPage(e), this.cookie.saveChannel(e);
            },
            cookie: {
              getChannel: function () {
                var r;
                try {
                  r = JSON.parse(e.cookie.get("sensorsdata2015jssdkchannel"));
                } catch (i) {
                  t.log(i);
                }
                return !(!e.isObject(r) || !r.prop) && r.prop;
              },
              saveChannel: function (t) {
                var r = { prop: t };
                e.cookie.set("sensorsdata2015jssdkchannel", JSON.stringify(r));
              },
            },
            channelLinkHandler: function () {
              this.eventList.reset(), t.track("$ChannelLinkReaching");
            },
            getUrlDomain: function () {
              var t = e.info.pageProp.url_domain;
              return "" === t && (t = "url\u89e3\u6790\u5931\u8d25"), t;
            },
            eventList: {
              init: function () {
                var t = this.get(),
                  i = new Date().getTime();
                if (
                  t &&
                  e.isNumber(t.latest_event_initial_time) &&
                  e.isArray(t.eventList)
                ) {
                  var a = i - t.latest_event_initial_time;
                  a > 0 && a < r.max_save_time
                    ? ((r.event_list = t.eventList),
                      (r.latest_event_initial_time =
                        t.latest_event_initial_time))
                    : this.reset();
                } else this.reset();
              },
              get: function () {
                var r = {};
                try {
                  r = JSON.parse(e.localStorage.get("sawebjssdkchannel"));
                } catch (i) {
                  t.log(i);
                }
                return r;
              },
              add: function (e) {
                r.event_list.push(e), this.save();
              },
              save: function () {
                var t = {
                  latest_event_initial_time: r.latest_event_initial_time,
                  eventList: r.event_list,
                };
                e.localStorage.set("sawebjssdkchannel", JSON.stringify(t));
              },
              reset: function () {
                (r.event_list = []),
                  (r.latest_event_initial_time = new Date().getTime()),
                  this.save();
              },
              hasEvent: function (t) {
                var i = !1;
                return (
                  e.each(r.event_list, function (e) {
                    e === t && (i = !0);
                  }),
                  i
                );
              },
            },
          };
          return (
            window.TutorDataWebJSSDKPlugin &&
              "[object Object]" ===
              Object.prototype.toString.call(window.TutorDataWebJSSDKPlugin)
              ? (window.TutorDataWebJSSDKPlugin.SensorsChannel =
                window.TutorDataWebJSSDKPlugin.SensorsChannel || r)
              : (window.TutorDataWebJSSDKPlugin = { SensorsChannel: r }),
            r
          );
        })()),
        (e.modules.Deeplink = (function () {
          "use strict";
          /micromessenger\/([\d.]+)/i.test(navigator.userAgent || "");
          var e, t = function () {
            var e = {};
            return (
              "undefined" != typeof document.hidden
                ? ((e.hidden = "hidden"),
                  (e.visibilityChange = "visibilitychange"))
                : "undefined" != typeof document.msHidden
                  ? ((e.hidden = "msHidden"),
                    (e.visibilityChange = "msvisibilitychange"))
                  : "undefined" != typeof document.webkitHidden &&
                  ((e.hidden = "webkitHidden"),
                    (e.visibilityChange = "webkitvisibilitychange")),

              e
            );
          };


          function r() {
            return void 0 !== e && document[e];
          }
          e = t().hidden;

          var i = { android: /Android/i, iOS: /iPhone|iPad|iPod/i };

          var a = (function () {
            for (var e in i) if (navigator.userAgent.match(i[e])) return e;

            return "";
          })();
          var n = function (e) {

            return (
              null != e &&
              "[object Object]" == Object.prototype.toString.call(e)
            );
          };
          var s = {
            key: null,
            timer: null,
            sd: null,
            data: null,
            timeout: 2500,
            apiURL:
              "{origin}/sdk/deeplink/param?key={key}&system_type=JS&project={project}",
            init: function () {

              if (this.sd)
                return this.log(`deeplink已经初始化`), !1;

              if (
                (n(tutorDataAnalytic201505) &&
                  (this.sd = tutorDataAnalytic201505),
                  this.log("init()"),
                  null === this.sd)
              )
                return (
                  this.log(`SDK未成功引⼊`), !1
                );
              var e = {};

              if (
                (arguments.length > 0 && (1 === arguments.length && n(arguments[0]) ? (e = arguments[0]) : arguments.length >= 2 && n(arguments[1]) && (e = arguments[1])), !i.hasOwnProperty(a))
              )
                return (
                  this.log(`不⽀持当前系统，⽬前只⽀持Android和iOS`), !1
                );

              if (
                (n(e) && this.sd._.isNumber(e.timeout) && e.timeout >= 2500 && (this.timeout = e.timeout), !this.sd.para.server_url)
              )
                return (
                  this.log(`JS SDK配置项server_url未正确配置`), !1
                );
              var t, r, o = ((t = this.sd),
              {
                origin: (r = t._.URL(t.para.server_url)).origin,
                project: r.searchParams.get("project") || "default",
              });
              this.apiURL = this.apiURL
                .replace("{origin}", o.origin)
                .replace("{project}", o.project);
              var c = this.sd._.URL(window.location.href).searchParams.get("deeplink");

              if (!c)
                return (
                  this.log(`当前⻚⾯缺少deeplink参数`), !1
                );
              c = window.decodeURIComponent(c);

              var l = c.match(/\/td\/(\w+)\/(\w+)$/);
              if (!l)
                return (
                  this.log(`当前⻚⾯的deeplink参数⽆效`), !1
                );
              (this.key = l[2]),
                (this.apiURL = this.apiURL.replace(
                  "{key}",
                  window.encodeURIComponent(l[2])
                )),

                this.sd._.ajax({
                  url: this.apiURL,
                  type: "GET",
                  cors: !0,
                  credentials: !1,
                  success: function (e) {

                    if (e.errorMsg)
                      return s.log(`API报错：${e.errorMsg}`), !1;
                    (s.data = e),

                      s.log(`API查询成功，数据：${JSON.stringify(e, null, " ")}`)


                    this.data.app_key &&
                      (this.data.android_info &&
                        this.data.android_info.url_schemes &&
                        (this.data.android_info.url_schemes +=
                          "://tutordata/td/" +
                          this.data.app_key +
                          "/" +
                          this.key),
                        this.data.ios_info &&
                        this.data.ios_info.url_schemes &&
                        (this.data.ios_info.url_schemes +=
                          "://tutordata/td/" +
                          this.data.app_key +
                          "/" +
                          this.key));
                    console.log(this.data.android_info.url_schemes,);
                  }.bind(this),

                  error: function (e) {
                    s.log(`API查询出错`);
                  },
                }),
                this.addListeners();
            },
            openDeepLink: function () {
              if ((this.log("openDeeplink()"), !this.data))
                return this.log(`没有Deep link数据!`), !1;

              if ("iOS" === a) {
                this.log(`当前系统是iOS`);


                var t = this.sd && this.sd._ && this.sd._.getIOSVersion() >= 9 && this.data.ios_info.ios_wake_url ? this.data.ios_info.ios_wake_url : this.data.ios_info.url_schemes;

                this.log(`唤起APP的地址：${t}`),
                  (i = this),
                  (n = t),
                  (s = this.data.ios_info.download_url),
                  i.log(`尝试唤起 iOS app:${n}`),
                  (window.location.href = n),

                  (i.timer = setTimeout(function () {
                    if (r())
                      return (
                        i.log(
                          "The page is hidden, stop navigating to download page"
                        ), !1
                      );
                    i.log(`App可能未安装，跳转到下载地址`)
                      (window.location.href = s);

                  }, i.timeout)),
                  i.log("new timer:" + i.timer);
              } else
                this.log(`当前系统是 android`),
                  (function (t, i, a) {

                    t.log(`尝试唤起 android app`);
                    console.log("url = " + i)
                    var n = i;
                    t.log(`唤起APP的地址：${n}`),
                      (window.location.href = n),
                      (t.timer = setTimeout(function () {
                        var i = r();

                        if ((t.log("hide:" + e + ":" + document[e]), i))
                          return (
                            t.log(
                              "The page is hidden, stop navigating to download page"
                            ),
                            !1
                          );
                        t.log(`App可能未安装，跳转到下载地址`)
                          (window.location = a);

                      }, t.timeout));
                  })(
                    this,
                    this.data.android_info.url_schemes,
                    this.data.android_info.download_url
                  );
              var i, n, s;
            },
            log: function (e) {
              this.sd && this.sd.log(e);
            },
            addListeners: function () {
              var e = t().visibilityChange;


              e && document.addEventListener(e, function () {
                clearTimeout(this.timer),
                  this.log("visibilitychange, clear timeout:" + this.timer);
              }.bind(this), !1
              ),
                window.addEventListener(
                  "pagehide",
                  function () {
                    this.log("page hide, clear timeout:" + this.timer),
                      clearTimeout(this.timer);
                  }.bind(this),
                  !1
                );
            },
          };
          return (
            n(window.TutorDataWebJSSDKPlugin)
              ? ((window.TutorDataWebJSSDKPlugin.Deeplink =
                window.TutorDataWebJSSDKPlugin.Deeplink || s),
                (window.TutorDataWebJSSDKPlugin.deeplink =
                  window.TutorDataWebJSSDKPlugin.deeplink || s))
              : (window.TutorDataWebJSSDKPlugin = { Deeplink: s, deeplink: s }),
            s
          );
        })()),
        (e.modules.SiteLinker = (function () {
          "use strict";
          var e = {
            getPart: function (e) {
              var t = this.option.length;
              if (t)
                for (var r = 0; r < t; r++)
                  if (e.indexOf(this.option[r].part_url) > -1) return !0;
              return !1;
            },
            getPartHash: function (e) {
              var t = this.option.length;
              if (t)
                for (var r = 0; r < t; r++)
                  if (e.indexOf(this.option[r].part_url) > -1)
                    return this.option[r].after_hash;
              return !1;
            },
            getCurrenId: function () {
              var e = this.store.getDistinctId() || "",
                r = this.store.getFirstId() || "";
              return (
                this._.urlSafeBase64 && this._.urlSafeBase64.encode
                  ? (e = e
                    ? this._.urlSafeBase64.trim(
                      this._.urlSafeBase64.encode(t.base64Encode(e))
                    )
                    : "")
                  : this._.rot13obfs && (e = e ? this._.rot13obfs(e) : ""),
                encodeURIComponent(r ? "f" + e : "d" + e)
              );
            },
            rewireteUrl: function (e, t) {
              var r = /([^?#]+)(\?[^#]*)?(#.*)?/.exec(e),
                i = "";
              if (r) {
                var a,
                  n = r[1] || "",
                  s = r[2] || "",
                  o = r[3] || "";
                if (this.getPartHash(e))
                  (a = o.indexOf("_sasdk")),
                    (i =
                      o.indexOf("?") > -1
                        ? a > -1
                          ? n +
                          s +
                          "#" +
                          o.substring(1, a) +
                          "_sasdk=" +
                          this.getCurrenId()
                          : n +
                          s +
                          "#" +
                          o.substring(1) +
                          "&_sasdk=" +
                          this.getCurrenId()
                        : n +
                        s +
                        "#" +
                        o.substring(1) +
                        "?_sasdk=" +
                        this.getCurrenId());
                else
                  (a = s.indexOf("_sasdk")),
                    (i = /^\?(\w)+/.test(s)
                      ? a > -1
                        ? n +
                        "?" +
                        s.substring(1, a) +
                        "_sasdk=" +
                        this.getCurrenId() +
                        o
                        : n +
                        "?" +
                        s.substring(1) +
                        "&_sasdk=" +
                        this.getCurrenId() +
                        o
                      : n +
                      "?" +
                      s.substring(1) +
                      "_sasdk=" +
                      this.getCurrenId() +
                      o);
                return t && (t.href = i), i;
              }
            },
            getUrlId: function () {
              var e = location.href.match(/_sasdk=([aufd][^\?\#\&\=]+)/);
              if (this._.isArray(e) && e[1]) {
                var r = decodeURIComponent(e[1]);
                return (
                  !r ||
                  ("f" !== r.substring(0, 1) && "d" !== r.substring(0, 1)) ||
                  (this._.urlSafeBase64 &&
                    this._.urlSafeBase64.isUrlSafeBase64 &&
                    this._.urlSafeBase64.isUrlSafeBase64(r)
                    ? (r =
                      r.substring(0, 1) +
                      t.base64Decode(
                        this._.urlSafeBase64.decode(r.substring(1))
                      ))
                    : this._.rot13defs &&
                    (r =
                      r.substring(0, 1) + this._.rot13defs(r.substring(1)))),
                  r
                );
              }
              return "";
            },
            setRefferId: function () {
              var e = this.store.getDistinctId(),
                t = this.getUrlId();
              if ("" === t) return !1;
              var r = "a" === t.substring(0, 1) || "d" === t.substring(0, 1);
              if ((t = t.substring(1)) === e) return !1;
              t &&
                r &&
                this.store.getFirstId() &&
                (this.sd.identify(t, !0),
                  this.sd.saEvent.send(
                    {
                      original_id: t,
                      distinct_id: e,
                      type: "track_signup",
                      event: "$SignUp",
                      properties: {},
                    },
                    null
                  )),
                t && r && !this.store.getFirstId() && this.sd.identify(t, !0),
                !t || r || this.store.getFirstId() || this.sd.login(t);
            },
            addListen: function () {
              var e = this,
                t = function (t) {
                  var r,
                    i,
                    a = t.target,
                    n = a.tagName.toLowerCase(),
                    s = a.parentNode;
                  if (
                    ("a" === n && a.href) ||
                    (s && s.tagName && "a" === s.tagName.toLowerCase() && s.href)
                  ) {
                    "a" === n && a.href
                      ? ((r = a.href), (i = a))
                      : ((r = s.href), (i = s));
                    var o = e._.URL(r).protocol;
                    ("http:" !== o && "https:" !== o) ||
                      (e.getPart(r) && e.rewireteUrl(r, i));
                  }
                };
              e._.addEvent(document, "mousedown", t),
                window.PointerEvent &&
                "maxTouchPoints" in window.navigator &&
                window.navigator.maxTouchPoints >= 0 &&
                e._.addEvent(document, "pointerdown", t);
            },
            init: function (e, t) {
              (this.sd = e),
                (this._ = e._),
                (this.store = e.store),
                (this.para = e.para),
                this._.isObject(t) &&
                  this._.isArray(t.linker) &&
                  t.linker.length > 0
                  ? (this.setRefferId(),
                    this.addListen(),
                    (this.option = t.linker),
                    (this.option = (function (t) {
                      for (var r = t.length, i = [], a = 0; a < r; a++)
                        /[A-Za-z0-9]+\./.test(t[a].part_url) &&
                          "[object Boolean]" ==
                          Object.prototype.toString.call(t[a].after_hash)
                          ? i.push(t[a])
                          : e.log(
                            "linker \u914d\u7f6e\u7684\u7b2c " +
                            (a + 1) +
                            " \u9879\u683c\u5f0f\u4e0d\u6b63\u786e\uff0c\u8bf7\u68c0\u67e5\u53c2\u6570\u683c\u5f0f\uff01"
                          );
                      return i;
                    })(this.option)))
                  : e.log(
                    "\u8bf7\u914d\u7f6e\u6253\u901a\u57df\u540d\u53c2\u6570\uff01"
                  );
            },
          };
          return (
            t.isObject(window.TutorDataWebJSSDKPlugin)
              ? (window.TutorDataWebJSSDKPlugin.SiteLinker =
                window.TutorDataWebJSSDKPlugin.SiteLinker || e)
              : (window.TutorDataWebJSSDKPlugin = { SiteLinker: e }),
            e
          );
        })()),
        "string" != typeof window.tutorDataAnalytic201505)
    )
      return "undefined" == typeof window.tutorDataAnalytic201505
        ? ((window.tutorDataAnalytic201505 = e), e)
        : window.tutorDataAnalytic201505;
    e.setPreConfig(window[tutorDataAnalytic201505]),
      (window[tutorDataAnalytic201505] = e),
      (window.tutorDataAnalytic201505 = e),
      e.init();
  } catch (O) {
    if ("object" == typeof console && console.log)
      try {
        console.log(O);
      } catch (D) {
        e.log(D);
      }
  }
  var p, d, f, _, g, h, v, y, S, b, w, k, j, P;
});
